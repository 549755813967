import React from "react";

const Background = () => {
  return (
    <svg
      width="390"
      height="606"
      viewBox="0 0 390 606"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={0.5} clipPath="url(#clip0_0_1)">
        <mask
          id="mask0_0_1"
          marktype="luminance"
          maskUnits="userSpaceOnUse"
          x="-55"
          y="0"
          width="500"
          height="500"
        >
          <path d="M445 0H-55V500H445V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_0_1)">
          <path
            d="M360.4 437.95C360.4 438.15 360.24 438.32 360.03 438.32C359.82 438.32 359.66 438.15 359.66 437.95C359.66 437.75 359.83 437.58 360.03 437.58C360.23 437.58 360.4 437.75 360.4 437.95Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M387.58 426.31C387.58 426.57 387.36 426.79 387.1 426.79C386.84 426.79 386.62 426.57 386.62 426.31C386.62 426.05 386.83 425.83 387.1 425.83C387.37 425.83 387.58 426.05 387.58 426.31Z"
            fill="white"
          />
          <path
            d="M332.98 425.56C332.98 426.15 332.5 426.63 331.91 426.63C331.32 426.63 330.84 426.15 330.84 425.56C330.84 424.97 331.32 424.49 331.91 424.49C332.5 424.49 332.98 424.97 332.98 425.56Z"
            fill="#8D5AA4"
          />
          <path
            d="M372.91 385.86L373.91 385.62L372.91 385.38C372.35 385.26 371.91 384.82 371.79 384.26L371.55 383.26L371.31 384.26C371.19 384.82 370.75 385.26 370.19 385.38L369.19 385.62L370.19 385.86C370.75 385.98 371.19 386.42 371.31 386.98L371.55 387.98L371.79 386.98C371.91 386.42 372.35 385.98 372.91 385.86Z"
            fill="white"
          />
          <path
            d="M268.91 348.83L269.91 348.59L268.91 348.35C268.35 348.23 267.91 347.79 267.79 347.23L267.55 346.23L267.31 347.23C267.19 347.79 266.75 348.23 266.19 348.35L265.19 348.59L266.19 348.83C266.75 348.95 267.19 349.39 267.31 349.95L267.55 350.95L267.79 349.95C267.91 349.39 268.35 348.95 268.91 348.83Z"
            fill="white"
          />
          <path
            opacity="0.75"
            d="M360.67 494.94C360.67 495.09 360.55 495.22 360.39 495.22C360.23 495.22 360.12 495.1 360.12 494.94C360.12 494.78 360.24 494.67 360.39 494.67C360.54 494.67 360.67 494.79 360.67 494.94Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M299.77 435.09C299.77 435.35 299.55 435.57 299.29 435.57C299.03 435.57 298.81 435.35 298.81 435.09C298.81 434.83 299.02 434.61 299.29 434.61C299.56 434.61 299.77 434.83 299.77 435.09Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M300.25 395.29C300.25 395.55 300.03 395.77 299.77 395.77C299.51 395.77 299.29 395.55 299.29 395.29C299.29 395.03 299.5 394.81 299.77 394.81C300.04 394.81 300.25 395.03 300.25 395.29Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M330.84 375.09C330.84 375.35 330.62 375.57 330.36 375.57C330.1 375.57 329.88 375.35 329.88 375.09C329.88 374.83 330.09 374.61 330.36 374.61C330.63 374.61 330.84 374.83 330.84 375.09Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M253.75 360.19C253.75 360.45 253.53 360.67 253.27 360.67C253.01 360.67 252.79 360.45 252.79 360.19C252.79 359.93 253 359.71 253.27 359.71C253.54 359.71 253.75 359.93 253.75 360.19Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M272.79 383.28C272.79 383.54 272.57 383.76 272.31 383.76C272.05 383.76 271.83 383.54 271.83 383.28C271.83 383.02 272.04 382.8 272.31 382.8C272.58 382.8 272.79 383.02 272.79 383.28Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M356.02 484.13C356.02 484.39 355.8 484.61 355.54 484.61C355.28 484.61 355.06 484.39 355.06 484.13C355.06 483.87 355.27 483.65 355.54 483.65C355.81 483.65 356.02 483.87 356.02 484.13Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M360.14 336.18C360.14 336.44 359.92 336.66 359.66 336.66C359.4 336.66 359.18 336.44 359.18 336.18C359.18 335.92 359.39 335.7 359.66 335.7C359.93 335.7 360.14 335.92 360.14 336.18Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M365.79 341.61C365.79 341.87 365.57 342.09 365.31 342.09C365.05 342.09 364.83 341.87 364.83 341.61C364.83 341.35 365.04 341.13 365.31 341.13C365.58 341.13 365.79 341.35 365.79 341.61Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M314.52 344.13C314.52 344.39 314.3 344.61 314.04 344.61C313.78 344.61 313.56 344.39 313.56 344.13C313.56 343.87 313.77 343.65 314.04 343.65C314.31 343.65 314.52 343.87 314.52 344.13Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M325.77 335.7C325.77 335.96 325.55 336.18 325.29 336.18C325.03 336.18 324.81 335.96 324.81 335.7C324.81 335.44 325.02 335.22 325.29 335.22C325.56 335.22 325.77 335.44 325.77 335.7Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M287.81 312.13C287.81 312.39 287.59 312.61 287.33 312.61C287.07 312.61 286.85 312.39 286.85 312.13C286.85 311.87 287.06 311.65 287.33 311.65C287.6 311.65 287.81 311.87 287.81 312.13Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M256.29 321.09C256.29 321.35 256.07 321.57 255.81 321.57C255.55 321.57 255.33 321.35 255.33 321.09C255.33 320.83 255.54 320.61 255.81 320.61C256.08 320.61 256.29 320.83 256.29 321.09Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M244.02 326.95C244.02 327.21 243.8 327.43 243.54 327.43C243.28 327.43 243.06 327.21 243.06 326.95C243.06 326.69 243.27 326.47 243.54 326.47C243.81 326.47 244.02 326.69 244.02 326.95Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M385.77 318.38C385.77 318.64 385.55 318.86 385.29 318.86C385.03 318.86 384.81 318.64 384.81 318.38C384.81 318.12 385.02 317.9 385.29 317.9C385.56 317.9 385.77 318.12 385.77 318.38Z"
            fill="white"
          />
          <path
            d="M268.91 257.99L269.91 257.75L268.91 257.51C268.35 257.39 267.91 256.95 267.79 256.39L267.55 255.39L267.31 256.39C267.19 256.95 266.75 257.39 266.19 257.51L265.19 257.75L266.19 257.99C266.75 258.11 267.19 258.55 267.31 259.11L267.55 260.11L267.79 259.11C267.91 258.55 268.35 258.11 268.91 257.99Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M304 282.61C304 282.87 303.78 283.09 303.52 283.09C303.26 283.09 303.04 282.87 303.04 282.61C303.04 282.35 303.25 282.13 303.52 282.13C303.79 282.13 304 282.35 304 282.61Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M308.02 287.63C308.02 287.89 307.8 288.11 307.54 288.11C307.28 288.11 307.06 287.89 307.06 287.63C307.06 287.37 307.27 287.15 307.54 287.15C307.81 287.15 308.02 287.37 308.02 287.63Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M252.79 282.13C252.79 282.39 252.57 282.61 252.31 282.61C252.05 282.61 251.83 282.39 251.83 282.13C251.83 281.87 252.04 281.65 252.31 281.65C252.58 281.65 252.79 281.87 252.79 282.13Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M313.56 224.94C313.56 225.2 313.34 225.42 313.08 225.42C312.82 225.42 312.6 225.2 312.6 224.94C312.6 224.68 312.81 224.46 313.08 224.46C313.35 224.46 313.56 224.68 313.56 224.94Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M304.48 229.94C304.48 230.2 304.26 230.42 304 230.42C303.74 230.42 303.52 230.2 303.52 229.94C303.52 229.68 303.73 229.46 304 229.46C304.27 229.46 304.48 229.68 304.48 229.94Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M247.75 230.42C247.75 230.68 247.53 230.9 247.27 230.9C247.01 230.9 246.79 230.68 246.79 230.42C246.79 230.16 247 229.94 247.27 229.94C247.54 229.94 247.75 230.16 247.75 230.42Z"
            fill="white"
          />
          <path
            d="M345.52 282.61C345.52 283.2 345.04 283.68 344.45 283.68C343.86 283.68 343.38 283.2 343.38 282.61C343.38 282.02 343.86 281.54 344.45 281.54C345.04 281.54 345.52 282.02 345.52 282.61Z"
            fill="#8D5AA4"
          />
          <path
            opacity="0.6"
            d="M369.69 244.8C369.69 245.06 369.47 245.28 369.21 245.28C368.95 245.28 368.73 245.06 368.73 244.8C368.73 244.54 368.94 244.32 369.21 244.32C369.48 244.32 369.69 244.54 369.69 244.8Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M376.04 257.76C376.04 258.02 375.82 258.24 375.56 258.24C375.3 258.24 375.08 258.02 375.08 257.76C375.08 257.5 375.29 257.28 375.56 257.28C375.83 257.28 376.04 257.5 376.04 257.76Z"
            fill="white"
          />
          <path
            d="M379.27 300.21L380.27 299.97L379.27 299.73C378.71 299.61 378.27 299.17 378.15 298.61L377.91 297.61L377.67 298.61C377.55 299.17 377.11 299.61 376.55 299.73L375.55 299.97L376.55 300.21C377.11 300.33 377.55 300.77 377.67 301.33L377.91 302.33L378.15 301.33C378.27 300.77 378.71 300.33 379.27 300.21Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M95.0201 473.91C95.0201 474.17 94.8001 474.39 94.5401 474.39C94.2801 474.39 94.0601 474.17 94.0601 473.91C94.0601 473.65 94.2701 473.43 94.5401 473.43C94.8101 473.43 95.0201 473.65 95.0201 473.91Z"
            fill="white"
          />
          <path
            d="M60.53 466.06C60.53 466.26 60.37 466.43 60.16 466.43C59.95 466.43 59.79 466.26 59.79 466.06C59.79 465.86 59.96 465.69 60.16 465.69C60.36 465.69 60.53 465.86 60.53 466.06Z"
            fill="white"
          />
          <path
            d="M80.87 447.67C80.87 448.26 80.39 448.74 79.8 448.74C79.21 448.74 78.73 448.26 78.73 447.67C78.73 447.08 79.21 446.6 79.8 446.6C80.39 446.6 80.87 447.08 80.87 447.67Z"
            fill="#8D5AA4"
          />
          <path
            opacity="0.6"
            d="M135.85 434.48C135.85 434.74 135.63 434.96 135.37 434.96C135.11 434.96 134.89 434.74 134.89 434.48C134.89 434.22 135.1 434 135.37 434C135.64 434 135.85 434.22 135.85 434.48Z"
            fill="white"
          />
          <path
            opacity="0.75"
            d="M47.09 455.1C47.09 455.25 46.97 455.38 46.81 455.38C46.65 455.38 46.54 455.26 46.54 455.1C46.54 454.94 46.66 454.83 46.81 454.83C46.96 454.83 47.09 454.95 47.09 455.1Z"
            fill="white"
          />
          <path
            opacity="0.75"
            d="M154.15 391.94C154.15 392.09 154.03 392.22 153.87 392.22C153.71 392.22 153.6 392.1 153.6 391.94C153.6 391.78 153.72 391.67 153.87 391.67C154.02 391.67 154.15 391.79 154.15 391.94Z"
            fill="white"
          />
          <path
            d="M109.04 440.02L110.04 439.78L109.04 439.54C108.48 439.42 108.04 438.98 107.92 438.42L107.68 437.42L107.44 438.42C107.32 438.98 106.88 439.42 106.32 439.54L105.32 439.78L106.32 440.02C106.88 440.14 107.32 440.58 107.44 441.14L107.68 442.14L107.92 441.14C108.04 440.58 108.48 440.14 109.04 440.02Z"
            fill="white"
          />
          <path
            d="M122.88 343.2L123.88 342.96L122.88 342.72C122.32 342.6 121.88 342.16 121.76 341.6L121.52 340.6L121.28 341.6C121.16 342.16 120.72 342.6 120.16 342.72L119.16 342.96L120.16 343.2C120.72 343.32 121.16 343.76 121.28 344.32L121.52 345.32L121.76 344.32C121.88 343.76 122.32 343.32 122.88 343.2Z"
            fill="white"
          />
          <path
            d="M6.32005 422.18C6.32005 422.77 5.84005 423.25 5.25005 423.25C4.66005 423.25 4.18005 422.77 4.18005 422.18C4.18005 421.59 4.66005 421.11 5.25005 421.11C5.84005 421.11 6.32005 421.59 6.32005 422.18Z"
            fill="#8D5AA4"
          />
          <path
            opacity="0.6"
            d="M55.54 419.94C55.54 420.2 55.32 420.42 55.06 420.42C54.8 420.42 54.58 420.2 54.58 419.94C54.58 419.68 54.79 419.46 55.06 419.46C55.33 419.46 55.54 419.68 55.54 419.94Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M268.02 360.07C268.02 360.33 267.8 360.55 267.54 360.55C267.28 360.55 267.06 360.33 267.06 360.07C267.06 359.81 267.27 359.59 267.54 359.59C267.81 359.59 268.02 359.81 268.02 360.07Z"
            fill="white"
          />
          <path
            opacity="0.75"
            d="M206.02 326.76C206.02 326.91 205.9 327.04 205.74 327.04C205.58 327.04 205.47 326.92 205.47 326.76C205.47 326.6 205.59 326.49 205.74 326.49C205.89 326.49 206.02 326.61 206.02 326.76Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M214.89 345.77C214.89 346.03 214.67 346.25 214.41 346.25C214.15 346.25 213.93 346.03 213.93 345.77C213.93 345.51 214.14 345.29 214.41 345.29C214.68 345.29 214.89 345.51 214.89 345.77Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M217.77 297.39C217.77 297.65 217.55 297.87 217.29 297.87C217.03 297.87 216.81 297.65 216.81 297.39C216.81 297.13 217.02 296.91 217.29 296.91C217.56 296.91 217.77 297.13 217.77 297.39Z"
            fill="white"
          />
          <path
            opacity="0.75"
            d="M181.33 349.41C181.33 349.56 181.21 349.69 181.05 349.69C180.89 349.69 180.78 349.57 180.78 349.41C180.78 349.25 180.9 349.14 181.05 349.14C181.2 349.14 181.33 349.26 181.33 349.41Z"
            fill="white"
          />
          <path
            opacity="0.75"
            d="M45.34 408.93C45.34 409.08 45.22 409.21 45.06 409.21C44.9 409.21 44.79 409.09 44.79 408.93C44.79 408.77 44.91 408.66 45.06 408.66C45.21 408.66 45.34 408.78 45.34 408.93Z"
            fill="white"
          />
          <path
            opacity="0.75"
            d="M125.56 384.19C125.56 384.34 125.44 384.47 125.28 384.47C125.12 384.47 125.01 384.35 125.01 384.19C125.01 384.03 125.13 383.92 125.28 383.92C125.43 383.92 125.56 384.04 125.56 384.19Z"
            fill="white"
          />
          <path
            opacity="0.75"
            d="M68.06 380.39C68.06 380.54 67.94 380.67 67.78 380.67C67.62 380.67 67.51 380.55 67.51 380.39C67.51 380.23 67.63 380.12 67.78 380.12C67.93 380.12 68.06 380.24 68.06 380.39Z"
            fill="white"
          />
          <path
            d="M74.74 272.68L75.74 272.44L74.74 272.2C74.18 272.08 73.74 271.64 73.62 271.08L73.38 270.08L73.14 271.08C73.02 271.64 72.58 272.08 72.02 272.2L71.02 272.44L72.02 272.68C72.58 272.8 73.02 273.24 73.14 273.8L73.38 274.8L73.62 273.8C73.74 273.24 74.18 272.8 74.74 272.68Z"
            fill="white"
          />
          <path
            opacity="0.75"
            d="M101.24 280.76C101.24 280.91 101.12 281.04 100.96 281.04C100.8 281.04 100.69 280.92 100.69 280.76C100.69 280.6 100.81 280.49 100.96 280.49C101.11 280.49 101.24 280.61 101.24 280.76Z"
            fill="white"
          />
          <path
            opacity="0.75"
            d="M157.06 322.96C157.06 323.11 156.94 323.24 156.78 323.24C156.62 323.24 156.51 323.12 156.51 322.96C156.51 322.8 156.63 322.69 156.78 322.69C156.93 322.69 157.06 322.81 157.06 322.96Z"
            fill="white"
          />
          <path
            opacity="0.75"
            d="M171.75 343.93C171.75 344.08 171.63 344.21 171.47 344.21C171.31 344.21 171.2 344.09 171.2 343.93C171.2 343.77 171.32 343.66 171.47 343.66C171.62 343.66 171.75 343.78 171.75 343.93Z"
            fill="white"
          />
          <path
            opacity="0.75"
            d="M88.84 406.46C88.84 406.61 88.72 406.74 88.56 406.74C88.4 406.74 88.29 406.62 88.29 406.46C88.29 406.3 88.41 406.19 88.56 406.19C88.71 406.19 88.84 406.31 88.84 406.46Z"
            fill="white"
          />
          <path
            d="M72.11 310.94C72.11 311.53 71.63 312.01 71.04 312.01C70.45 312.01 69.97 311.53 69.97 310.94C69.97 310.35 70.45 309.87 71.04 309.87C71.63 309.87 72.11 310.35 72.11 310.94Z"
            fill="#8D5AA4"
          />
          <path
            opacity="0.75"
            d="M46.81 344.93C46.81 345.08 46.69 345.21 46.53 345.21C46.37 345.21 46.26 345.09 46.26 344.93C46.26 344.77 46.38 344.66 46.53 344.66C46.68 344.66 46.81 344.78 46.81 344.93Z"
            fill="white"
          />
          <path
            opacity="0.75"
            d="M188.34 308.18C188.34 308.33 188.22 308.46 188.06 308.46C187.9 308.46 187.79 308.34 187.79 308.18C187.79 308.02 187.91 307.91 188.06 307.91C188.21 307.91 188.34 308.03 188.34 308.18Z"
            fill="white"
          />
          <path
            opacity="0.75"
            d="M81.15 316.41C81.15 316.56 81.03 316.69 80.87 316.69C80.71 316.69 80.6 316.57 80.6 316.41C80.6 316.25 80.72 316.14 80.87 316.14C81.02 316.14 81.15 316.26 81.15 316.41Z"
            fill="white"
          />
          <path
            opacity="0.75"
            d="M89.1101 354.4C89.1101 354.55 88.9901 354.68 88.8301 354.68C88.6701 354.68 88.5601 354.56 88.5601 354.4C88.5601 354.24 88.6801 354.13 88.8301 354.13C88.9801 354.13 89.1101 354.25 89.1101 354.4Z"
            fill="white"
          />
          <path
            opacity="0.75"
            d="M132.21 355.06C132.21 355.21 132.09 355.34 131.93 355.34C131.77 355.34 131.66 355.22 131.66 355.06C131.66 354.9 131.78 354.79 131.93 354.79C132.08 354.79 132.21 354.91 132.21 355.06Z"
            fill="white"
          />
          <path
            opacity="0.75"
            d="M193.34 268.68C193.34 268.83 193.22 268.96 193.06 268.96C192.9 268.96 192.79 268.84 192.79 268.68C192.79 268.52 192.91 268.41 193.06 268.41C193.21 268.41 193.34 268.53 193.34 268.68Z"
            fill="white"
          />
          <path
            opacity="0.75"
            d="M136.04 241.91C136.04 242.06 135.92 242.19 135.76 242.19C135.6 242.19 135.49 242.07 135.49 241.91C135.49 241.75 135.61 241.64 135.76 241.64C135.91 241.64 136.04 241.76 136.04 241.91Z"
            fill="white"
          />
          <path
            opacity="0.75"
            d="M125.56 307.63C125.56 307.78 125.44 307.91 125.28 307.91C125.12 307.91 125.01 307.79 125.01 307.63C125.01 307.47 125.13 307.36 125.28 307.36C125.43 307.36 125.56 307.48 125.56 307.63Z"
            fill="white"
          />
          <path
            d="M154.48 286.48C154.48 287.07 154 287.55 153.41 287.55C152.82 287.55 152.34 287.07 152.34 286.48C152.34 285.89 152.82 285.41 153.41 285.41C154 285.41 154.48 285.89 154.48 286.48Z"
            fill="#8D5AA4"
          />
          <path
            opacity="0.6"
            d="M34.0201 279.68C34.0201 279.91 33.8001 280.1 33.5401 280.1C33.2801 280.1 33.0601 279.91 33.0601 279.68C33.0601 279.45 33.2701 279.26 33.5401 279.26C33.8101 279.26 34.0201 279.45 34.0201 279.68Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M23.51 267.93C23.51 268.16 23.29 268.35 23.03 268.35C22.77 268.35 22.55 268.16 22.55 267.93C22.55 267.7 22.76 267.51 23.03 267.51C23.3 267.51 23.51 267.7 23.51 267.93Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M50.2701 242.89C50.2701 243.12 50.0501 243.31 49.7901 243.31C49.5301 243.31 49.3101 243.12 49.3101 242.89C49.3101 242.66 49.5201 242.47 49.7901 242.47C50.0601 242.47 50.2701 242.66 50.2701 242.89Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M86.7701 238.35C86.7701 238.58 86.5501 238.77 86.2901 238.77C86.0301 238.77 85.8101 238.58 85.8101 238.35C85.8101 238.12 86.0201 237.93 86.2901 237.93C86.5601 237.93 86.7701 238.12 86.7701 238.35Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M93.0201 234.06C93.0201 234.29 92.8001 234.48 92.5401 234.48C92.2801 234.48 92.0601 234.29 92.0601 234.06C92.0601 233.83 92.2701 233.64 92.5401 233.64C92.8101 233.64 93.0201 233.83 93.0201 234.06Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M165.77 211.26C165.77 211.49 165.55 211.68 165.29 211.68C165.03 211.68 164.81 211.49 164.81 211.26C164.81 211.03 165.02 210.84 165.29 210.84C165.56 210.84 165.77 211.03 165.77 211.26Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M218.25 244.32C218.25 244.55 218.03 244.74 217.77 244.74C217.51 244.74 217.29 244.55 217.29 244.32C217.29 244.09 217.5 243.9 217.77 243.9C218.04 243.9 218.25 244.09 218.25 244.32Z"
            fill="white"
          />
          <path
            d="M117.62 205.75C117.62 206.34 117.14 206.82 116.55 206.82C115.96 206.82 115.48 206.34 115.48 205.75C115.48 205.16 115.96 204.68 116.55 204.68C117.14 204.68 117.62 205.16 117.62 205.75Z"
            fill="#8D5AA4"
          />
          <path
            opacity="0.6"
            d="M68 210.84C68 211.07 67.78 211.26 67.52 211.26C67.26 211.26 67.04 211.07 67.04 210.84C67.04 210.61 67.25 210.42 67.52 210.42C67.79 210.42 68 210.61 68 210.84Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M60.6401 204.68C60.6401 204.91 60.4201 205.1 60.1601 205.1C59.9001 205.1 59.6801 204.91 59.6801 204.68C59.6801 204.45 59.8901 204.26 60.1601 204.26C60.4301 204.26 60.6401 204.45 60.6401 204.68Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M8.27995 244.75C8.27995 244.98 8.05995 245.17 7.79995 245.17C7.53995 245.17 7.31995 244.98 7.31995 244.75C7.31995 244.52 7.52995 244.33 7.79995 244.33C8.06995 244.33 8.27995 244.52 8.27995 244.75Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M13.81 210.73C13.81 210.96 13.59 211.15 13.33 211.15C13.07 211.15 12.85 210.96 12.85 210.73C12.85 210.5 13.06 210.31 13.33 210.31C13.6 210.31 13.81 210.5 13.81 210.73Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M309.96 470.38C309.96 470.64 309.74 470.86 309.48 470.86C309.22 470.86 309 470.64 309 470.38C309 470.12 309.21 469.9 309.48 469.9C309.75 469.9 309.96 470.12 309.96 470.38Z"
            fill="white"
          />
          <path
            opacity="0.75"
            d="M1.52998 398.47C1.52998 398.62 1.40998 398.75 1.24998 398.75C1.08998 398.75 0.97998 398.63 0.97998 398.47C0.97998 398.31 1.09998 398.2 1.24998 398.2C1.39998 398.2 1.52998 398.32 1.52998 398.47Z"
            fill="white"
          />
          <path
            opacity="0.75"
            d="M6.77998 403.72C6.77998 403.87 6.65998 404 6.49998 404C6.33998 404 6.22998 403.88 6.22998 403.72C6.22998 403.56 6.34998 403.45 6.49998 403.45C6.64998 403.45 6.77998 403.57 6.77998 403.72Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M193.29 453.52C193.29 453.78 193.07 454 192.81 454C192.55 454 192.33 453.78 192.33 453.52C192.33 453.26 192.54 453.04 192.81 453.04C193.08 453.04 193.29 453.26 193.29 453.52Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M238.08 386.02C238.08 386.28 237.86 386.5 237.6 386.5C237.34 386.5 237.12 386.28 237.12 386.02C237.12 385.76 237.33 385.54 237.6 385.54C237.87 385.54 238.08 385.76 238.08 386.02Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M303.23 475.33C303.23 475.59 303.01 475.81 302.75 475.81C302.49 475.81 302.27 475.59 302.27 475.33C302.27 475.07 302.48 474.85 302.75 474.85C303.02 474.85 303.23 475.07 303.23 475.33Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M315.71 490C315.71 490.26 315.49 490.48 315.23 490.48C314.97 490.48 314.75 490.26 314.75 490C314.75 489.74 314.96 489.52 315.23 489.52C315.5 489.52 315.71 489.74 315.71 490Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M276.98 479.67C276.98 479.93 276.76 480.15 276.5 480.15C276.24 480.15 276.02 479.93 276.02 479.67C276.02 479.41 276.23 479.19 276.5 479.19C276.77 479.19 276.98 479.41 276.98 479.67Z"
            fill="white"
          />
          <path
            opacity="0.75"
            d="M204.27 443.61C204.27 443.76 204.15 443.89 203.99 443.89C203.83 443.89 203.72 443.77 203.72 443.61C203.72 443.45 203.84 443.34 203.99 443.34C204.14 443.34 204.27 443.46 204.27 443.61Z"
            fill="white"
          />
          <path
            opacity="0.75"
            d="M232.94 476.37C232.94 476.52 232.82 476.65 232.66 476.65C232.5 476.65 232.39 476.53 232.39 476.37C232.39 476.21 232.51 476.1 232.66 476.1C232.81 476.1 232.94 476.22 232.94 476.37Z"
            fill="white"
          />
          <path
            d="M181.59 427.74C181.59 428.33 181.11 428.81 180.52 428.81C179.93 428.81 179.45 428.33 179.45 427.74C179.45 427.15 179.93 426.67 180.52 426.67C181.11 426.67 181.59 427.15 181.59 427.74Z"
            fill="#8D5AA4"
          />
          <path
            opacity="0.6"
            d="M158.12 467.14C158.12 467.4 157.9 467.62 157.64 467.62C157.38 467.62 157.16 467.4 157.16 467.14C157.16 466.88 157.37 466.66 157.64 466.66C157.91 466.66 158.12 466.88 158.12 467.14Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M171.82 484.18C171.82 484.44 171.6 484.66 171.34 484.66C171.08 484.66 170.86 484.44 170.86 484.18C170.86 483.92 171.07 483.7 171.34 483.7C171.61 483.7 171.82 483.92 171.82 484.18Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M153.89 484.03C153.89 484.29 153.67 484.51 153.41 484.51C153.15 484.51 152.93 484.29 152.93 484.03C152.93 483.77 153.14 483.55 153.41 483.55C153.68 483.55 153.89 483.77 153.89 484.03Z"
            fill="white"
          />
        </g>
      </g>
      <g clipPath="url(#clip3_0_1)">
        <rect width="390" height="606" fill="#0f1f39" />

        <path
          style={{ mixBlendMode: "color-dodge" }}
          d="M124.23 75.18C124.23 87.98 122.43 98.36 120.22 98.36C118.01 98.36 116.21 87.98 116.21 75.18C116.21 62.38 118 52 120.22 52C122.44 52 124.23 62.38 124.23 75.18Z"
          fill="url(#paint2_radial_0_1)"
        />
        <path
          style={{ mixBlendMode: "color-dodge" }}
          d="M120.31 79.18C107.48 79.46 97.0501 77.89 97.0001 75.69C96.9501 73.48 107.31 71.46 120.13 71.18C132.96 70.91 143.39 72.47 143.44 74.68C143.48 76.89 133.13 78.9 120.31 79.18Z"
          fill="url(#paint3_radial_0_1)"
        />
      </g>
      <g clipPath="url(#clip4_0_1)">
        <rect width="390" height="606" fill="#0f1f39" />

        <path
          style={{ mixBlendMode: "color-dodge" }}
          d="M42.78 131.8C42.78 144.39 41.01 154.6 38.83 154.6C36.65 154.6 34.89 144.39 34.89 131.8C34.89 119.21 36.66 109 38.84 109C41.02 109 42.79 119.21 42.79 131.8H42.78Z"
          fill="url(#paint4_radial_0_1)"
        />
        <path
          style={{ mixBlendMode: "color-dodge" }}
          d="M38.9199 135.74C26.3099 136.01 16.0499 134.47 15.9999 132.3C15.9499 130.12 26.1399 128.13 38.7599 127.86C51.3799 127.59 61.6399 129.12 61.6799 131.31C61.7199 133.49 51.5399 135.46 38.9199 135.74Z"
          fill="url(#paint5_radial_0_1)"
        />
        <path
          style={{ mixBlendMode: "color-dodge" }}
          d="M36.07 134.6C27.09 125.76 21.04 117.34 22.58 115.78C24.1 114.24 32.63 120.15 41.6 128.99C50.58 137.83 56.63 146.25 55.09 147.81C53.56 149.35 45.05 143.44 36.07 134.6Z"
          fill="url(#paint6_radial_0_1)"
        />
        <path
          style={{ mixBlendMode: "color-dodge" }}
          d="M41.76 134.44C33.3 143.78 25.12 150.16 23.51 148.7C21.89 147.24 27.45 138.5 35.91 129.16C44.38 119.82 52.55 113.44 54.17 114.9C55.78 116.37 50.23 125.11 41.77 134.44H41.76Z"
          fill="url(#paint7_radial_0_1)"
        />
      </g>
      <g clipPath="url(#clip5_0_1)">
        <rect width="390" height="606" fill="#0f1f39" />

        <path
          style={{ mixBlendMode: "color-dodge" }}
          d="M50.23 268.18C50.23 280.98 48.43 291.36 46.22 291.36C44.01 291.36 42.21 280.98 42.21 268.18C42.21 255.38 44 245 46.22 245C48.44 245 50.23 255.38 50.23 268.18Z"
          fill="url(#paint8_radial_0_1)"
        />
        <path
          style={{ mixBlendMode: "color-dodge" }}
          d="M46.3101 272.18C33.4801 272.46 23.0501 270.89 23.0001 268.69C22.9501 266.48 33.3101 264.46 46.1301 264.18C58.9601 263.91 69.3901 265.47 69.4401 267.68C69.4801 269.89 59.1301 271.9 46.3101 272.18Z"
          fill="url(#paint9_radial_0_1)"
        />
      </g>
      <g opacity="0.2" clipPath="url(#clip6_0_1)">
        <path d="M347.572 587H346.749V587.825H347.572V587Z" fill="#373B3E" />
        <path d="M348.392 587H347.572V587.825H348.392V587Z" fill="#26282A" />
        <path d="M357.428 587H356.608V587.825H357.428V587Z" fill="#373B3E" />
        <path d="M358.248 587H357.428V587.825H358.248V587Z" fill="#26282A" />
        <path
          d="M347.572 587.825H346.749V588.65H347.572V587.825Z"
          fill="#43484B"
        />
        <path
          d="M348.392 587.825H347.572V588.65H348.392V587.825Z"
          fill="#373B3E"
        />
        <path
          d="M357.428 587.825H356.608V588.65H357.428V587.825Z"
          fill="#43484B"
        />
        <path
          d="M358.248 587.825H357.428V588.65H358.248V587.825Z"
          fill="#373B3E"
        />
        <path
          d="M346.749 588.653H345.929V589.477H346.749V588.653Z"
          fill="#F0FFFF"
        />
        <path
          d="M347.572 588.653H346.749V589.477H347.572V588.653Z"
          fill="#373B3E"
        />
        <path
          d="M348.392 588.653H347.572V589.477H348.392V588.653Z"
          fill="#26282A"
        />
        <path
          d="M357.428 588.653H356.608V589.477H357.428V588.653Z"
          fill="#373B3E"
        />
        <path
          d="M358.248 588.653H357.428V589.477H358.248V588.653Z"
          fill="#26282A"
        />
        <path
          d="M359.071 588.653H358.251V589.477H359.071V588.653Z"
          fill="#F0FFFF"
        />
        <path
          d="M346.749 589.477H345.929V590.302H346.749V589.477Z"
          fill="#F0FFFF"
        />
        <path
          d="M347.572 589.477H346.749V590.302H347.572V589.477Z"
          fill="#373B3E"
        />
        <path
          d="M348.392 589.477H347.572V590.302H348.392V589.477Z"
          fill="#26282A"
        />
        <path
          d="M357.428 589.477H356.608V590.302H357.428V589.477Z"
          fill="#373B3E"
        />
        <path
          d="M358.248 589.477H357.428V590.302H358.248V589.477Z"
          fill="#26282A"
        />
        <path
          d="M359.071 589.477H358.251V590.302H359.071V589.477Z"
          fill="#F0FFFF"
        />
        <path
          d="M345.927 590.305H345.106V591.13H345.927V590.305Z"
          fill="#F0FFFF"
        />
        <path
          d="M346.749 590.305H345.929V591.13H346.749V590.305Z"
          fill="#D0E1E9"
        />
        <path
          d="M347.572 590.305H346.749V591.13H347.572V590.305Z"
          fill="#373B3E"
        />
        <path
          d="M348.392 590.305H347.572V591.13H348.392V590.305Z"
          fill="#26282A"
        />
        <path
          d="M357.428 590.305H356.608V591.13H357.428V590.305Z"
          fill="#373B3E"
        />
        <path
          d="M358.248 590.305H357.428V591.13H358.248V590.305Z"
          fill="#26282A"
        />
        <path
          d="M359.071 590.305H358.251V591.13H359.071V590.305Z"
          fill="#D0E1E9"
        />
        <path
          d="M359.891 590.305H359.071V591.13H359.891V590.305Z"
          fill="#809AA7"
        />
        <path
          d="M342.643 591.13H341.82V591.958H342.643V591.13Z"
          fill="#373B3E"
        />
        <path
          d="M343.463 591.13H342.643V591.958H343.463V591.13Z"
          fill="#26282A"
        />
        <path
          d="M345.927 591.13H345.106V591.958H345.927V591.13Z"
          fill="#F0FFFF"
        />
        <path
          d="M346.749 591.13H345.929V591.958H346.749V591.13Z"
          fill="#D0E1E9"
        />
        <path
          d="M347.572 591.13H346.749V591.958H347.572V591.13Z"
          fill="#373B3E"
        />
        <path
          d="M348.392 591.13H347.572V591.958H348.392V591.13Z"
          fill="#26282A"
        />
        <path
          d="M350.856 591.13H350.035V591.958H350.856V591.13Z"
          fill="#F0FFFF"
        />
        <path
          d="M351.676 591.13H350.856V591.958H351.676V591.13Z"
          fill="#9AB0BB"
        />
        <path
          d="M354.142 591.13H353.322V591.958H354.142V591.13Z"
          fill="#F0FFFF"
        />
        <path
          d="M354.962 591.13H354.142V591.958H354.962V591.13Z"
          fill="#9AB0BB"
        />
        <path
          d="M357.428 591.13H356.608V591.958H357.428V591.13Z"
          fill="#373B3E"
        />
        <path
          d="M358.248 591.13H357.428V591.958H358.248V591.13Z"
          fill="#26282A"
        />
        <path
          d="M359.071 591.13H358.251V591.958H359.071V591.13Z"
          fill="#D0E1E9"
        />
        <path
          d="M359.891 591.13H359.071V591.958H359.891V591.13Z"
          fill="#809AA7"
        />
        <path
          d="M362.354 591.13H361.534V591.958H362.354V591.13Z"
          fill="#373B3E"
        />
        <path
          d="M363.18 591.13H362.357V591.958H363.18V591.13Z"
          fill="#26282A"
        />
        <path
          d="M342.643 591.958H341.82V592.782H342.643V591.958Z"
          fill="#43484B"
        />
        <path
          d="M343.463 591.958H342.643V592.782H343.463V591.958Z"
          fill="#373B3E"
        />
        <path
          d="M345.106 591.958H344.286V592.782H345.106V591.958Z"
          fill="#F0FFFF"
        />
        <path
          d="M345.927 591.958H345.106V592.782H345.927V591.958Z"
          fill="#D0E1E9"
        />
        <path
          d="M346.749 591.958H345.929V592.782H346.749V591.958Z"
          fill="#D0E1E9"
        />
        <path
          d="M347.572 591.958H346.749V592.782H347.572V591.958Z"
          fill="#373B3E"
        />
        <path
          d="M348.392 591.958H347.572V592.782H348.392V591.958Z"
          fill="#26282A"
        />
        <path
          d="M350.856 591.958H350.035V592.782H350.856V591.958Z"
          fill="#D0E1E9"
        />
        <path
          d="M351.676 591.958H350.856V592.782H351.676V591.958Z"
          fill="#809AA7"
        />
        <path
          d="M354.142 591.958H353.322V592.782H354.142V591.958Z"
          fill="#D0E1E9"
        />
        <path
          d="M354.962 591.958H354.142V592.782H354.962V591.958Z"
          fill="#809AA7"
        />
        <path
          d="M357.428 591.958H356.608V592.782H357.428V591.958Z"
          fill="#373B3E"
        />
        <path
          d="M358.248 591.958H357.428V592.782H358.248V591.958Z"
          fill="#26282A"
        />
        <path
          d="M359.071 591.958H358.251V592.782H359.071V591.958Z"
          fill="#D0E1E9"
        />
        <path
          d="M359.891 591.958H359.071V592.782H359.891V591.958Z"
          fill="#9AB0BB"
        />
        <path
          d="M360.714 591.958H359.894V592.782H360.714V591.958Z"
          fill="#809AA7"
        />
        <path
          d="M362.354 591.958H361.534V592.782H362.354V591.958Z"
          fill="#43484B"
        />
        <path
          d="M363.18 591.958H362.357V592.782H363.18V591.958Z"
          fill="#373B3E"
        />
        <path
          d="M342.643 592.783H341.82V593.607H342.643V592.783Z"
          fill="#373B3E"
        />
        <path
          d="M343.463 592.783H342.643V593.607H343.463V592.783Z"
          fill="#26282A"
        />
        <path
          d="M345.106 592.783H344.286V593.607H345.106V592.783Z"
          fill="#F0FFFF"
        />
        <path
          d="M345.927 592.783H345.106V593.607H345.927V592.783Z"
          fill="#D0E1E9"
        />
        <path
          d="M346.749 592.783H345.929V593.607H346.749V592.783Z"
          fill="#D0E1E9"
        />
        <path
          d="M347.572 592.783H346.749V593.607H347.572V592.783Z"
          fill="#809AA7"
        />
        <path
          d="M348.392 592.783H347.572V593.607H348.392V592.783Z"
          fill="#F0FFFF"
        />
        <path
          d="M349.213 592.783H348.392V593.607H349.213V592.783Z"
          fill="#F0FFFF"
        />
        <path
          d="M350.856 592.783H350.035V593.607H350.856V592.783Z"
          fill="#D0E1E9"
        />
        <path
          d="M351.676 592.783H350.856V593.607H351.676V592.783Z"
          fill="#809AA7"
        />
        <path
          d="M354.142 592.783H353.322V593.607H354.142V592.783Z"
          fill="#D0E1E9"
        />
        <path
          d="M354.962 592.783H354.142V593.607H354.962V592.783Z"
          fill="#809AA7"
        />
        <path
          d="M356.608 592.783H355.785V593.607H356.608V592.783Z"
          fill="#F0FFFF"
        />
        <path
          d="M357.428 592.783H356.608V593.607H357.428V592.783Z"
          fill="#F0FFFF"
        />
        <path
          d="M358.248 592.783H357.428V593.607H358.248V592.783Z"
          fill="#F0FFFF"
        />
        <path
          d="M359.071 592.783H358.251V593.607H359.071V592.783Z"
          fill="#D0E1E9"
        />
        <path
          d="M359.891 592.783H359.071V593.607H359.891V592.783Z"
          fill="#D0E1E9"
        />
        <path
          d="M360.714 592.783H359.894V593.607H360.714V592.783Z"
          fill="#809AA7"
        />
        <path
          d="M362.354 592.783H361.534V593.607H362.354V592.783Z"
          fill="#373B3E"
        />
        <path
          d="M363.18 592.783H362.357V593.607H363.18V592.783Z"
          fill="#26282A"
        />
        <path
          d="M342.643 593.61H341.82V594.435H342.643V593.61Z"
          fill="#373B3E"
        />
        <path
          d="M343.463 593.61H342.643V594.435H343.463V593.61Z"
          fill="#26282A"
        />
        <path
          d="M344.283 593.61H343.463V594.435H344.283V593.61Z"
          fill="#F0FFFF"
        />
        <path
          d="M345.106 593.61H344.286V594.435H345.106V593.61Z"
          fill="#D0E1E9"
        />
        <path
          d="M345.927 593.61H345.106V594.435H345.927V593.61Z"
          fill="#D0E1E9"
        />
        <path
          d="M346.749 593.61H345.929V594.435H346.749V593.61Z"
          fill="#D0E1E9"
        />
        <path
          d="M347.572 593.61H346.749V594.435H347.572V593.61Z"
          fill="#809AA7"
        />
        <path
          d="M348.392 593.61H347.572V594.435H348.392V593.61Z"
          fill="#D0E1E9"
        />
        <path
          d="M349.213 593.61H348.392V594.435H349.213V593.61Z"
          fill="#D0E1E9"
        />
        <path
          d="M350.035 593.61H349.215V594.435H350.035V593.61Z"
          fill="#D0E1E9"
        />
        <path
          d="M350.856 593.61H350.035V594.435H350.856V593.61Z"
          fill="#D0E1E9"
        />
        <path
          d="M351.676 593.61H350.856V594.435H351.676V593.61Z"
          fill="#D0E1E9"
        />
        <path
          d="M352.499 593.61H351.678V594.435H352.499V593.61Z"
          fill="#D0E1E9"
        />
        <path
          d="M353.319 593.61H352.499V594.435H353.319V593.61Z"
          fill="#D0E1E9"
        />
        <path
          d="M354.142 593.61H353.322V594.435H354.142V593.61Z"
          fill="#D0E1E9"
        />
        <path
          d="M354.962 593.61H354.142V594.435H354.962V593.61Z"
          fill="#D0E1E9"
        />
        <path
          d="M355.785 593.61H354.965V594.435H355.785V593.61Z"
          fill="#D0E1E9"
        />
        <path
          d="M356.608 593.61H355.785V594.435H356.608V593.61Z"
          fill="#D0E1E9"
        />
        <path
          d="M357.428 593.61H356.608V594.435H357.428V593.61Z"
          fill="#D0E1E9"
        />
        <path
          d="M358.248 593.61H357.428V594.435H358.248V593.61Z"
          fill="#F0FFFF"
        />
        <path
          d="M359.071 593.61H358.251V594.435H359.071V593.61Z"
          fill="#D0E1E9"
        />
        <path
          d="M359.891 593.61H359.071V594.435H359.891V593.61Z"
          fill="#D0E1E9"
        />
        <path
          d="M360.714 593.61H359.894V594.435H360.714V593.61Z"
          fill="#9AB0BB"
        />
        <path
          d="M361.534 593.61H360.714V594.435H361.534V593.61Z"
          fill="#809AA7"
        />
        <path
          d="M362.354 593.61H361.534V594.435H362.354V593.61Z"
          fill="#373B3E"
        />
        <path
          d="M363.18 593.61H362.357V594.435H363.18V593.61Z"
          fill="#26282A"
        />
        <path
          d="M342.643 594.435H341.82V595.263H342.643V594.435Z"
          fill="#373B3E"
        />
        <path
          d="M343.463 594.435H342.643V595.263H343.463V594.435Z"
          fill="#26282A"
        />
        <path
          d="M344.283 594.435H343.463V595.263H344.283V594.435Z"
          fill="#F0FFFF"
        />
        <path
          d="M345.106 594.435H344.286V595.263H345.106V594.435Z"
          fill="#D0E1E9"
        />
        <path
          d="M345.927 594.435H345.106V595.263H345.927V594.435Z"
          fill="#D0E1E9"
        />
        <path
          d="M346.749 594.435H345.929V595.263H346.749V594.435Z"
          fill="#D0E1E9"
        />
        <path
          d="M347.572 594.435H346.749V595.263H347.572V594.435Z"
          fill="#809AA7"
        />
        <path
          d="M348.392 594.435H347.572V595.263H348.392V594.435Z"
          fill="#D0E1E9"
        />
        <path
          d="M349.213 594.435H348.392V595.263H349.213V594.435Z"
          fill="#D0E1E9"
        />
        <path
          d="M350.035 594.435H349.215V595.263H350.035V594.435Z"
          fill="#394B53"
        />
        <path
          d="M350.856 594.435H350.035V595.263H350.856V594.435Z"
          fill="#394B53"
        />
        <path
          d="M351.676 594.435H350.856V595.263H351.676V594.435Z"
          fill="#394B53"
        />
        <path
          d="M352.499 594.435H351.678V595.263H352.499V594.435Z"
          fill="#394B53"
        />
        <path
          d="M353.319 594.435H352.499V595.263H353.319V594.435Z"
          fill="#394B53"
        />
        <path
          d="M354.142 594.435H353.322V595.263H354.142V594.435Z"
          fill="#394B53"
        />
        <path
          d="M354.962 594.435H354.142V595.263H354.962V594.435Z"
          fill="#394B53"
        />
        <path
          d="M355.785 594.435H354.965V595.263H355.785V594.435Z"
          fill="#394B53"
        />
        <path
          d="M356.608 594.435H355.785V595.263H356.608V594.435Z"
          fill="#D0E1E9"
        />
        <path
          d="M357.428 594.435H356.608V595.263H357.428V594.435Z"
          fill="#D0E1E9"
        />
        <path
          d="M358.248 594.435H357.428V595.263H358.248V594.435Z"
          fill="#F0FFFF"
        />
        <path
          d="M359.071 594.435H358.251V595.263H359.071V594.435Z"
          fill="#D0E1E9"
        />
        <path
          d="M359.891 594.435H359.071V595.263H359.891V594.435Z"
          fill="#D0E1E9"
        />
        <path
          d="M360.714 594.435H359.894V595.263H360.714V594.435Z"
          fill="#D0E1E9"
        />
        <path
          d="M361.534 594.435H360.714V595.263H361.534V594.435Z"
          fill="#809AA7"
        />
        <path
          d="M362.354 594.435H361.534V595.263H362.354V594.435Z"
          fill="#373B3E"
        />
        <path
          d="M363.18 594.435H362.357V595.263H363.18V594.435Z"
          fill="#26282A"
        />
        <path d="M341.82 595.263H341V596.088H341.82V595.263Z" fill="#F0FFFF" />
        <path
          d="M342.643 595.263H341.82V596.088H342.643V595.263Z"
          fill="#D0E1E9"
        />
        <path
          d="M343.463 595.263H342.643V596.088H343.463V595.263Z"
          fill="#D0E1E9"
        />
        <path
          d="M344.283 595.263H343.463V596.088H344.283V595.263Z"
          fill="#D0E1E9"
        />
        <path
          d="M345.106 595.263H344.286V596.088H345.106V595.263Z"
          fill="#D0E1E9"
        />
        <path
          d="M345.927 595.263H345.106V596.088H345.927V595.263Z"
          fill="#D0E1E9"
        />
        <path
          d="M346.749 595.263H345.929V596.088H346.749V595.263Z"
          fill="#9AB0BB"
        />
        <path
          d="M347.572 595.263H346.749V596.088H347.572V595.263Z"
          fill="#809AA7"
        />
        <path
          d="M348.392 595.263H347.572V596.088H348.392V595.263Z"
          fill="#D0E1E9"
        />
        <path
          d="M349.213 595.263H348.392V596.088H349.213V595.263Z"
          fill="#394B53"
        />
        <path
          d="M350.035 595.263H349.215V596.088H350.035V595.263Z"
          fill="#51DFF2"
        />
        <path
          d="M350.856 595.263H350.035V596.088H350.856V595.263Z"
          fill="#51DFF2"
        />
        <path
          d="M351.676 595.263H350.856V596.088H351.676V595.263Z"
          fill="#51DFF2"
        />
        <path
          d="M352.499 595.263H351.678V596.088H352.499V595.263Z"
          fill="#05B8CF"
        />
        <path
          d="M353.319 595.263H352.499V596.088H353.319V595.263Z"
          fill="#51DFF2"
        />
        <path
          d="M354.142 595.263H353.322V596.088H354.142V595.263Z"
          fill="#51DFF2"
        />
        <path
          d="M354.962 595.263H354.142V596.088H354.962V595.263Z"
          fill="#51DFF2"
        />
        <path
          d="M355.785 595.263H354.965V596.088H355.785V595.263Z"
          fill="#05B8CF"
        />
        <path
          d="M356.608 595.263H355.785V596.088H356.608V595.263Z"
          fill="#394B53"
        />
        <path
          d="M357.428 595.263H356.608V596.088H357.428V595.263Z"
          fill="#D0E1E9"
        />
        <path
          d="M358.248 595.263H357.428V596.088H358.248V595.263Z"
          fill="#F0FFFF"
        />
        <path
          d="M359.071 595.263H358.251V596.088H359.071V595.263Z"
          fill="#D0E1E9"
        />
        <path
          d="M359.891 595.263H359.071V596.088H359.891V595.263Z"
          fill="#D0E1E9"
        />
        <path
          d="M360.714 595.263H359.894V596.088H360.714V595.263Z"
          fill="#D0E1E9"
        />
        <path
          d="M361.534 595.263H360.714V596.088H361.534V595.263Z"
          fill="#D0E1E9"
        />
        <path
          d="M362.354 595.263H361.534V596.088H362.354V595.263Z"
          fill="#D0E1E9"
        />
        <path
          d="M363.18 595.263H362.357V596.088H363.18V595.263Z"
          fill="#D0E1E9"
        />
        <path
          d="M363.997 595.263H363.177V596.088H363.997V595.263Z"
          fill="#809AA7"
        />
        <path d="M341.82 596.088H341V596.912H341.82V596.088Z" fill="#FFAD32" />
        <path
          d="M342.643 596.088H341.82V596.912H342.643V596.088Z"
          fill="#F7852F"
        />
        <path
          d="M343.463 596.088H342.643V596.912H343.463V596.088Z"
          fill="#F7852F"
        />
        <path
          d="M344.283 596.088H343.463V596.912H344.283V596.088Z"
          fill="#F7852F"
        />
        <path
          d="M345.106 596.088H344.286V596.912H345.106V596.088Z"
          fill="#F7852F"
        />
        <path
          d="M345.927 596.088H345.106V596.912H345.927V596.088Z"
          fill="#F7852F"
        />
        <path
          d="M346.749 596.088H345.929V596.912H346.749V596.088Z"
          fill="#D8531A"
        />
        <path
          d="M347.572 596.088H346.749V596.912H347.572V596.088Z"
          fill="#809AA7"
        />
        <path
          d="M348.392 596.088H347.572V596.912H348.392V596.088Z"
          fill="#D0E1E9"
        />
        <path
          d="M349.213 596.088H348.392V596.912H349.213V596.088Z"
          fill="#D0E1E9"
        />
        <path
          d="M350.035 596.088H349.215V596.912H350.035V596.088Z"
          fill="#394B53"
        />
        <path
          d="M350.856 596.088H350.035V596.912H350.856V596.088Z"
          fill="#05B8CF"
        />
        <path
          d="M351.676 596.088H350.856V596.912H351.676V596.088Z"
          fill="#058499"
        />
        <path
          d="M352.499 596.088H351.678V596.912H352.499V596.088Z"
          fill="#058499"
        />
        <path
          d="M353.319 596.088H352.499V596.912H353.319V596.088Z"
          fill="#05B8CF"
        />
        <path
          d="M354.142 596.088H353.322V596.912H354.142V596.088Z"
          fill="#058499"
        />
        <path
          d="M354.962 596.088H354.142V596.912H354.962V596.088Z"
          fill="#058499"
        />
        <path
          d="M355.785 596.088H354.965V596.912H355.785V596.088Z"
          fill="#394B53"
        />
        <path
          d="M356.608 596.088H355.785V596.912H356.608V596.088Z"
          fill="#D0E1E9"
        />
        <path
          d="M357.428 596.088H356.608V596.912H357.428V596.088Z"
          fill="#D0E1E9"
        />
        <path
          d="M358.248 596.088H357.428V596.912H358.248V596.088Z"
          fill="#F0FFFF"
        />
        <path
          d="M359.071 596.088H358.251V596.912H359.071V596.088Z"
          fill="#FFAD32"
        />
        <path
          d="M359.891 596.088H359.071V596.912H359.891V596.088Z"
          fill="#F7852F"
        />
        <path
          d="M360.714 596.088H359.894V596.912H360.714V596.088Z"
          fill="#F7852F"
        />
        <path
          d="M361.534 596.088H360.714V596.912H361.534V596.088Z"
          fill="#F7852F"
        />
        <path
          d="M362.354 596.088H361.534V596.912H362.354V596.088Z"
          fill="#F7852F"
        />
        <path
          d="M363.18 596.088H362.357V596.912H363.18V596.088Z"
          fill="#F7852F"
        />
        <path
          d="M363.997 596.088H363.177V596.912H363.997V596.088Z"
          fill="#D8531A"
        />
        <path d="M341.82 596.912H341V597.737H341.82V596.912Z" fill="#F0FFFF" />
        <path
          d="M342.643 596.912H341.82V597.737H342.643V596.912Z"
          fill="#D0E1E9"
        />
        <path
          d="M343.463 596.912H342.643V597.737H343.463V596.912Z"
          fill="#D0E1E9"
        />
        <path
          d="M344.283 596.912H343.463V597.737H344.283V596.912Z"
          fill="#D0E1E9"
        />
        <path
          d="M345.106 596.912H344.286V597.737H345.106V596.912Z"
          fill="#D0E1E9"
        />
        <path
          d="M345.927 596.912H345.106V597.737H345.927V596.912Z"
          fill="#D0E1E9"
        />
        <path
          d="M346.749 596.912H345.929V597.737H346.749V596.912Z"
          fill="#D0E1E9"
        />
        <path
          d="M347.572 596.912H346.749V597.737H347.572V596.912Z"
          fill="#F0FFFF"
        />
        <path
          d="M348.392 596.912H347.572V597.737H348.392V596.912Z"
          fill="#F0FFFF"
        />
        <path
          d="M349.213 596.912H348.392V597.737H349.213V596.912Z"
          fill="#D0E1E9"
        />
        <path
          d="M350.035 596.912H349.215V597.737H350.035V596.912Z"
          fill="#D0E1E9"
        />
        <path
          d="M350.856 596.912H350.035V597.737H350.856V596.912Z"
          fill="#394B53"
        />
        <path
          d="M351.676 596.912H350.856V597.737H351.676V596.912Z"
          fill="#394B53"
        />
        <path
          d="M352.499 596.912H351.678V597.737H352.499V596.912Z"
          fill="#394B53"
        />
        <path
          d="M353.319 596.912H352.499V597.737H353.319V596.912Z"
          fill="#394B53"
        />
        <path
          d="M354.142 596.912H353.322V597.737H354.142V596.912Z"
          fill="#394B53"
        />
        <path
          d="M354.962 596.912H354.142V597.737H354.962V596.912Z"
          fill="#394B53"
        />
        <path
          d="M355.785 596.912H354.965V597.737H355.785V596.912Z"
          fill="#D0E1E9"
        />
        <path
          d="M356.608 596.912H355.785V597.737H356.608V596.912Z"
          fill="#D0E1E9"
        />
        <path
          d="M357.428 596.912H356.608V597.737H357.428V596.912Z"
          fill="#809AA7"
        />
        <path
          d="M358.248 596.912H357.428V597.737H358.248V596.912Z"
          fill="#809AA7"
        />
        <path
          d="M359.071 596.912H358.251V597.737H359.071V596.912Z"
          fill="#D0E1E9"
        />
        <path
          d="M359.891 596.912H359.071V597.737H359.891V596.912Z"
          fill="#D0E1E9"
        />
        <path
          d="M360.714 596.912H359.894V597.737H360.714V596.912Z"
          fill="#D0E1E9"
        />
        <path
          d="M361.534 596.912H360.714V597.737H361.534V596.912Z"
          fill="#D0E1E9"
        />
        <path
          d="M362.354 596.912H361.534V597.737H362.354V596.912Z"
          fill="#D0E1E9"
        />
        <path
          d="M363.18 596.912H362.357V597.737H363.18V596.912Z"
          fill="#D0E1E9"
        />
        <path
          d="M363.997 596.912H363.177V597.737H363.997V596.912Z"
          fill="#809AA7"
        />
        <path d="M341.82 597.74H341V598.568H341.82V597.74Z" fill="#F0FFFF" />
        <path
          d="M342.643 597.74H341.82V598.568H342.643V597.74Z"
          fill="#D0E1E9"
        />
        <path
          d="M343.463 597.74H342.643V598.568H343.463V597.74Z"
          fill="#D0E1E9"
        />
        <path
          d="M344.283 597.74H343.463V598.568H344.283V597.74Z"
          fill="#D0E1E9"
        />
        <path
          d="M345.106 597.74H344.286V598.568H345.106V597.74Z"
          fill="#D0E1E9"
        />
        <path
          d="M345.927 597.74H345.106V598.568H345.927V597.74Z"
          fill="#D0E1E9"
        />
        <path
          d="M346.749 597.74H345.929V598.568H346.749V597.74Z"
          fill="#F0FFFF"
        />
        <path
          d="M347.572 597.74H346.749V598.568H347.572V597.74Z"
          fill="#D0E1E9"
        />
        <path
          d="M348.392 597.74H347.572V598.568H348.392V597.74Z"
          fill="#D0E1E9"
        />
        <path
          d="M349.213 597.74H348.392V598.568H349.213V597.74Z"
          fill="#F0FFFF"
        />
        <path
          d="M350.035 597.74H349.215V598.568H350.035V597.74Z"
          fill="#D0E1E9"
        />
        <path
          d="M350.856 597.74H350.035V598.568H350.856V597.74Z"
          fill="#D0E1E9"
        />
        <path
          d="M351.676 597.74H350.856V598.568H351.676V597.74Z"
          fill="#D0E1E9"
        />
        <path
          d="M352.499 597.74H351.678V598.568H352.499V597.74Z"
          fill="#D0E1E9"
        />
        <path
          d="M353.319 597.74H352.499V598.568H353.319V597.74Z"
          fill="#D0E1E9"
        />
        <path
          d="M354.142 597.74H353.322V598.568H354.142V597.74Z"
          fill="#D0E1E9"
        />
        <path
          d="M354.962 597.74H354.142V598.568H354.962V597.74Z"
          fill="#D0E1E9"
        />
        <path
          d="M355.785 597.74H354.965V598.568H355.785V597.74Z"
          fill="#D0E1E9"
        />
        <path
          d="M356.608 597.74H355.785V598.568H356.608V597.74Z"
          fill="#809AA7"
        />
        <path
          d="M357.428 597.74H356.608V598.568H357.428V597.74Z"
          fill="#D0E1E9"
        />
        <path
          d="M358.248 597.74H357.428V598.568H358.248V597.74Z"
          fill="#D0E1E9"
        />
        <path
          d="M359.071 597.74H358.251V598.568H359.071V597.74Z"
          fill="#809AA7"
        />
        <path
          d="M359.891 597.74H359.071V598.568H359.891V597.74Z"
          fill="#D0E1E9"
        />
        <path
          d="M360.714 597.74H359.894V598.568H360.714V597.74Z"
          fill="#D0E1E9"
        />
        <path
          d="M361.534 597.74H360.714V598.568H361.534V597.74Z"
          fill="#D0E1E9"
        />
        <path
          d="M362.354 597.74H361.534V598.568H362.354V597.74Z"
          fill="#D0E1E9"
        />
        <path
          d="M363.18 597.74H362.357V598.568H363.18V597.74Z"
          fill="#D0E1E9"
        />
        <path
          d="M363.997 597.74H363.177V598.568H363.997V597.74Z"
          fill="#809AA7"
        />
        <path d="M341.82 598.565H341V599.39H341.82V598.565Z" fill="#F0FFFF" />
        <path
          d="M342.643 598.565H341.82V599.39H342.643V598.565Z"
          fill="#D0E1E9"
        />
        <path
          d="M343.463 598.565H342.643V599.39H343.463V598.565Z"
          fill="#D0E1E9"
        />
        <path
          d="M344.283 598.565H343.463V599.39H344.283V598.565Z"
          fill="#D0E1E9"
        />
        <path
          d="M345.106 598.565H344.286V599.39H345.106V598.565Z"
          fill="#D0E1E9"
        />
        <path
          d="M345.927 598.565H345.106V599.39H345.927V598.565Z"
          fill="#F0FFFF"
        />
        <path
          d="M346.749 598.565H345.929V599.39H346.749V598.565Z"
          fill="#D0E1E9"
        />
        <path
          d="M347.572 598.565H346.749V599.39H347.572V598.565Z"
          fill="#D0E1E9"
        />
        <path
          d="M348.392 598.565H347.572V599.39H348.392V598.565Z"
          fill="#D0E1E9"
        />
        <path
          d="M349.213 598.565H348.392V599.39H349.213V598.565Z"
          fill="#D0E1E9"
        />
        <path
          d="M350.035 598.565H349.215V599.39H350.035V598.565Z"
          fill="#F0FFFF"
        />
        <path
          d="M350.856 598.565H350.035V599.39H350.856V598.565Z"
          fill="#F0FFFF"
        />
        <path
          d="M351.676 598.565H350.856V599.39H351.676V598.565Z"
          fill="#9AB0BB"
        />
        <path
          d="M352.499 598.565H351.678V599.39H352.499V598.565Z"
          fill="#9AB0BB"
        />
        <path
          d="M353.319 598.565H352.499V599.39H353.319V598.565Z"
          fill="#9AB0BB"
        />
        <path
          d="M354.142 598.565H353.322V599.39H354.142V598.565Z"
          fill="#9AB0BB"
        />
        <path
          d="M354.962 598.565H354.142V599.39H354.962V598.565Z"
          fill="#809AA7"
        />
        <path
          d="M355.785 598.565H354.965V599.39H355.785V598.565Z"
          fill="#809AA7"
        />
        <path
          d="M356.608 598.565H355.785V599.39H356.608V598.565Z"
          fill="#D0E1E9"
        />
        <path
          d="M357.428 598.565H356.608V599.39H357.428V598.565Z"
          fill="#D0E1E9"
        />
        <path
          d="M358.248 598.565H357.428V599.39H358.248V598.565Z"
          fill="#D0E1E9"
        />
        <path
          d="M359.071 598.565H358.251V599.39H359.071V598.565Z"
          fill="#D0E1E9"
        />
        <path
          d="M359.891 598.565H359.071V599.39H359.891V598.565Z"
          fill="#809AA7"
        />
        <path
          d="M360.714 598.565H359.894V599.39H360.714V598.565Z"
          fill="#D0E1E9"
        />
        <path
          d="M361.534 598.565H360.714V599.39H361.534V598.565Z"
          fill="#D0E1E9"
        />
        <path
          d="M362.354 598.565H361.534V599.39H362.354V598.565Z"
          fill="#D0E1E9"
        />
        <path
          d="M363.18 598.565H362.357V599.39H363.18V598.565Z"
          fill="#D0E1E9"
        />
        <path
          d="M363.997 598.565H363.177V599.39H363.997V598.565Z"
          fill="#809AA7"
        />
        <path
          d="M342.643 599.393H341.82V600.218H342.643V599.393Z"
          fill="#809AA7"
        />
        <path
          d="M343.463 599.393H342.643V600.218H343.463V599.393Z"
          fill="#9AB0BB"
        />
        <path
          d="M344.283 599.393H343.463V600.218H344.283V599.393Z"
          fill="#9AB0BB"
        />
        <path
          d="M345.106 599.393H344.286V600.218H345.106V599.393Z"
          fill="#9AB0BB"
        />
        <path
          d="M345.927 599.393H345.106V600.218H345.927V599.393Z"
          fill="#9AB0BB"
        />
        <path
          d="M346.749 599.393H345.929V600.218H346.749V599.393Z"
          fill="#D0E1E9"
        />
        <path
          d="M347.572 599.393H346.749V600.218H347.572V599.393Z"
          fill="#D0E1E9"
        />
        <path
          d="M348.392 599.393H347.572V600.218H348.392V599.393Z"
          fill="#D0E1E9"
        />
        <path
          d="M349.213 599.393H348.392V600.218H349.213V599.393Z"
          fill="#F0FFFF"
        />
        <path
          d="M350.035 599.393H349.215V600.218H350.035V599.393Z"
          fill="#D0E1E9"
        />
        <path
          d="M350.856 599.393H350.035V600.218H350.856V599.393Z"
          fill="#D0E1E9"
        />
        <path
          d="M351.676 599.393H350.856V600.218H351.676V599.393Z"
          fill="#D0E1E9"
        />
        <path
          d="M352.499 599.393H351.678V600.218H352.499V599.393Z"
          fill="#D0E1E9"
        />
        <path
          d="M353.319 599.393H352.499V600.218H353.319V599.393Z"
          fill="#D0E1E9"
        />
        <path
          d="M354.142 599.393H353.322V600.218H354.142V599.393Z"
          fill="#D0E1E9"
        />
        <path
          d="M354.962 599.393H354.142V600.218H354.962V599.393Z"
          fill="#D0E1E9"
        />
        <path
          d="M355.785 599.393H354.965V600.218H355.785V599.393Z"
          fill="#D0E1E9"
        />
        <path
          d="M356.608 599.393H355.785V600.218H356.608V599.393Z"
          fill="#809AA7"
        />
        <path
          d="M357.428 599.393H356.608V600.218H357.428V599.393Z"
          fill="#D0E1E9"
        />
        <path
          d="M358.248 599.393H357.428V600.218H358.248V599.393Z"
          fill="#D0E1E9"
        />
        <path
          d="M359.071 599.393H358.251V600.218H359.071V599.393Z"
          fill="#9AB0BB"
        />
        <path
          d="M359.891 599.393H359.071V600.218H359.891V599.393Z"
          fill="#9AB0BB"
        />
        <path
          d="M360.714 599.393H359.894V600.218H360.714V599.393Z"
          fill="#9AB0BB"
        />
        <path
          d="M361.534 599.393H360.714V600.218H361.534V599.393Z"
          fill="#9AB0BB"
        />
        <path
          d="M362.354 599.393H361.534V600.218H362.354V599.393Z"
          fill="#9AB0BB"
        />
        <path
          d="M363.18 599.393H362.357V600.218H363.18V599.393Z"
          fill="#809AA7"
        />
        <path
          d="M342.643 600.218H341.82V601.042H342.643V600.218Z"
          fill="#373B3E"
        />
        <path
          d="M343.463 600.218H342.643V601.042H343.463V600.218Z"
          fill="#43484B"
        />
        <path
          d="M344.283 600.218H343.463V601.042H344.283V600.218Z"
          fill="#373B3E"
        />
        <path
          d="M345.106 600.218H344.286V601.042H345.106V600.218Z"
          fill="#373B3E"
        />
        <path
          d="M345.927 600.218H345.106V601.042H345.927V600.218Z"
          fill="#26282A"
        />
        <path
          d="M346.749 600.218H345.929V601.042H346.749V600.218Z"
          fill="#809AA7"
        />
        <path
          d="M347.572 600.218H346.749V601.042H347.572V600.218Z"
          fill="#809AA7"
        />
        <path
          d="M348.392 600.218H347.572V601.042H348.392V600.218Z"
          fill="#9AB0BB"
        />
        <path
          d="M349.213 600.218H348.392V601.042H349.213V600.218Z"
          fill="#F0FFFF"
        />
        <path
          d="M350.035 600.218H349.215V601.042H350.035V600.218Z"
          fill="#FFAD32"
        />
        <path
          d="M350.856 600.218H350.035V601.042H350.856V600.218Z"
          fill="#F7852F"
        />
        <path
          d="M351.676 600.218H350.856V601.042H351.676V600.218Z"
          fill="#F7852F"
        />
        <path
          d="M352.499 600.218H351.678V601.042H352.499V600.218Z"
          fill="#F7852F"
        />
        <path
          d="M353.319 600.218H352.499V601.042H353.319V600.218Z"
          fill="#F7852F"
        />
        <path
          d="M354.142 600.218H353.322V601.042H354.142V600.218Z"
          fill="#F7852F"
        />
        <path
          d="M354.962 600.218H354.142V601.042H354.962V600.218Z"
          fill="#F7852F"
        />
        <path
          d="M355.785 600.218H354.965V601.042H355.785V600.218Z"
          fill="#D8531A"
        />
        <path
          d="M356.608 600.218H355.785V601.042H356.608V600.218Z"
          fill="#809AA7"
        />
        <path
          d="M357.428 600.218H356.608V601.042H357.428V600.218Z"
          fill="#9AB0BB"
        />
        <path
          d="M358.248 600.218H357.428V601.042H358.248V600.218Z"
          fill="#809AA7"
        />
        <path
          d="M359.071 600.218H358.251V601.042H359.071V600.218Z"
          fill="#809AA7"
        />
        <path
          d="M359.891 600.218H359.071V601.042H359.891V600.218Z"
          fill="#373B3E"
        />
        <path
          d="M360.714 600.218H359.894V601.042H360.714V600.218Z"
          fill="#43484B"
        />
        <path
          d="M361.534 600.218H360.714V601.042H361.534V600.218Z"
          fill="#373B3E"
        />
        <path
          d="M362.354 600.218H361.534V601.042H362.354V600.218Z"
          fill="#373B3E"
        />
        <path
          d="M363.18 600.218H362.357V601.042H363.18V600.218Z"
          fill="#26282A"
        />
        <path
          d="M342.643 601.045H341.82V601.87H342.643V601.045Z"
          fill="#FFCA34"
        />
        <path
          d="M343.463 601.045H342.643V601.87H343.463V601.045Z"
          fill="#FFAD32"
        />
        <path
          d="M344.283 601.045H343.463V601.87H344.283V601.045Z"
          fill="#FFCA34"
        />
        <path
          d="M345.106 601.045H344.286V601.87H345.106V601.045Z"
          fill="#FFAD32"
        />
        <path
          d="M345.927 601.045H345.106V601.87H345.927V601.045Z"
          fill="#FFCA34"
        />
        <path
          d="M348.392 601.045H347.572V601.87H348.392V601.045Z"
          fill="#809AA7"
        />
        <path
          d="M349.213 601.045H348.392V601.87H349.213V601.045Z"
          fill="#809AA7"
        />
        <path
          d="M350.035 601.045H349.215V601.87H350.035V601.045Z"
          fill="#9AB0BB"
        />
        <path
          d="M350.856 601.045H350.035V601.87H350.856V601.045Z"
          fill="#D0E1E9"
        />
        <path
          d="M351.676 601.045H350.856V601.87H351.676V601.045Z"
          fill="#D0E1E9"
        />
        <path
          d="M352.499 601.045H351.678V601.87H352.499V601.045Z"
          fill="#D0E1E9"
        />
        <path
          d="M353.319 601.045H352.499V601.87H353.319V601.045Z"
          fill="#D0E1E9"
        />
        <path
          d="M354.142 601.045H353.322V601.87H354.142V601.045Z"
          fill="#D0E1E9"
        />
        <path
          d="M354.962 601.045H354.142V601.87H354.962V601.045Z"
          fill="#D0E1E9"
        />
        <path
          d="M355.785 601.045H354.965V601.87H355.785V601.045Z"
          fill="#9AB0BB"
        />
        <path
          d="M356.608 601.045H355.785V601.87H356.608V601.045Z"
          fill="#809AA7"
        />
        <path
          d="M357.428 601.045H356.608V601.87H357.428V601.045Z"
          fill="#809AA7"
        />
        <path
          d="M359.891 601.045H359.071V601.87H359.891V601.045Z"
          fill="#FFCA34"
        />
        <path
          d="M360.714 601.045H359.894V601.87H360.714V601.045Z"
          fill="#FFAD32"
        />
        <path
          d="M361.534 601.045H360.714V601.87H361.534V601.045Z"
          fill="#FFCA34"
        />
        <path
          d="M362.354 601.045H361.534V601.87H362.354V601.045Z"
          fill="#FFAD32"
        />
        <path
          d="M363.18 601.045H362.357V601.87H363.18V601.045Z"
          fill="#FFCA34"
        />
        <path
          d="M342.643 601.87H341.82V602.695H342.643V601.87Z"
          fill="#FFAD32"
        />
        <path
          d="M343.463 601.87H342.643V602.695H343.463V601.87Z"
          fill="#F7852F"
        />
        <path
          d="M344.283 601.87H343.463V602.695H344.283V601.87Z"
          fill="#FFAD32"
        />
        <path
          d="M345.106 601.87H344.286V602.695H345.106V601.87Z"
          fill="#F7852F"
        />
        <path
          d="M345.927 601.87H345.106V602.695H345.927V601.87Z"
          fill="#FFAD32"
        />
        <path
          d="M350.035 601.87H349.215V602.695H350.035V601.87Z"
          fill="#FFAD32"
        />
        <path
          d="M350.856 601.87H350.035V602.695H350.856V601.87Z"
          fill="#F7852F"
        />
        <path
          d="M351.676 601.87H350.856V602.695H351.676V601.87Z"
          fill="#F7852F"
        />
        <path
          d="M352.499 601.87H351.678V602.695H352.499V601.87Z"
          fill="#F7852F"
        />
        <path
          d="M353.319 601.87H352.499V602.695H353.319V601.87Z"
          fill="#F7852F"
        />
        <path
          d="M354.142 601.87H353.322V602.695H354.142V601.87Z"
          fill="#F7852F"
        />
        <path
          d="M354.962 601.87H354.142V602.695H354.962V601.87Z"
          fill="#F7852F"
        />
        <path
          d="M355.785 601.87H354.965V602.695H355.785V601.87Z"
          fill="#D8531A"
        />
        <path
          d="M359.891 601.87H359.071V602.695H359.891V601.87Z"
          fill="#FFAD32"
        />
        <path
          d="M360.714 601.87H359.894V602.695H360.714V601.87Z"
          fill="#F7852F"
        />
        <path
          d="M361.534 601.87H360.714V602.695H361.534V601.87Z"
          fill="#FFAD32"
        />
        <path
          d="M362.354 601.87H361.534V602.695H362.354V601.87Z"
          fill="#F7852F"
        />
        <path
          d="M363.18 601.87H362.357V602.695H363.18V601.87Z"
          fill="#FFAD32"
        />
        <path
          d="M342.643 602.698H341.82V603.523H342.643V602.698Z"
          fill="#F7852F"
        />
        <path
          d="M343.463 602.698H342.643V603.523H343.463V602.698Z"
          fill="#FFAD32"
        />
        <path
          d="M344.283 602.698H343.463V603.523H344.283V602.698Z"
          fill="#D8531A"
        />
        <path
          d="M345.106 602.698H344.286V603.523H345.106V602.698Z"
          fill="#FFAD32"
        />
        <path
          d="M345.927 602.698H345.106V603.523H345.927V602.698Z"
          fill="#F7852F"
        />
        <path
          d="M351.676 602.698H350.856V603.523H351.676V602.698Z"
          fill="#809AA7"
        />
        <path
          d="M352.499 602.698H351.678V603.523H352.499V602.698Z"
          fill="#D0E1E9"
        />
        <path
          d="M353.319 602.698H352.499V603.523H353.319V602.698Z"
          fill="#9AB0BB"
        />
        <path
          d="M354.142 602.698H353.322V603.523H354.142V602.698Z"
          fill="#809AA7"
        />
        <path
          d="M359.891 602.698H359.071V603.523H359.891V602.698Z"
          fill="#F7852F"
        />
        <path
          d="M360.714 602.698H359.894V603.523H360.714V602.698Z"
          fill="#FFAD32"
        />
        <path
          d="M361.534 602.698H360.714V603.523H361.534V602.698Z"
          fill="#D8531A"
        />
        <path
          d="M362.354 602.698H361.534V603.523H362.354V602.698Z"
          fill="#FFAD32"
        />
        <path
          d="M363.18 602.698H362.357V603.523H363.18V602.698Z"
          fill="#F7852F"
        />
        <path
          d="M343.463 603.523H342.643V604.347H343.463V603.523Z"
          fill="#D8531A"
        />
        <path
          d="M344.283 603.523H343.463V604.347H344.283V603.523Z"
          fill="#F7852F"
        />
        <path
          d="M345.106 603.523H344.286V604.347H345.106V603.523Z"
          fill="#D8531A"
        />
        <path
          d="M360.714 603.523H359.894V604.347H360.714V603.523Z"
          fill="#D8531A"
        />
        <path
          d="M361.534 603.523H360.714V604.347H361.534V603.523Z"
          fill="#F7852F"
        />
        <path
          d="M362.354 603.523H361.534V604.347H362.354V603.523Z"
          fill="#D8531A"
        />
        <path
          d="M343.463 604.35H342.643V605.175H343.463V604.35Z"
          fill="#D8531A"
        />
        <path
          d="M344.283 604.35H343.463V605.175H344.283V604.35Z"
          fill="#D8531A"
        />
        <path
          d="M345.106 604.35H344.286V605.175H345.106V604.35Z"
          fill="#D8531A"
        />
        <path
          d="M360.714 604.35H359.894V605.175H360.714V604.35Z"
          fill="#D8531A"
        />
        <path
          d="M361.534 604.35H360.714V605.175H361.534V604.35Z"
          fill="#D8531A"
        />
        <path
          d="M362.354 604.35H361.534V605.175H362.354V604.35Z"
          fill="#D8531A"
        />
        <path
          d="M344.283 605.175H343.463V606H344.283V605.175Z"
          fill="#D8531A"
        />
        <path
          d="M361.534 605.175H360.714V606H361.534V605.175Z"
          fill="#D8531A"
        />
      </g>
      <g opacity="0.2" clipPath="url(#clip7_0_1)">
        <path
          d="M41.8087 437.838L41.3441 437.374L40.8734 437.844L41.3379 438.309L41.8087 437.838Z"
          fill="#F0FFFF"
        />
        <path
          d="M42.2747 438.304L41.8102 437.84L41.3394 438.31L41.804 438.775L42.2747 438.304Z"
          fill="#9AB0BB"
        />
        <path
          d="M40.8734 437.844L40.4088 437.38L39.9381 437.85L40.4026 438.315L40.8734 437.844Z"
          fill="#F0FFFF"
        />
        <path
          d="M41.338 438.309L40.8734 437.844L40.4027 438.315L40.8672 438.78L41.338 438.309Z"
          fill="#D0E1E9"
        />
        <path
          d="M41.804 438.775L41.3395 438.31L40.8687 438.781L41.3333 439.246L41.804 438.775Z"
          fill="#D0E1E9"
        />
        <path
          d="M42.2685 439.239L41.804 438.775L41.3332 439.246L41.7978 439.71L42.2685 439.239Z"
          fill="#809AA7"
        />
        <path
          d="M40.4012 438.317L39.9366 437.852L39.4659 438.323L39.9304 438.787L40.4012 438.317Z"
          fill="#F0FFFF"
        />
        <path
          d="M40.8657 438.781L40.4011 438.317L39.9304 438.787L40.3949 439.252L40.8657 438.781Z"
          fill="#D0E1E9"
        />
        <path
          d="M41.3317 439.247L40.8672 438.783L40.3965 439.253L40.861 439.718L41.3317 439.247Z"
          fill="#D0E1E9"
        />
        <path
          d="M41.7962 439.712L41.3317 439.247L40.8609 439.718L41.3255 440.182L41.7962 439.712Z"
          fill="#809AA7"
        />
        <path
          d="M39.4644 438.321L38.9999 437.857L38.5291 438.327L38.9937 438.792L39.4644 438.321Z"
          fill="#F0FFFF"
        />
        <path
          d="M39.9305 438.787L39.4659 438.323L38.9952 438.793L39.4597 439.258L39.9305 438.787Z"
          fill="#D0E1E9"
        />
        <path
          d="M40.395 439.252L39.9304 438.787L39.4597 439.258L39.9242 439.723L40.395 439.252Z"
          fill="#D0E1E9"
        />
        <path
          d="M40.861 439.718L40.3965 439.253L39.9257 439.724L40.3903 440.189L40.861 439.718Z"
          fill="#D0E1E9"
        />
        <path
          d="M41.3255 440.182L40.861 439.718L40.3902 440.189L40.8548 440.653L41.3255 440.182Z"
          fill="#9AB0BB"
        />
        <path
          d="M41.7916 440.648L41.327 440.184L40.8563 440.655L41.3208 441.119L41.7916 440.648Z"
          fill="#809AA7"
        />
        <path
          d="M38.9921 438.793L38.5276 438.329L38.0553 438.801L38.5199 439.266L38.9921 438.793Z"
          fill="#F0FFFF"
        />
        <path
          d="M39.4582 439.26L38.9937 438.795L38.5214 439.267L38.9859 439.732L39.4582 439.26Z"
          fill="#D0E1E9"
        />
        <path
          d="M39.9227 439.724L39.4581 439.26L38.9859 439.732L39.4504 440.196L39.9227 439.724Z"
          fill="#D0E1E9"
        />
        <path
          d="M40.3887 440.19L39.9242 439.726L39.452 440.198L39.9165 440.662L40.3887 440.19Z"
          fill="#D0E1E9"
        />
        <path
          d="M40.8533 440.655L40.3888 440.19L39.9166 440.662L40.3811 441.127L40.8533 440.655Z"
          fill="#D0E1E9"
        />
        <path
          d="M41.3193 441.121L40.8547 440.656L40.3825 441.128L40.847 441.593L41.3193 441.121Z"
          fill="#809AA7"
        />
        <path
          d="M38.0569 438.8L37.5924 438.335L37.1217 438.806L37.5862 439.27L38.0569 438.8Z"
          fill="#F0FFFF"
        />
        <path
          d="M38.5214 439.264L38.0569 438.8L37.5861 439.27L38.0507 439.735L38.5214 439.264Z"
          fill="#D0E1E9"
        />
        <path
          d="M38.9875 439.73L38.5229 439.266L38.0522 439.736L38.5168 440.201L38.9875 439.73Z"
          fill="#D0E1E9"
        />
        <path
          d="M39.452 440.195L38.9874 439.73L38.5167 440.201L38.9812 440.666L39.452 440.195Z"
          fill="#394B53"
        />
        <path
          d="M39.918 440.661L39.4535 440.196L38.9828 440.667L39.4473 441.132L39.918 440.661Z"
          fill="#394B53"
        />
        <path
          d="M40.3825 441.125L39.918 440.661L39.4472 441.132L39.9118 441.596L40.3825 441.125Z"
          fill="#D0E1E9"
        />
        <path
          d="M40.8486 441.591L40.384 441.127L39.9133 441.598L40.3778 442.062L40.8486 441.591Z"
          fill="#9AB0BB"
        />
        <path
          d="M41.313 442.056L40.8485 441.591L40.3778 442.062L40.8423 442.527L41.313 442.056Z"
          fill="#809AA7"
        />
        <path
          d="M37.5847 439.272L37.1201 438.807L36.6494 439.278L37.1139 439.743L37.5847 439.272Z"
          fill="#F0FFFF"
        />
        <path
          d="M38.0491 439.736L37.5846 439.272L37.1139 439.743L37.5784 440.207L38.0491 439.736Z"
          fill="#D0E1E9"
        />
        <path
          d="M38.5152 440.202L38.0507 439.738L37.5799 440.209L38.0445 440.673L38.5152 440.202Z"
          fill="#394B53"
        />
        <path
          d="M38.9798 440.667L38.5153 440.202L38.0445 440.673L38.5091 441.138L38.9798 440.667Z"
          fill="#51DFF2"
        />
        <path
          d="M39.4457 441.133L38.9812 440.669L38.5105 441.139L38.975 441.604L39.4457 441.133Z"
          fill="#05B8CF"
        />
        <path
          d="M39.9103 441.598L39.4458 441.133L38.9751 441.604L39.4396 442.068L39.9103 441.598Z"
          fill="#394B53"
        />
        <path
          d="M40.3763 442.064L39.9117 441.599L39.441 442.07L39.9055 442.534L40.3763 442.064Z"
          fill="#D0E1E9"
        />
        <path
          d="M40.8409 442.528L40.3763 442.064L39.9056 442.534L40.3701 442.999L40.8409 442.528Z"
          fill="#809AA7"
        />
        <path
          d="M37.114 439.743L36.6494 439.278L36.1787 439.749L36.6432 440.213L37.114 439.743Z"
          fill="#F0FFFF"
        />
        <path
          d="M37.5784 440.207L37.1139 439.743L36.6432 440.213L37.1077 440.678L37.5784 440.207Z"
          fill="#D0E1E9"
        />
        <path
          d="M38.0445 440.673L37.58 440.209L37.1092 440.679L37.5738 441.144L38.0445 440.673Z"
          fill="#394B53"
        />
        <path
          d="M38.509 441.138L38.0444 440.673L37.5737 441.144L38.0382 441.608L38.509 441.138Z"
          fill="#51DFF2"
        />
        <path
          d="M38.975 441.604L38.5105 441.139L38.0398 441.61L38.5043 442.075L38.975 441.604Z"
          fill="#05B8CF"
        />
        <path
          d="M39.4395 442.068L38.975 441.604L38.5042 442.075L38.9688 442.539L39.4395 442.068Z"
          fill="#394B53"
        />
        <path
          d="M39.9056 442.534L39.441 442.07L38.9703 442.541L39.4348 443.005L39.9056 442.534Z"
          fill="#D0E1E9"
        />
        <path
          d="M40.3702 442.999L39.9056 442.534L39.4349 443.005L39.8994 443.47L40.3702 442.999Z"
          fill="#809AA7"
        />
        <path
          d="M31.9888 435.562L31.5243 435.098L31.0536 435.568L31.5181 436.033L31.9888 435.562Z"
          fill="#373B3E"
        />
        <path
          d="M32.4549 436.028L31.9904 435.564L31.5196 436.034L31.9842 436.499L32.4549 436.028Z"
          fill="#26282A"
        />
        <path
          d="M36.6417 440.215L36.1771 439.75L35.7064 440.221L36.1709 440.686L36.6417 440.215Z"
          fill="#F0FFFF"
        />
        <path
          d="M37.1063 440.679L36.6417 440.215L36.171 440.686L36.6355 441.15L37.1063 440.679Z"
          fill="#D0E1E9"
        />
        <path
          d="M37.5722 441.145L37.1077 440.681L36.6369 441.152L37.1015 441.616L37.5722 441.145Z"
          fill="#394B53"
        />
        <path
          d="M38.0368 441.61L37.5723 441.145L37.1015 441.616L37.5661 442.081L38.0368 441.61Z"
          fill="#05B8CF"
        />
        <path
          d="M38.5027 442.076L38.0382 441.611L37.5675 442.082L38.032 442.547L38.5027 442.076Z"
          fill="#058499"
        />
        <path
          d="M38.9673 442.541L38.5028 442.076L38.0321 442.547L38.4966 443.011L38.9673 442.541Z"
          fill="#394B53"
        />
        <path
          d="M39.4334 443.007L38.9689 442.542L38.4981 443.013L38.9627 443.477L39.4334 443.007Z"
          fill="#D0E1E9"
        />
        <path
          d="M39.8979 443.471L39.4333 443.007L38.9626 443.477L39.4272 443.942L39.8979 443.471Z"
          fill="#809AA7"
        />
        <path
          d="M44.0847 447.658L43.6201 447.193L43.1494 447.664L43.6139 448.129L44.0847 447.658Z"
          fill="#373B3E"
        />
        <path
          d="M44.5507 448.124L44.0862 447.659L43.6154 448.13L44.08 448.595L44.5507 448.124Z"
          fill="#26282A"
        />
        <path
          d="M31.5181 436.033L31.0536 435.568L30.5829 436.039L31.0474 436.504L31.5181 436.033Z"
          fill="#43484B"
        />
        <path
          d="M31.9842 436.499L31.5197 436.034L31.0489 436.505L31.5135 436.97L31.9842 436.499Z"
          fill="#373B3E"
        />
        <path
          d="M36.171 440.686L35.7064 440.221L35.2357 440.692L35.7002 441.156L36.171 440.686Z"
          fill="#F0FFFF"
        />
        <path
          d="M36.6354 441.15L36.1709 440.686L35.7002 441.156L36.1647 441.621L36.6354 441.15Z"
          fill="#D0E1E9"
        />
        <path
          d="M37.1015 441.616L36.637 441.152L36.1662 441.622L36.6308 442.087L37.1015 441.616Z"
          fill="#394B53"
        />
        <path
          d="M37.566 442.081L37.1014 441.616L36.6307 442.087L37.0952 442.551L37.566 442.081Z"
          fill="#51DFF2"
        />
        <path
          d="M38.032 442.547L37.5675 442.082L37.0968 442.553L37.5613 443.017L38.032 442.547Z"
          fill="#05B8CF"
        />
        <path
          d="M38.4966 443.011L38.0321 442.547L37.5614 443.017L38.0259 443.482L38.4966 443.011Z"
          fill="#394B53"
        />
        <path
          d="M38.9626 443.477L38.498 443.013L38.0273 443.484L38.4918 443.948L38.9626 443.477Z"
          fill="#D0E1E9"
        />
        <path
          d="M39.4272 443.942L38.9626 443.477L38.4919 443.948L38.9564 444.413L39.4272 443.942Z"
          fill="#809AA7"
        />
        <path
          d="M43.614 448.129L43.1494 447.664L42.6787 448.135L43.1432 448.599L43.614 448.129Z"
          fill="#43484B"
        />
        <path
          d="M44.0799 448.595L43.6154 448.13L43.1446 448.601L43.6092 449.065L44.0799 448.595Z"
          fill="#373B3E"
        />
        <path
          d="M31.0458 436.505L30.5813 436.041L30.1106 436.511L30.5751 436.976L31.0458 436.505Z"
          fill="#373B3E"
        />
        <path
          d="M31.5119 436.971L31.0474 436.507L30.5766 436.977L31.0412 437.442L31.5119 436.971Z"
          fill="#26282A"
        />
        <path
          d="M35.6987 441.158L35.2341 440.693L34.7634 441.164L35.2279 441.629L35.6987 441.158Z"
          fill="#F0FFFF"
        />
        <path
          d="M36.1633 441.622L35.6987 441.158L35.228 441.629L35.6925 442.093L36.1633 441.622Z"
          fill="#D0E1E9"
        />
        <path
          d="M36.6292 442.088L36.1647 441.624L35.6939 442.095L36.1585 442.559L36.6292 442.088Z"
          fill="#394B53"
        />
        <path
          d="M37.0938 442.553L36.6293 442.088L36.1585 442.559L36.6231 443.024L37.0938 442.553Z"
          fill="#51DFF2"
        />
        <path
          d="M37.5599 443.019L37.0953 442.554L36.6246 443.025L37.0891 443.49L37.5599 443.019Z"
          fill="#05B8CF"
        />
        <path
          d="M38.0244 443.484L37.5598 443.019L37.0891 443.49L37.5536 443.954L38.0244 443.484Z"
          fill="#394B53"
        />
        <path
          d="M38.4904 443.95L38.0259 443.485L37.5551 443.956L38.0197 444.42L38.4904 443.95Z"
          fill="#D0E1E9"
        />
        <path
          d="M38.9549 444.414L38.4904 443.95L38.0196 444.42L38.4842 444.885L38.9549 444.414Z"
          fill="#809AA7"
        />
        <path
          d="M43.1417 448.601L42.6771 448.136L42.2064 448.607L42.6709 449.072L43.1417 448.601Z"
          fill="#373B3E"
        />
        <path
          d="M43.6077 449.067L43.1432 448.602L42.6725 449.073L43.137 449.538L43.6077 449.067Z"
          fill="#26282A"
        />
        <path
          d="M30.5751 436.976L30.1106 436.511L29.6399 436.982L30.1044 437.447L30.5751 436.976Z"
          fill="#373B3E"
        />
        <path
          d="M31.0412 437.442L30.5767 436.977L30.1059 437.448L30.5705 437.913L31.0412 437.442Z"
          fill="#26282A"
        />
        <path
          d="M35.228 441.629L34.7634 441.164L34.2927 441.635L34.7572 442.099L35.228 441.629Z"
          fill="#F0FFFF"
        />
        <path
          d="M35.6924 442.093L35.2279 441.629L34.7572 442.099L35.2217 442.564L35.6924 442.093Z"
          fill="#D0E1E9"
        />
        <path
          d="M36.1585 442.559L35.694 442.095L35.2232 442.565L35.6878 443.03L36.1585 442.559Z"
          fill="#394B53"
        />
        <path
          d="M36.6231 443.024L36.1586 442.559L35.6878 443.03L36.1524 443.494L36.6231 443.024Z"
          fill="#05B8CF"
        />
        <path
          d="M37.089 443.49L36.6245 443.025L36.1538 443.496L36.6183 443.96L37.089 443.49Z"
          fill="#058499"
        />
        <path
          d="M37.5536 443.954L37.0891 443.49L36.6184 443.96L37.0829 444.425L37.5536 443.954Z"
          fill="#394B53"
        />
        <path
          d="M38.0196 444.42L37.5551 443.956L37.0843 444.426L37.5489 444.891L38.0196 444.42Z"
          fill="#D0E1E9"
        />
        <path
          d="M38.4842 444.885L38.0197 444.42L37.5489 444.891L38.0135 445.356L38.4842 444.885Z"
          fill="#809AA7"
        />
        <path
          d="M42.671 449.072L42.2064 448.607L41.7357 449.078L42.2002 449.542L42.671 449.072Z"
          fill="#373B3E"
        />
        <path
          d="M43.137 449.538L42.6725 449.073L42.2017 449.544L42.6663 450.008L43.137 449.538Z"
          fill="#26282A"
        />
        <path
          d="M30.1044 437.447L29.6399 436.982L29.1677 437.454L29.6322 437.919L30.1044 437.447Z"
          fill="#373B3E"
        />
        <path
          d="M30.5705 437.913L30.106 437.448L29.6337 437.92L30.0983 438.385L30.5705 437.913Z"
          fill="#26282A"
        />
        <path
          d="M34.7573 442.099L34.2927 441.635L33.8205 442.107L34.285 442.572L34.7573 442.099Z"
          fill="#F0FFFF"
        />
        <path
          d="M35.2217 442.564L34.7572 442.099L34.285 442.572L34.7495 443.036L35.2217 442.564Z"
          fill="#D0E1E9"
        />
        <path
          d="M35.6878 443.03L35.2233 442.565L34.751 443.038L35.2156 443.502L35.6878 443.03Z"
          fill="#394B53"
        />
        <path
          d="M36.1523 443.494L35.6877 443.03L35.2155 443.502L35.68 443.967L36.1523 443.494Z"
          fill="#51DFF2"
        />
        <path
          d="M36.6183 443.96L36.1538 443.496L35.6816 443.968L36.1461 444.433L36.6183 443.96Z"
          fill="#05B8CF"
        />
        <path
          d="M37.0828 444.425L36.6183 443.96L36.146 444.433L36.6106 444.897L37.0828 444.425Z"
          fill="#394B53"
        />
        <path
          d="M37.5489 444.891L37.0844 444.426L36.6121 444.899L37.0766 445.363L37.5489 444.891Z"
          fill="#D0E1E9"
        />
        <path
          d="M38.0135 445.356L37.549 444.891L37.0767 445.363L37.5412 445.828L38.0135 445.356Z"
          fill="#809AA7"
        />
        <path
          d="M42.2003 449.542L41.7357 449.078L41.2635 449.55L41.728 450.015L42.2003 449.542Z"
          fill="#373B3E"
        />
        <path
          d="M42.6662 450.008L42.2017 449.544L41.7294 450.016L42.194 450.481L42.6662 450.008Z"
          fill="#26282A"
        />
        <path
          d="M29.6321 437.919L29.1676 437.454L28.6969 437.925L29.1614 438.39L29.6321 437.919Z"
          fill="#373B3E"
        />
        <path
          d="M30.0982 438.385L29.6337 437.92L29.1629 438.391L29.6275 438.856L30.0982 438.385Z"
          fill="#26282A"
        />
        <path
          d="M33.8204 442.107L33.3544 441.641L32.8836 442.112L33.3497 442.578L33.8204 442.107Z"
          fill="#D0E1E9"
        />
        <path
          d="M34.285 442.572L33.8204 442.107L33.3497 442.578L33.8142 443.042L34.285 442.572Z"
          fill="#F0FFFF"
        />
        <path
          d="M34.7496 443.036L34.285 442.572L33.8143 443.042L34.2788 443.507L34.7496 443.036Z"
          fill="#D0E1E9"
        />
        <path
          d="M35.2155 443.502L34.751 443.038L34.2802 443.508L34.7448 443.973L35.2155 443.502Z"
          fill="#394B53"
        />
        <path
          d="M35.6801 443.967L35.2156 443.502L34.7448 443.973L35.2094 444.437L35.6801 443.967Z"
          fill="#51DFF2"
        />
        <path
          d="M36.1461 444.433L35.6815 443.968L35.2108 444.439L35.6753 444.903L36.1461 444.433Z"
          fill="#05B8CF"
        />
        <path
          d="M36.6107 444.897L36.1461 444.433L35.6754 444.903L36.1399 445.368L36.6107 444.897Z"
          fill="#394B53"
        />
        <path
          d="M37.0767 445.363L36.6122 444.899L36.1414 445.369L36.606 445.834L37.0767 445.363Z"
          fill="#D0E1E9"
        />
        <path
          d="M37.5412 445.828L37.0767 445.363L36.6059 445.834L37.0705 446.299L37.5412 445.828Z"
          fill="#809AA7"
        />
        <path
          d="M38.0073 446.294L37.5427 445.829L37.072 446.3L37.5365 446.765L38.0073 446.294Z"
          fill="#D0E1E9"
        />
        <path
          d="M41.728 450.015L41.2634 449.55L40.7927 450.021L41.2572 450.485L41.728 450.015Z"
          fill="#373B3E"
        />
        <path
          d="M42.194 450.481L41.7295 450.016L41.2588 450.487L41.7233 450.951L42.194 450.481Z"
          fill="#26282A"
        />
        <path
          d="M28.6984 437.926L28.2324 437.46L27.7617 437.931L28.2277 438.397L28.6984 437.926Z"
          fill="#D0E1E9"
        />
        <path
          d="M29.1614 438.39L28.6969 437.925L28.2262 438.396L28.6907 438.86L29.1614 438.39Z"
          fill="#373B3E"
        />
        <path
          d="M29.6275 438.856L29.163 438.391L28.6922 438.862L29.1568 439.326L29.6275 438.856Z"
          fill="#26282A"
        />
        <path
          d="M30.092 439.32L29.6274 438.856L29.1567 439.326L29.6212 439.791L30.092 439.32Z"
          fill="#809AA7"
        />
        <path
          d="M32.8837 442.112L32.4192 441.647L31.9485 442.118L32.413 442.582L32.8837 442.112Z"
          fill="#D0E1E9"
        />
        <path
          d="M33.3497 442.578L32.8837 442.112L32.4129 442.582L32.879 443.049L33.3497 442.578Z"
          fill="#809AA7"
        />
        <path
          d="M33.8143 443.042L33.3497 442.578L32.879 443.049L33.3435 443.513L33.8143 443.042Z"
          fill="#F0FFFF"
        />
        <path
          d="M34.2787 443.507L33.8142 443.042L33.3435 443.513L33.808 443.978L34.2787 443.507Z"
          fill="#D0E1E9"
        />
        <path
          d="M34.7448 443.973L34.2803 443.508L33.8095 443.979L34.2741 444.444L34.7448 443.973Z"
          fill="#394B53"
        />
        <path
          d="M35.2093 444.437L34.7448 443.973L34.274 444.444L34.7386 444.908L35.2093 444.437Z"
          fill="#05B8CF"
        />
        <path
          d="M35.6754 444.903L35.2108 444.439L34.7401 444.91L35.2046 445.374L35.6754 444.903Z"
          fill="#058499"
        />
        <path
          d="M36.14 445.368L35.6754 444.903L35.2047 445.374L35.6692 445.839L36.14 445.368Z"
          fill="#394B53"
        />
        <path
          d="M36.6059 445.834L36.1414 445.369L35.6706 445.84L36.1352 446.305L36.6059 445.834Z"
          fill="#D0E1E9"
        />
        <path
          d="M37.0705 446.299L36.606 445.834L36.1352 446.305L36.5998 446.769L37.0705 446.299Z"
          fill="#809AA7"
        />
        <path
          d="M37.5364 446.765L37.0719 446.3L36.6012 446.771L37.0657 447.235L37.5364 446.765Z"
          fill="#9AB0BB"
        />
        <path
          d="M38.001 447.229L37.5365 446.765L37.0658 447.235L37.5303 447.7L38.001 447.229Z"
          fill="#D0E1E9"
        />
        <path
          d="M41.2573 450.485L40.7927 450.021L40.322 450.492L40.7865 450.956L41.2573 450.485Z"
          fill="#373B3E"
        />
        <path
          d="M41.7232 450.951L41.2587 450.487L40.7879 450.958L41.2525 451.422L41.7232 450.951Z"
          fill="#26282A"
        />
        <path
          d="M28.2262 438.399L27.7601 437.933L27.2894 438.403L27.7554 438.869L28.2262 438.399Z"
          fill="#F0FFFF"
        />
        <path
          d="M28.6891 438.862L28.2246 438.397L27.7539 438.868L28.2184 439.332L28.6891 438.862Z"
          fill="#D0E1E9"
        />
        <path
          d="M29.1552 439.328L28.6907 438.863L28.2199 439.334L28.6845 439.799L29.1552 439.328Z"
          fill="#9AB0BB"
        />
        <path
          d="M29.6198 439.792L29.1553 439.328L28.6845 439.799L29.1491 440.263L29.6198 439.792Z"
          fill="#809AA7"
        />
        <path
          d="M31.9469 442.119L31.4808 441.653L31.0101 442.124L31.4761 442.59L31.9469 442.119Z"
          fill="#D0E1E9"
        />
        <path
          d="M32.4114 442.584L31.9469 442.119L31.4762 442.59L31.9407 443.055L32.4114 442.584Z"
          fill="#9AB0BB"
        />
        <path
          d="M32.8775 443.05L32.4115 442.584L31.9408 443.055L32.4068 443.521L32.8775 443.05Z"
          fill="#F0FFFF"
        />
        <path
          d="M33.342 443.515L32.8774 443.05L32.4067 443.521L32.8712 443.985L33.342 443.515Z"
          fill="#F0FFFF"
        />
        <path
          d="M33.8066 443.979L33.342 443.515L32.8713 443.985L33.3358 444.45L33.8066 443.979Z"
          fill="#9AB0BB"
        />
        <path
          d="M34.2725 444.445L33.808 443.981L33.3372 444.451L33.8018 444.916L34.2725 444.445Z"
          fill="#D0E1E9"
        />
        <path
          d="M34.7371 444.91L34.2726 444.445L33.8018 444.916L34.2664 445.38L34.7371 444.91Z"
          fill="#D0E1E9"
        />
        <path
          d="M35.2032 445.376L34.7386 444.911L34.2679 445.382L34.7324 445.846L35.2032 445.376Z"
          fill="#D0E1E9"
        />
        <path
          d="M35.6677 445.84L35.2031 445.376L34.7324 445.846L35.1969 446.311L35.6677 445.84Z"
          fill="#D0E1E9"
        />
        <path
          d="M36.1337 446.306L35.6692 445.842L35.1985 446.312L35.663 446.777L36.1337 446.306Z"
          fill="#9AB0BB"
        />
        <path
          d="M36.5982 446.771L36.1337 446.306L35.6629 446.777L36.1275 447.242L36.5982 446.771Z"
          fill="#809AA7"
        />
        <path
          d="M37.0643 447.237L36.5997 446.772L36.129 447.243L36.5935 447.708L37.0643 447.237Z"
          fill="#809AA7"
        />
        <path
          d="M37.5287 447.701L37.0642 447.237L36.5935 447.708L37.058 448.172L37.5287 447.701Z"
          fill="#9AB0BB"
        />
        <path
          d="M37.9948 448.167L37.5288 447.701L37.0581 448.172L37.5241 448.638L37.9948 448.167Z"
          fill="#D0E1E9"
        />
        <path
          d="M40.3205 450.493L39.856 450.028L39.3852 450.499L39.8498 450.964L40.3205 450.493Z"
          fill="#F0FFFF"
        />
        <path
          d="M40.785 450.958L40.3204 450.493L39.8497 450.964L40.3142 451.428L40.785 450.958Z"
          fill="#373B3E"
        />
        <path
          d="M41.251 451.424L40.7865 450.959L40.3158 451.43L40.7803 451.894L41.251 451.424Z"
          fill="#26282A"
        />
        <path
          d="M41.7155 451.888L41.251 451.424L40.7802 451.894L41.2448 452.359L41.7155 451.888Z"
          fill="#809AA7"
        />
        <path
          d="M27.7555 438.869L27.2894 438.403L26.8187 438.874L27.2847 439.34L27.7555 438.869Z"
          fill="#F0FFFF"
        />
        <path
          d="M28.2184 439.332L27.7539 438.868L27.2832 439.339L27.7477 439.803L28.2184 439.332Z"
          fill="#D0E1E9"
        />
        <path
          d="M28.6845 439.799L28.22 439.334L27.7492 439.805L28.2138 440.269L28.6845 439.799Z"
          fill="#9AB0BB"
        />
        <path
          d="M29.149 440.263L28.6844 439.799L28.2137 440.269L28.6783 440.734L29.149 440.263Z"
          fill="#809AA7"
        />
        <path
          d="M31.0102 442.124L30.5457 441.66L30.0749 442.13L30.5395 442.595L31.0102 442.124Z"
          fill="#D0E1E9"
        />
        <path
          d="M31.4762 442.59L31.0101 442.124L30.5394 442.595L31.0054 443.061L31.4762 442.59Z"
          fill="#9AB0BB"
        />
        <path
          d="M31.9407 443.055L31.4762 442.59L31.0055 443.061L31.47 443.525L31.9407 443.055Z"
          fill="#9AB0BB"
        />
        <path
          d="M32.4067 443.521L31.9407 443.055L31.4699 443.525L31.936 443.992L32.4067 443.521Z"
          fill="#F0FFFF"
        />
        <path
          d="M32.8713 443.985L32.4067 443.521L31.936 443.991L32.4005 444.456L32.8713 443.985Z"
          fill="#F0FFFF"
        />
        <path
          d="M33.3358 444.45L32.8712 443.985L32.4005 444.456L32.865 444.921L33.3358 444.45Z"
          fill="#D0E1E9"
        />
        <path
          d="M33.8018 444.916L33.3373 444.451L32.8665 444.922L33.3311 445.387L33.8018 444.916Z"
          fill="#394B53"
        />
        <path
          d="M34.2664 445.38L33.8019 444.916L33.3311 445.387L33.7957 445.851L34.2664 445.38Z"
          fill="#51DFF2"
        />
        <path
          d="M34.7324 445.846L34.2678 445.382L33.7971 445.853L34.2616 446.317L34.7324 445.846Z"
          fill="#05B8CF"
        />
        <path
          d="M35.197 446.311L34.7324 445.846L34.2617 446.317L34.7262 446.782L35.197 446.311Z"
          fill="#394B53"
        />
        <path
          d="M35.6629 446.777L35.1984 446.312L34.7276 446.783L35.1922 447.248L35.6629 446.777Z"
          fill="#D0E1E9"
        />
        <path
          d="M36.1275 447.242L35.663 446.777L35.1922 447.248L35.6568 447.712L36.1275 447.242Z"
          fill="#809AA7"
        />
        <path
          d="M36.5936 447.708L36.129 447.243L35.6583 447.714L36.1228 448.178L36.5936 447.708Z"
          fill="#809AA7"
        />
        <path
          d="M37.058 448.172L36.5935 447.708L36.1228 448.178L36.5873 448.643L37.058 448.172Z"
          fill="#9AB0BB"
        />
        <path
          d="M37.5241 448.638L37.0581 448.172L36.5874 448.643L37.0534 449.109L37.5241 448.638Z"
          fill="#9AB0BB"
        />
        <path
          d="M37.9886 449.103L37.524 448.638L37.0533 449.109L37.5178 449.573L37.9886 449.103Z"
          fill="#D0E1E9"
        />
        <path
          d="M39.8497 450.964L39.3851 450.499L38.9144 450.97L39.3789 451.434L39.8497 450.964Z"
          fill="#F0FFFF"
        />
        <path
          d="M40.3143 451.428L39.8497 450.964L39.379 451.434L39.8435 451.899L40.3143 451.428Z"
          fill="#D0E1E9"
        />
        <path
          d="M40.7803 451.894L40.3158 451.43L39.8451 451.901L40.3096 452.365L40.7803 451.894Z"
          fill="#9AB0BB"
        />
        <path
          d="M41.2448 452.359L40.7803 451.894L40.3095 452.365L40.7741 452.83L41.2448 452.359Z"
          fill="#809AA7"
        />
        <path
          d="M27.2832 439.342L26.8171 438.876L26.3464 439.346L26.8124 439.812L27.2832 439.342Z"
          fill="#FFAD32"
        />
        <path
          d="M27.7463 439.805L27.2817 439.34L26.811 439.811L27.2755 440.275L27.7463 439.805Z"
          fill="#F7852F"
        />
        <path
          d="M28.2122 440.271L27.7477 439.806L27.2769 440.277L27.7415 440.741L28.2122 440.271Z"
          fill="#F7852F"
        />
        <path
          d="M28.6768 440.735L28.2123 440.271L27.7415 440.741L28.2061 441.206L28.6768 440.735Z"
          fill="#D8531A"
        />
        <path
          d="M29.1428 441.201L28.6782 440.737L28.2075 441.208L28.672 441.672L29.1428 441.201Z"
          fill="#D0E1E9"
        />
        <path
          d="M29.6074 441.666L29.1428 441.201L28.6721 441.672L29.1366 442.137L29.6074 441.666Z"
          fill="#D0E1E9"
        />
        <path
          d="M30.0734 442.132L29.6089 441.667L29.1382 442.138L29.6027 442.603L30.0734 442.132Z"
          fill="#D0E1E9"
        />
        <path
          d="M30.5379 442.596L30.0734 442.132L29.6026 442.603L30.0672 443.067L30.5379 442.596Z"
          fill="#9AB0BB"
        />
        <path
          d="M31.004 443.062L30.538 442.596L30.0672 443.067L30.5333 443.533L31.004 443.062Z"
          fill="#9AB0BB"
        />
        <path
          d="M31.4684 443.527L31.0039 443.062L30.5332 443.533L30.9977 443.998L31.4684 443.527Z"
          fill="#9AB0BB"
        />
        <path
          d="M31.9345 443.993L31.4685 443.527L30.9978 443.998L31.4638 444.464L31.9345 443.993Z"
          fill="#F0FFFF"
        />
        <path
          d="M32.399 444.458L31.9344 443.993L31.4637 444.464L31.9283 444.928L32.399 444.458Z"
          fill="#F0FFFF"
        />
        <path
          d="M32.8636 444.922L32.399 444.458L31.9283 444.928L32.3928 445.393L32.8636 444.922Z"
          fill="#D0E1E9"
        />
        <path
          d="M33.3296 445.388L32.8651 444.924L32.3944 445.394L32.8589 445.859L33.3296 445.388Z"
          fill="#394B53"
        />
        <path
          d="M33.7941 445.853L33.3296 445.388L32.8589 445.859L33.3234 446.323L33.7941 445.853Z"
          fill="#51DFF2"
        />
        <path
          d="M34.2602 446.319L33.7957 445.854L33.3249 446.325L33.7895 446.789L34.2602 446.319Z"
          fill="#05B8CF"
        />
        <path
          d="M34.7247 446.783L34.2601 446.319L33.7894 446.789L34.2539 447.254L34.7247 446.783Z"
          fill="#394B53"
        />
        <path
          d="M35.1907 447.249L34.7262 446.785L34.2555 447.255L34.72 447.72L35.1907 447.249Z"
          fill="#D0E1E9"
        />
        <path
          d="M35.6552 447.714L35.1907 447.249L34.7199 447.72L35.1845 448.184L35.6552 447.714Z"
          fill="#809AA7"
        />
        <path
          d="M36.1213 448.18L35.6567 447.715L35.186 448.186L35.6505 448.651L36.1213 448.18Z"
          fill="#809AA7"
        />
        <path
          d="M36.5858 448.644L36.1212 448.18L35.6505 448.651L36.115 449.115L36.5858 448.644Z"
          fill="#9AB0BB"
        />
        <path
          d="M37.0518 449.11L36.5858 448.644L36.1151 449.115L36.5811 449.581L37.0518 449.11Z"
          fill="#9AB0BB"
        />
        <path
          d="M37.5164 449.575L37.0519 449.11L36.5811 449.581L37.0457 450.046L37.5164 449.575Z"
          fill="#9AB0BB"
        />
        <path
          d="M37.9809 450.039L37.5164 449.575L37.0456 450.046L37.5102 450.51L37.9809 450.039Z"
          fill="#D0E1E9"
        />
        <path
          d="M38.447 450.505L37.9824 450.041L37.5117 450.512L37.9762 450.976L38.447 450.505Z"
          fill="#D0E1E9"
        />
        <path
          d="M38.9114 450.97L38.4469 450.505L37.9762 450.976L38.4407 451.441L38.9114 450.97Z"
          fill="#D0E1E9"
        />
        <path
          d="M39.3775 451.436L38.913 450.971L38.4422 451.442L38.9068 451.907L39.3775 451.436Z"
          fill="#FFAD32"
        />
        <path
          d="M39.842 451.901L39.3774 451.436L38.9067 451.907L39.3712 452.371L39.842 451.901Z"
          fill="#F7852F"
        />
        <path
          d="M40.308 452.367L39.8435 451.902L39.3728 452.373L39.8373 452.837L40.308 452.367Z"
          fill="#F7852F"
        />
        <path
          d="M40.7726 452.831L40.3081 452.367L39.8374 452.837L40.3019 453.302L40.7726 452.831Z"
          fill="#D8531A"
        />
        <path
          d="M26.8125 439.812L26.3464 439.346L25.8757 439.817L26.3417 440.283L26.8125 439.812Z"
          fill="#F0FFFF"
        />
        <path
          d="M27.2755 440.275L26.8109 439.811L26.3402 440.282L26.8047 440.746L27.2755 440.275Z"
          fill="#D0E1E9"
        />
        <path
          d="M27.7415 440.741L27.277 440.277L26.8062 440.748L27.2708 441.212L27.7415 440.741Z"
          fill="#9AB0BB"
        />
        <path
          d="M28.2061 441.206L27.7416 440.741L27.2708 441.212L27.7354 441.677L28.2061 441.206Z"
          fill="#809AA7"
        />
        <path
          d="M28.6721 441.672L28.2075 441.208L27.7368 441.678L28.2013 442.143L28.6721 441.672Z"
          fill="#9AB0BB"
        />
        <path
          d="M29.1367 442.137L28.6721 441.672L28.2014 442.143L28.6659 442.607L29.1367 442.137Z"
          fill="#9AB0BB"
        />
        <path
          d="M29.6026 442.603L29.1381 442.138L28.6673 442.609L29.1319 443.073L29.6026 442.603Z"
          fill="#809AA7"
        />
        <path
          d="M30.0672 443.067L29.6027 442.603L29.1319 443.073L29.5965 443.538L30.0672 443.067Z"
          fill="#F0FFFF"
        />
        <path
          d="M30.5332 443.533L30.0671 443.067L29.5964 443.538L30.0624 444.004L30.5332 443.533Z"
          fill="#F0FFFF"
        />
        <path
          d="M30.9977 443.998L30.5332 443.533L30.0625 444.004L30.527 444.468L30.9977 443.998Z"
          fill="#F0FFFF"
        />
        <path
          d="M31.4637 444.464L30.9977 443.998L30.5269 444.468L30.993 444.934L31.4637 444.464Z"
          fill="#D0E1E9"
        />
        <path
          d="M31.9283 444.928L31.4637 444.464L30.993 444.934L31.4575 445.399L31.9283 444.928Z"
          fill="#F0FFFF"
        />
        <path
          d="M32.3929 445.393L31.9283 444.928L31.4576 445.399L31.9221 445.864L32.3929 445.393Z"
          fill="#D0E1E9"
        />
        <path
          d="M32.8588 445.859L32.3943 445.394L31.9236 445.865L32.3881 446.33L32.8588 445.859Z"
          fill="#394B53"
        />
        <path
          d="M33.3234 446.323L32.8589 445.859L32.3882 446.33L32.8527 446.794L33.3234 446.323Z"
          fill="#05B8CF"
        />
        <path
          d="M33.7894 446.789L33.3248 446.325L32.8541 446.796L33.3186 447.26L33.7894 446.789Z"
          fill="#058499"
        />
        <path
          d="M34.254 447.254L33.7894 446.789L33.3187 447.26L33.7832 447.725L34.254 447.254Z"
          fill="#394B53"
        />
        <path
          d="M34.72 447.72L34.2555 447.255L33.7848 447.726L34.2493 448.191L34.72 447.72Z"
          fill="#D0E1E9"
        />
        <path
          d="M35.1845 448.184L34.72 447.72L34.2492 448.191L34.7138 448.655L35.1845 448.184Z"
          fill="#809AA7"
        />
        <path
          d="M35.6506 448.651L35.186 448.186L34.7153 448.657L35.1798 449.121L35.6506 448.651Z"
          fill="#D0E1E9"
        />
        <path
          d="M36.1151 449.115L35.6505 448.651L35.1798 449.121L35.6443 449.586L36.1151 449.115Z"
          fill="#D0E1E9"
        />
        <path
          d="M36.5811 449.581L36.1151 449.115L35.6444 449.586L36.1104 450.052L36.5811 449.581Z"
          fill="#D0E1E9"
        />
        <path
          d="M37.0456 450.046L36.5811 449.581L36.1103 450.052L36.5749 450.516L37.0456 450.046Z"
          fill="#809AA7"
        />
        <path
          d="M37.5102 450.51L37.0457 450.046L36.5749 450.516L37.0395 450.981L37.5102 450.51Z"
          fill="#9AB0BB"
        />
        <path
          d="M37.9761 450.976L37.5116 450.512L37.0409 450.982L37.5054 451.447L37.9761 450.976Z"
          fill="#9AB0BB"
        />
        <path
          d="M38.4407 451.441L37.9762 450.976L37.5055 451.447L37.97 451.911L38.4407 451.441Z"
          fill="#809AA7"
        />
        <path
          d="M38.9068 451.907L38.4423 451.442L37.9715 451.913L38.4361 452.377L38.9068 451.907Z"
          fill="#F0FFFF"
        />
        <path
          d="M39.3713 452.371L38.9067 451.907L38.436 452.377L38.9005 452.842L39.3713 452.371Z"
          fill="#D0E1E9"
        />
        <path
          d="M39.8373 452.837L39.3728 452.373L38.9021 452.843L39.3666 453.308L39.8373 452.837Z"
          fill="#9AB0BB"
        />
        <path
          d="M40.3018 453.302L39.8373 452.837L39.3665 453.308L39.8311 453.773L40.3018 453.302Z"
          fill="#809AA7"
        />
        <path
          d="M26.3402 440.285L25.8741 439.819L25.4034 440.289L25.8694 440.755L26.3402 440.285Z"
          fill="#F0FFFF"
        />
        <path
          d="M26.8033 440.748L26.3387 440.283L25.868 440.754L26.3325 441.218L26.8033 440.748Z"
          fill="#D0E1E9"
        />
        <path
          d="M27.2692 441.214L26.8047 440.749L26.334 441.22L26.7985 441.684L27.2692 441.214Z"
          fill="#9AB0BB"
        />
        <path
          d="M27.7338 441.678L27.2693 441.214L26.7986 441.684L27.2631 442.149L27.7338 441.678Z"
          fill="#809AA7"
        />
        <path
          d="M28.1999 442.144L27.7354 441.68L27.2646 442.15L27.7292 442.615L28.1999 442.144Z"
          fill="#9AB0BB"
        />
        <path
          d="M28.6644 442.609L28.1998 442.144L27.7291 442.615L28.1936 443.08L28.6644 442.609Z"
          fill="#809AA7"
        />
        <path
          d="M29.1304 443.075L28.6659 442.61L28.1952 443.081L28.6597 443.546L29.1304 443.075Z"
          fill="#FFAD32"
        />
        <path
          d="M29.5949 443.539L29.1304 443.075L28.6596 443.546L29.1242 444.01L29.5949 443.539Z"
          fill="#F7852F"
        />
        <path
          d="M30.061 444.005L29.595 443.539L29.1242 444.01L29.5903 444.476L30.061 444.005Z"
          fill="#F7852F"
        />
        <path
          d="M30.5255 444.47L30.0609 444.005L29.5902 444.476L30.0547 444.941L30.5255 444.47Z"
          fill="#F7852F"
        />
        <path
          d="M30.9915 444.936L30.5255 444.47L30.0548 444.941L30.5208 445.407L30.9915 444.936Z"
          fill="#F7852F"
        />
        <path
          d="M31.4561 445.401L30.9916 444.936L30.5208 445.407L30.9854 445.871L31.4561 445.401Z"
          fill="#F0FFFF"
        />
        <path
          d="M31.9206 445.865L31.4561 445.401L30.9853 445.871L31.4499 446.336L31.9206 445.865Z"
          fill="#D0E1E9"
        />
        <path
          d="M32.3867 446.331L31.9221 445.867L31.4514 446.337L31.9159 446.802L32.3867 446.331Z"
          fill="#809AA7"
        />
        <path
          d="M32.8511 446.796L32.3866 446.331L31.9159 446.802L32.3804 447.266L32.8511 446.796Z"
          fill="#394B53"
        />
        <path
          d="M33.3172 447.262L32.8527 446.797L32.3819 447.268L32.8465 447.732L33.3172 447.262Z"
          fill="#394B53"
        />
        <path
          d="M33.7817 447.726L33.3171 447.262L32.8464 447.732L33.3109 448.197L33.7817 447.726Z"
          fill="#809AA7"
        />
        <path
          d="M34.2477 448.192L33.7832 447.728L33.3125 448.198L33.777 448.663L34.2477 448.192Z"
          fill="#D0E1E9"
        />
        <path
          d="M34.7122 448.657L34.2477 448.192L33.7769 448.663L34.2415 449.127L34.7122 448.657Z"
          fill="#809AA7"
        />
        <path
          d="M35.1783 449.123L34.7137 448.658L34.243 449.129L34.7075 449.593L35.1783 449.123Z"
          fill="#F7852F"
        />
        <path
          d="M35.6429 449.587L35.1783 449.123L34.7076 449.593L35.1721 450.058L35.6429 449.587Z"
          fill="#F7852F"
        />
        <path
          d="M36.1088 450.053L35.6428 449.587L35.1721 450.058L35.6381 450.524L36.1088 450.053Z"
          fill="#F7852F"
        />
        <path
          d="M36.5734 450.518L36.1089 450.053L35.6382 450.524L36.1027 450.989L36.5734 450.518Z"
          fill="#F7852F"
        />
        <path
          d="M37.0379 450.982L36.5734 450.518L36.1026 450.989L36.5672 451.453L37.0379 450.982Z"
          fill="#D8531A"
        />
        <path
          d="M37.504 451.448L37.0394 450.984L36.5687 451.455L37.0332 451.919L37.504 451.448Z"
          fill="#9AB0BB"
        />
        <path
          d="M37.9684 451.913L37.5039 451.448L37.0332 451.919L37.4977 452.384L37.9684 451.913Z"
          fill="#809AA7"
        />
        <path
          d="M38.4345 452.379L37.97 451.914L37.4992 452.385L37.9638 452.85L38.4345 452.379Z"
          fill="#F0FFFF"
        />
        <path
          d="M38.8991 452.844L38.4346 452.379L37.9638 452.85L38.4284 453.314L38.8991 452.844Z"
          fill="#D0E1E9"
        />
        <path
          d="M39.3651 453.31L38.9005 452.845L38.4298 453.316L38.8943 453.78L39.3651 453.31Z"
          fill="#9AB0BB"
        />
        <path
          d="M39.8296 453.774L39.3651 453.31L38.8944 453.78L39.3589 454.245L39.8296 453.774Z"
          fill="#809AA7"
        />
        <path
          d="M25.8695 440.755L25.4034 440.289L24.9327 440.76L25.3987 441.226L25.8695 440.755Z"
          fill="#F0FFFF"
        />
        <path
          d="M26.3326 441.218L25.868 440.754L25.3973 441.225L25.8618 441.689L26.3326 441.218Z"
          fill="#D0E1E9"
        />
        <path
          d="M26.7985 441.684L26.334 441.22L25.8632 441.691L26.3278 442.155L26.7985 441.684Z"
          fill="#9AB0BB"
        />
        <path
          d="M27.2631 442.149L26.7986 441.684L26.3278 442.155L26.7924 442.62L27.2631 442.149Z"
          fill="#809AA7"
        />
        <path
          d="M27.7291 442.615L27.2645 442.15L26.7938 442.621L27.2583 443.086L27.7291 442.615Z"
          fill="#809AA7"
        />
        <path
          d="M28.1937 443.08L27.7291 442.615L27.2584 443.086L27.7229 443.55L28.1937 443.08Z"
          fill="#F0FFFF"
        />
        <path
          d="M28.6596 443.546L28.1951 443.081L27.7243 443.552L28.1889 444.016L28.6596 443.546Z"
          fill="#D0E1E9"
        />
        <path
          d="M29.1242 444.01L28.6597 443.546L28.1889 444.016L28.6535 444.481L29.1242 444.01Z"
          fill="#D0E1E9"
        />
        <path
          d="M29.5902 444.476L29.1241 444.01L28.6534 444.481L29.1194 444.947L29.5902 444.476Z"
          fill="#D0E1E9"
        />
        <path
          d="M30.0547 444.941L29.5902 444.476L29.1195 444.947L29.584 445.411L30.0547 444.941Z"
          fill="#D0E1E9"
        />
        <path
          d="M30.5208 445.407L30.0548 444.941L29.5841 445.411L30.0501 445.877L30.5208 445.407Z"
          fill="#D0E1E9"
        />
        <path
          d="M30.9853 445.871L30.5208 445.407L30.05 445.877L30.5146 446.342L30.9853 445.871Z"
          fill="#F0FFFF"
        />
        <path
          d="M31.4499 446.336L30.9854 445.871L30.5146 446.342L30.9792 446.807L31.4499 446.336Z"
          fill="#D0E1E9"
        />
        <path
          d="M31.9158 446.802L31.4513 446.337L30.9806 446.808L31.4451 447.273L31.9158 446.802Z"
          fill="#D0E1E9"
        />
        <path
          d="M32.3804 447.266L31.9159 446.802L31.4452 447.273L31.9097 447.737L32.3804 447.266Z"
          fill="#D0E1E9"
        />
        <path
          d="M32.8465 447.732L32.382 447.268L31.9112 447.739L32.3758 448.203L32.8465 447.732Z"
          fill="#D0E1E9"
        />
        <path
          d="M33.311 448.197L32.8464 447.732L32.3757 448.203L32.8402 448.668L33.311 448.197Z"
          fill="#D0E1E9"
        />
        <path
          d="M33.777 448.663L33.3125 448.198L32.8418 448.669L33.3063 449.134L33.777 448.663Z"
          fill="#D0E1E9"
        />
        <path
          d="M34.2415 449.127L33.777 448.663L33.3062 449.134L33.7708 449.598L34.2415 449.127Z"
          fill="#809AA7"
        />
        <path
          d="M34.7076 449.594L34.243 449.129L33.7723 449.6L34.2368 450.064L34.7076 449.594Z"
          fill="#D0E1E9"
        />
        <path
          d="M35.1721 450.058L34.7075 449.594L34.2368 450.064L34.7013 450.529L35.1721 450.058Z"
          fill="#D0E1E9"
        />
        <path
          d="M35.6381 450.524L35.1721 450.058L34.7014 450.529L35.1674 450.995L35.6381 450.524Z"
          fill="#D0E1E9"
        />
        <path
          d="M36.1026 450.989L35.6381 450.524L35.1673 450.995L35.6319 451.459L36.1026 450.989Z"
          fill="#D0E1E9"
        />
        <path
          d="M36.5672 451.453L36.1027 450.989L35.6319 451.459L36.0965 451.924L36.5672 451.453Z"
          fill="#D0E1E9"
        />
        <path
          d="M37.0333 451.919L36.5687 451.455L36.098 451.925L36.5625 452.39L37.0333 451.919Z"
          fill="#809AA7"
        />
        <path
          d="M37.4977 452.384L37.0332 451.919L36.5625 452.39L37.027 452.854L37.4977 452.384Z"
          fill="#809AA7"
        />
        <path
          d="M37.9638 452.85L37.4993 452.385L37.0285 452.856L37.4931 453.32L37.9638 452.85Z"
          fill="#F0FFFF"
        />
        <path
          d="M38.4283 453.314L37.9637 452.85L37.493 453.32L37.9575 453.785L38.4283 453.314Z"
          fill="#D0E1E9"
        />
        <path
          d="M38.8943 453.78L38.4298 453.316L37.9591 453.786L38.4236 454.251L38.8943 453.78Z"
          fill="#9AB0BB"
        />
        <path
          d="M39.3588 454.245L38.8943 453.78L38.4236 454.251L38.8881 454.716L39.3588 454.245Z"
          fill="#809AA7"
        />
        <path
          d="M25.3972 441.228L24.9312 440.762L24.4604 441.232L24.9264 441.698L25.3972 441.228Z"
          fill="#F0FFFF"
        />
        <path
          d="M25.8603 441.691L25.3958 441.226L24.925 441.697L25.3896 442.161L25.8603 441.691Z"
          fill="#D0E1E9"
        />
        <path
          d="M26.3264 442.157L25.8618 441.692L25.3911 442.163L25.8556 442.627L26.3264 442.157Z"
          fill="#9AB0BB"
        />
        <path
          d="M26.7908 442.621L26.3263 442.157L25.8556 442.627L26.3201 443.092L26.7908 442.621Z"
          fill="#809AA7"
        />
        <path
          d="M27.2569 443.087L26.7924 442.623L26.3216 443.093L26.7862 443.558L27.2569 443.087Z"
          fill="#FFAD32"
        />
        <path
          d="M27.7214 443.552L27.2568 443.087L26.7861 443.558L27.2506 444.023L27.7214 443.552Z"
          fill="#F7852F"
        />
        <path
          d="M28.1874 444.018L27.7229 443.553L27.2522 444.024L27.7167 444.489L28.1874 444.018Z"
          fill="#F7852F"
        />
        <path
          d="M28.6519 444.482L28.1874 444.018L27.7166 444.489L28.1812 444.953L28.6519 444.482Z"
          fill="#F7852F"
        />
        <path
          d="M29.118 444.948L28.652 444.482L28.1812 444.953L28.6473 445.419L29.118 444.948Z"
          fill="#F7852F"
        />
        <path
          d="M29.5825 445.413L29.1179 444.948L28.6472 445.419L29.1117 445.884L29.5825 445.413Z"
          fill="#F7852F"
        />
        <path
          d="M30.0485 445.879L29.5825 445.413L29.1118 445.884L29.5778 446.35L30.0485 445.879Z"
          fill="#F7852F"
        />
        <path
          d="M30.5131 446.344L30.0486 445.879L29.5778 446.35L30.0424 446.814L30.5131 446.344Z"
          fill="#F0FFFF"
        />
        <path
          d="M30.9776 446.808L30.5131 446.344L30.0423 446.814L30.5069 447.279L30.9776 446.808Z"
          fill="#D0E1E9"
        />
        <path
          d="M31.4437 447.274L30.9791 446.81L30.5084 447.28L30.9729 447.745L31.4437 447.274Z"
          fill="#D0E1E9"
        />
        <path
          d="M31.9081 447.739L31.4436 447.274L30.9729 447.745L31.4374 448.209L31.9081 447.739Z"
          fill="#D0E1E9"
        />
        <path
          d="M32.3742 448.205L31.9097 447.74L31.4389 448.211L31.9035 448.675L32.3742 448.205Z"
          fill="#D0E1E9"
        />
        <path
          d="M32.8387 448.669L32.3741 448.205L31.9034 448.675L32.3679 449.14L32.8387 448.669Z"
          fill="#D0E1E9"
        />
        <path
          d="M33.3047 449.135L32.8402 448.671L32.3695 449.141L32.834 449.606L33.3047 449.135Z"
          fill="#D0E1E9"
        />
        <path
          d="M33.7693 449.6L33.3048 449.135L32.8341 449.606L33.2986 450.07L33.7693 449.6Z"
          fill="#809AA7"
        />
        <path
          d="M34.2353 450.066L33.7708 449.601L33.3 450.072L33.7646 450.536L34.2353 450.066Z"
          fill="#F7852F"
        />
        <path
          d="M34.6999 450.53L34.2354 450.066L33.7646 450.536L34.2292 451.001L34.6999 450.53Z"
          fill="#F7852F"
        />
        <path
          d="M35.1659 450.996L34.6998 450.53L34.2291 451.001L34.6951 451.467L35.1659 450.996Z"
          fill="#F7852F"
        />
        <path
          d="M35.6304 451.461L35.1659 450.996L34.6952 451.467L35.1597 451.932L35.6304 451.461Z"
          fill="#F7852F"
        />
        <path
          d="M36.0949 451.925L35.6304 451.461L35.1596 451.932L35.6242 452.396L36.0949 451.925Z"
          fill="#F7852F"
        />
        <path
          d="M36.561 452.391L36.0964 451.927L35.6257 452.398L36.0902 452.862L36.561 452.391Z"
          fill="#F7852F"
        />
        <path
          d="M37.0256 452.856L36.561 452.391L36.0903 452.862L36.5548 453.327L37.0256 452.856Z"
          fill="#D8531A"
        />
        <path
          d="M37.4915 453.322L37.027 452.857L36.5562 453.328L37.0208 453.793L37.4915 453.322Z"
          fill="#F0FFFF"
        />
        <path
          d="M37.9561 453.786L37.4916 453.322L37.0208 453.793L37.4854 454.257L37.9561 453.786Z"
          fill="#D0E1E9"
        />
        <path
          d="M38.4221 454.253L37.9575 453.788L37.4868 454.259L37.9513 454.723L38.4221 454.253Z"
          fill="#9AB0BB"
        />
        <path
          d="M38.8867 454.717L38.4221 454.253L37.9514 454.723L38.4159 455.188L38.8867 454.717Z"
          fill="#809AA7"
        />
        <path
          d="M24.9265 441.698L24.4604 441.232L23.9897 441.703L24.4557 442.169L24.9265 441.698Z"
          fill="#F0FFFF"
        />
        <path
          d="M25.3896 442.161L24.925 441.697L24.4543 442.168L24.9189 442.632L25.3896 442.161Z"
          fill="#D0E1E9"
        />
        <path
          d="M25.8555 442.627L25.391 442.163L24.9203 442.634L25.3848 443.098L25.8555 442.627Z"
          fill="#9AB0BB"
        />
        <path
          d="M26.3201 443.092L25.8556 442.627L25.3849 443.098L25.8494 443.563L26.3201 443.092Z"
          fill="#809AA7"
        />
        <path
          d="M26.7861 443.558L26.3215 443.093L25.8508 443.564L26.3153 444.029L26.7861 443.558Z"
          fill="#D0E1E9"
        />
        <path
          d="M27.2507 444.023L26.7861 443.558L26.3154 444.029L26.7799 444.493L27.2507 444.023Z"
          fill="#D0E1E9"
        />
        <path
          d="M27.7167 444.489L27.2522 444.024L26.7815 444.495L27.246 444.959L27.7167 444.489Z"
          fill="#D0E1E9"
        />
        <path
          d="M28.1812 444.953L27.7167 444.489L27.2459 444.959L27.7105 445.424L28.1812 444.953Z"
          fill="#D0E1E9"
        />
        <path
          d="M28.6473 445.419L28.1813 444.953L27.7105 445.424L28.1766 445.89L28.6473 445.419Z"
          fill="#D0E1E9"
        />
        <path
          d="M29.1118 445.884L28.6472 445.419L28.1765 445.89L28.641 446.354L29.1118 445.884Z"
          fill="#D0E1E9"
        />
        <path
          d="M29.5778 446.35L29.1118 445.884L28.6411 446.354L29.1071 446.82L29.5778 446.35Z"
          fill="#D0E1E9"
        />
        <path
          d="M30.0423 446.814L29.5778 446.35L29.107 446.82L29.5716 447.285L30.0423 446.814Z"
          fill="#F0FFFF"
        />
        <path
          d="M30.5069 447.279L30.0424 446.814L29.5716 447.285L30.0362 447.75L30.5069 447.279Z"
          fill="#D0E1E9"
        />
        <path
          d="M30.973 447.745L30.5084 447.28L30.0377 447.751L30.5022 448.216L30.973 447.745Z"
          fill="#809AA7"
        />
        <path
          d="M31.4374 448.209L30.9729 447.745L30.5022 448.216L30.9667 448.68L31.4374 448.209Z"
          fill="#809AA7"
        />
        <path
          d="M31.9035 448.675L31.439 448.211L30.9682 448.682L31.4328 449.146L31.9035 448.675Z"
          fill="#809AA7"
        />
        <path
          d="M32.368 449.14L31.9034 448.675L31.4327 449.146L31.8972 449.611L32.368 449.14Z"
          fill="#809AA7"
        />
        <path
          d="M32.834 449.606L32.3695 449.141L31.8988 449.612L32.3633 450.077L32.834 449.606Z"
          fill="#D0E1E9"
        />
        <path
          d="M33.2985 450.07L32.834 449.606L32.3632 450.077L32.8278 450.541L33.2985 450.07Z"
          fill="#809AA7"
        />
        <path
          d="M33.7646 450.536L33.3 450.072L32.8293 450.543L33.2939 451.007L33.7646 450.536Z"
          fill="#D0E1E9"
        />
        <path
          d="M34.2291 451.001L33.7645 450.536L33.2938 451.007L33.7583 451.472L34.2291 451.001Z"
          fill="#D0E1E9"
        />
        <path
          d="M34.6951 451.467L34.2291 451.001L33.7584 451.472L34.2244 451.938L34.6951 451.467Z"
          fill="#D0E1E9"
        />
        <path
          d="M35.1597 451.932L34.6952 451.467L34.2245 451.938L34.689 452.402L35.1597 451.932Z"
          fill="#D0E1E9"
        />
        <path
          d="M35.6242 452.396L35.1597 451.932L34.6889 452.402L35.1535 452.867L35.6242 452.396Z"
          fill="#D0E1E9"
        />
        <path
          d="M36.0903 452.862L35.6257 452.398L35.155 452.868L35.6195 453.333L36.0903 452.862Z"
          fill="#D0E1E9"
        />
        <path
          d="M36.5547 453.327L36.0902 452.862L35.6195 453.333L36.084 453.797L36.5547 453.327Z"
          fill="#D0E1E9"
        />
        <path
          d="M37.0208 453.793L36.5563 453.328L36.0855 453.799L36.5501 454.263L37.0208 453.793Z"
          fill="#F0FFFF"
        />
        <path
          d="M37.4853 454.257L37.0208 453.793L36.55 454.263L37.0146 454.728L37.4853 454.257Z"
          fill="#D0E1E9"
        />
        <path
          d="M37.9514 454.723L37.4868 454.259L37.0161 454.729L37.4806 455.194L37.9514 454.723Z"
          fill="#9AB0BB"
        />
        <path
          d="M38.416 455.188L37.9514 454.723L37.4807 455.194L37.9452 455.659L38.416 455.188Z"
          fill="#809AA7"
        />
        <path
          d="M24.4558 442.169L23.9897 441.703L23.5175 442.175L23.9835 442.641L24.4558 442.169Z"
          fill="#F0FFFF"
        />
        <path
          d="M24.9188 442.632L24.4542 442.168L23.982 442.64L24.4465 443.104L24.9188 442.632Z"
          fill="#D0E1E9"
        />
        <path
          d="M25.3848 443.098L24.9203 442.634L24.448 443.106L24.9126 443.57L25.3848 443.098Z"
          fill="#9AB0BB"
        />
        <path
          d="M25.8494 443.563L25.3849 443.098L24.9126 443.57L25.3772 444.035L25.8494 443.563Z"
          fill="#809AA7"
        />
        <path
          d="M26.3154 444.029L25.8508 443.564L25.3786 444.036L25.8431 444.501L26.3154 444.029Z"
          fill="#9AB0BB"
        />
        <path
          d="M26.78 444.493L26.3154 444.029L25.8432 444.501L26.3077 444.966L26.78 444.493Z"
          fill="#F0FFFF"
        />
        <path
          d="M27.2459 444.959L26.7814 444.495L26.3091 444.967L26.7737 445.432L27.2459 444.959Z"
          fill="#F0FFFF"
        />
        <path
          d="M27.7105 445.424L27.246 444.959L26.7737 445.432L27.2383 445.896L27.7105 445.424Z"
          fill="#F0FFFF"
        />
        <path
          d="M28.1765 445.89L27.7104 445.424L27.2382 445.896L27.7042 446.362L28.1765 445.89Z"
          fill="#F0FFFF"
        />
        <path
          d="M28.6411 446.354L28.1765 445.89L27.7043 446.362L28.1688 446.827L28.6411 446.354Z"
          fill="#F0FFFF"
        />
        <path
          d="M29.107 446.82L28.641 446.354L28.1688 446.827L28.6348 447.293L29.107 446.82Z"
          fill="#F0FFFF"
        />
        <path
          d="M29.5716 447.285L29.1071 446.82L28.6348 447.293L29.0994 447.757L29.5716 447.285Z"
          fill="#F0FFFF"
        />
        <path
          d="M30.0362 447.75L29.5717 447.285L29.0994 447.757L29.564 448.222L30.0362 447.75Z"
          fill="#D0E1E9"
        />
        <path
          d="M30.5021 448.216L30.0376 447.751L29.5654 448.223L30.0299 448.688L30.5021 448.216Z"
          fill="#D0E1E9"
        />
        <path
          d="M30.9667 448.68L30.5022 448.216L30.03 448.688L30.4945 449.152L30.9667 448.68Z"
          fill="#D0E1E9"
        />
        <path
          d="M31.4327 449.146L30.9681 448.682L30.4959 449.154L30.9604 449.618L31.4327 449.146Z"
          fill="#D0E1E9"
        />
        <path
          d="M31.8973 449.611L31.4327 449.146L30.9605 449.618L31.425 450.083L31.8973 449.611Z"
          fill="#D0E1E9"
        />
        <path
          d="M32.3633 450.077L31.8988 449.612L31.4266 450.084L31.8911 450.549L32.3633 450.077Z"
          fill="#D0E1E9"
        />
        <path
          d="M32.8278 450.541L32.3633 450.077L31.891 450.549L32.3556 451.013L32.8278 450.541Z"
          fill="#809AA7"
        />
        <path
          d="M33.2939 451.007L32.8293 450.543L32.3571 451.015L32.8216 451.479L33.2939 451.007Z"
          fill="#9AB0BB"
        />
        <path
          d="M33.7584 451.472L33.2938 451.007L32.8216 451.479L33.2861 451.944L33.7584 451.472Z"
          fill="#F0FFFF"
        />
        <path
          d="M34.2244 451.938L33.7584 451.472L33.2862 451.944L33.7522 452.41L34.2244 451.938Z"
          fill="#F0FFFF"
        />
        <path
          d="M34.6889 452.402L34.2244 451.938L33.7521 452.41L34.2167 452.875L34.6889 452.402Z"
          fill="#F0FFFF"
        />
        <path
          d="M35.1535 452.867L34.689 452.402L34.2167 452.875L34.6813 453.339L35.1535 452.867Z"
          fill="#F0FFFF"
        />
        <path
          d="M35.6194 453.333L35.1549 452.868L34.6827 453.341L35.1472 453.805L35.6194 453.333Z"
          fill="#F0FFFF"
        />
        <path
          d="M36.084 453.797L35.6195 453.333L35.1473 453.805L35.6118 454.27L36.084 453.797Z"
          fill="#F0FFFF"
        />
        <path
          d="M36.5501 454.263L36.0856 453.799L35.6133 454.271L36.0779 454.736L36.5501 454.263Z"
          fill="#F0FFFF"
        />
        <path
          d="M37.0146 454.728L36.55 454.263L36.0778 454.736L36.5423 455.2L37.0146 454.728Z"
          fill="#D0E1E9"
        />
        <path
          d="M37.4807 455.194L37.0161 454.729L36.5439 455.202L37.0084 455.666L37.4807 455.194Z"
          fill="#9AB0BB"
        />
        <path
          d="M37.9451 455.659L37.4806 455.194L37.0083 455.666L37.4729 456.131L37.9451 455.659Z"
          fill="#809AA7"
        />
        <path
          d="M22.1209 440.779L21.6564 440.314L21.1856 440.785L21.6502 441.249L22.1209 440.779Z"
          fill="#F0FFFF"
        />
        <path
          d="M23.5161 442.174L23.0515 441.709L22.5808 442.18L23.0453 442.645L23.5161 442.174Z"
          fill="#F0FFFF"
        />
        <path
          d="M23.9835 442.641L23.5175 442.175L23.0467 442.646L23.5127 443.112L23.9835 442.641Z"
          fill="#D0E1E9"
        />
        <path
          d="M24.4466 443.104L23.9821 442.64L23.5113 443.111L23.9759 443.575L24.4466 443.104Z"
          fill="#D0E1E9"
        />
        <path
          d="M24.9125 443.57L24.448 443.106L23.9773 443.577L24.4418 444.041L24.9125 443.57Z"
          fill="#9AB0BB"
        />
        <path
          d="M25.3771 444.035L24.9126 443.57L24.4419 444.041L24.9064 444.506L25.3771 444.035Z"
          fill="#809AA7"
        />
        <path
          d="M25.8432 444.501L25.3787 444.036L24.9079 444.507L25.3725 444.972L25.8432 444.501Z"
          fill="#809AA7"
        />
        <path
          d="M26.3077 444.966L25.8431 444.501L25.3724 444.972L25.8369 445.436L26.3077 444.966Z"
          fill="#809AA7"
        />
        <path
          d="M26.7737 445.432L26.3092 444.967L25.8385 445.438L26.303 445.902L26.7737 445.432Z"
          fill="#809AA7"
        />
        <path
          d="M27.2382 445.896L26.7737 445.432L26.3029 445.902L26.7675 446.367L27.2382 445.896Z"
          fill="#809AA7"
        />
        <path
          d="M27.7043 446.362L27.2383 445.896L26.7675 446.367L27.2336 446.833L27.7043 446.362Z"
          fill="#809AA7"
        />
        <path
          d="M28.1688 446.827L27.7042 446.362L27.2335 446.833L27.698 447.297L28.1688 446.827Z"
          fill="#809AA7"
        />
        <path
          d="M28.6348 447.293L28.1688 446.827L27.6981 447.297L28.1641 447.763L28.6348 447.293Z"
          fill="#809AA7"
        />
        <path
          d="M29.0994 447.757L28.6349 447.293L28.1642 447.763L28.6287 448.228L29.0994 447.757Z"
          fill="#F0FFFF"
        />
        <path
          d="M29.5639 448.222L29.0994 447.757L28.6286 448.228L29.0932 448.692L29.5639 448.222Z"
          fill="#809AA7"
        />
        <path
          d="M30.03 448.688L29.5654 448.223L29.0947 448.694L29.5592 449.159L30.03 448.688Z"
          fill="#809AA7"
        />
        <path
          d="M30.4944 449.152L30.0299 448.688L29.5592 449.159L30.0237 449.623L30.4944 449.152Z"
          fill="#809AA7"
        />
        <path
          d="M30.9605 449.618L30.496 449.154L30.0252 449.625L30.4898 450.089L30.9605 449.618Z"
          fill="#809AA7"
        />
        <path
          d="M31.425 450.083L30.9604 449.618L30.4897 450.089L30.9543 450.554L31.425 450.083Z"
          fill="#809AA7"
        />
        <path
          d="M31.8911 450.549L31.4265 450.084L30.9558 450.555L31.4203 451.02L31.8911 450.549Z"
          fill="#809AA7"
        />
        <path
          d="M32.3555 451.013L31.891 450.549L31.4203 451.02L31.8848 451.484L32.3555 451.013Z"
          fill="#809AA7"
        />
        <path
          d="M32.8216 451.479L32.3571 451.015L31.8863 451.486L32.3509 451.95L32.8216 451.479Z"
          fill="#809AA7"
        />
        <path
          d="M33.2862 451.944L32.8217 451.479L32.3509 451.95L32.8155 452.415L33.2862 451.944Z"
          fill="#809AA7"
        />
        <path
          d="M33.7522 452.41L33.2861 451.944L32.8154 452.415L33.2814 452.881L33.7522 452.41Z"
          fill="#809AA7"
        />
        <path
          d="M34.2167 452.875L33.7522 452.41L33.2815 452.881L33.746 453.345L34.2167 452.875Z"
          fill="#809AA7"
        />
        <path
          d="M34.6812 453.339L34.2167 452.875L33.7459 453.345L34.2105 453.81L34.6812 453.339Z"
          fill="#809AA7"
        />
        <path
          d="M35.1473 453.805L34.6827 453.341L34.212 453.811L34.6765 454.276L35.1473 453.805Z"
          fill="#809AA7"
        />
        <path
          d="M35.6118 454.27L35.1472 453.805L34.6765 454.276L35.141 454.74L35.6118 454.27Z"
          fill="#809AA7"
        />
        <path
          d="M36.0778 454.736L35.6133 454.271L35.1425 454.742L35.6071 455.206L36.0778 454.736Z"
          fill="#F0FFFF"
        />
        <path
          d="M36.5424 455.2L36.0779 454.736L35.6071 455.206L36.0717 455.671L36.5424 455.2Z"
          fill="#D0E1E9"
        />
        <path
          d="M37.0084 455.666L36.5438 455.202L36.0731 455.672L36.5376 456.137L37.0084 455.666Z"
          fill="#D0E1E9"
        />
        <path
          d="M37.473 456.131L37.0084 455.666L36.5377 456.137L37.0022 456.602L37.473 456.131Z"
          fill="#9AB0BB"
        />
        <path
          d="M37.9389 456.597L37.4744 456.132L37.0036 456.603L37.4682 457.068L37.9389 456.597Z"
          fill="#809AA7"
        />
        <path
          d="M39.334 457.992L38.8695 457.527L38.3988 457.998L38.8633 458.463L39.334 457.992Z"
          fill="#F0FFFF"
        />
        <path
          d="M21.6502 441.249L21.1857 440.785L20.7149 441.256L21.1795 441.72L21.6502 441.249Z"
          fill="#D0E1E9"
        />
        <path
          d="M22.5807 442.18L22.1162 441.716L21.6455 442.186L22.11 442.651L22.5807 442.18Z"
          fill="#F0FFFF"
        />
        <path
          d="M23.0452 442.645L22.5807 442.18L22.11 442.651L22.5745 443.115L23.0452 442.645Z"
          fill="#D0E1E9"
        />
        <path
          d="M23.5128 443.112L23.0468 442.646L22.576 443.117L23.042 443.583L23.5128 443.112Z"
          fill="#D0E1E9"
        />
        <path
          d="M23.9759 443.575L23.5114 443.111L23.0406 443.581L23.5052 444.046L23.9759 443.575Z"
          fill="#D0E1E9"
        />
        <path
          d="M24.4418 444.041L23.9773 443.577L23.5066 444.047L23.9711 444.512L24.4418 444.041Z"
          fill="#9AB0BB"
        />
        <path
          d="M24.9064 444.506L24.4419 444.041L23.9712 444.512L24.4357 444.976L24.9064 444.506Z"
          fill="#809AA7"
        />
        <path
          d="M25.3724 444.972L24.9078 444.507L24.4371 444.978L24.9016 445.442L25.3724 444.972Z"
          fill="#D0E1E9"
        />
        <path
          d="M25.837 445.436L25.3724 444.972L24.9017 445.442L25.3662 445.907L25.837 445.436Z"
          fill="#D0E1E9"
        />
        <path
          d="M26.3029 445.902L25.8384 445.438L25.3676 445.908L25.8322 446.373L26.3029 445.902Z"
          fill="#D0E1E9"
        />
        <path
          d="M26.7675 446.367L26.303 445.902L25.8322 446.373L26.2968 446.838L26.7675 446.367Z"
          fill="#D0E1E9"
        />
        <path
          d="M27.2335 446.833L26.7675 446.367L26.2967 446.838L26.7627 447.304L27.2335 446.833Z"
          fill="#D0E1E9"
        />
        <path
          d="M27.6981 447.297L27.2335 446.833L26.7628 447.304L27.2273 447.768L27.6981 447.297Z"
          fill="#D0E1E9"
        />
        <path
          d="M28.1641 447.763L27.6981 447.297L27.2274 447.768L27.6934 448.234L28.1641 447.763Z"
          fill="#9AB0BB"
        />
        <path
          d="M28.6286 448.228L28.1641 447.763L27.6933 448.234L28.1579 448.699L28.6286 448.228Z"
          fill="#F0FFFF"
        />
        <path
          d="M29.0932 448.692L28.6287 448.228L28.1579 448.699L28.6225 449.163L29.0932 448.692Z"
          fill="#D0E1E9"
        />
        <path
          d="M29.5591 449.159L29.0946 448.694L28.6239 449.165L29.0884 449.629L29.5591 449.159Z"
          fill="#D0E1E9"
        />
        <path
          d="M30.0237 449.623L29.5592 449.159L29.0885 449.629L29.553 450.094L30.0237 449.623Z"
          fill="#D0E1E9"
        />
        <path
          d="M30.4898 450.089L30.0253 449.625L29.5545 450.095L30.0191 450.56L30.4898 450.089Z"
          fill="#D0E1E9"
        />
        <path
          d="M30.9543 450.554L30.4897 450.089L30.019 450.56L30.4835 451.024L30.9543 450.554Z"
          fill="#D0E1E9"
        />
        <path
          d="M31.4203 451.02L30.9558 450.555L30.4851 451.026L30.9496 451.49L31.4203 451.02Z"
          fill="#D0E1E9"
        />
        <path
          d="M31.8848 451.484L31.4203 451.02L30.9496 451.49L31.4141 451.955L31.8848 451.484Z"
          fill="#809AA7"
        />
        <path
          d="M32.3509 451.95L31.8864 451.486L31.4156 451.956L31.8802 452.421L32.3509 451.95Z"
          fill="#9AB0BB"
        />
        <path
          d="M32.8154 452.415L32.3508 451.95L31.8801 452.421L32.3446 452.885L32.8154 452.415Z"
          fill="#D0E1E9"
        />
        <path
          d="M33.2815 452.881L32.8154 452.415L32.3447 452.885L32.8107 453.351L33.2815 452.881Z"
          fill="#D0E1E9"
        />
        <path
          d="M33.7459 453.345L33.2814 452.881L32.8106 453.351L33.2752 453.816L33.7459 453.345Z"
          fill="#D0E1E9"
        />
        <path
          d="M34.2105 453.81L33.746 453.345L33.2752 453.816L33.7398 454.281L34.2105 453.81Z"
          fill="#D0E1E9"
        />
        <path
          d="M34.6766 454.276L34.212 453.811L33.7413 454.282L34.2058 454.747L34.6766 454.276Z"
          fill="#D0E1E9"
        />
        <path
          d="M35.1411 454.74L34.6765 454.276L34.2058 454.747L34.6703 455.211L35.1411 454.74Z"
          fill="#D0E1E9"
        />
        <path
          d="M35.6071 455.206L35.1426 454.742L34.6718 455.213L35.1364 455.677L35.6071 455.206Z"
          fill="#F0FFFF"
        />
        <path
          d="M36.0716 455.671L35.6071 455.206L35.1363 455.677L35.6009 456.142L36.0716 455.671Z"
          fill="#D0E1E9"
        />
        <path
          d="M36.5377 456.137L36.0731 455.672L35.6024 456.143L36.0669 456.608L36.5377 456.137Z"
          fill="#D0E1E9"
        />
        <path
          d="M37.0021 456.602L36.5376 456.137L36.0669 456.608L36.5314 457.072L37.0021 456.602Z"
          fill="#D0E1E9"
        />
        <path
          d="M37.4682 457.068L37.0037 456.603L36.5329 457.074L36.9975 457.538L37.4682 457.068Z"
          fill="#9AB0BB"
        />
        <path
          d="M37.9328 457.532L37.4683 457.068L36.9975 457.538L37.4621 458.003L37.9328 457.532Z"
          fill="#809AA7"
        />
        <path
          d="M38.8633 458.463L38.3988 457.998L37.9281 458.469L38.3926 458.933L38.8633 458.463Z"
          fill="#D0E1E9"
        />
        <path
          d="M21.1779 441.722L20.7134 441.257L20.2426 441.728L20.7072 442.192L21.1779 441.722Z"
          fill="#D0E1E9"
        />
        <path
          d="M21.6425 442.186L21.178 441.722L20.7072 442.192L21.1718 442.657L21.6425 442.186Z"
          fill="#F0FFFF"
        />
        <path
          d="M22.1085 442.652L21.6439 442.188L21.1732 442.658L21.6377 443.123L22.1085 442.652Z"
          fill="#D0E1E9"
        />
        <path
          d="M22.5731 443.117L22.1085 442.652L21.6378 443.123L22.1023 443.588L22.5731 443.117Z"
          fill="#D0E1E9"
        />
        <path
          d="M23.0405 443.584L22.5745 443.118L22.1037 443.589L22.5697 444.055L23.0405 443.584Z"
          fill="#D0E1E9"
        />
        <path
          d="M23.5036 444.047L23.0391 443.583L22.5683 444.054L23.0329 444.518L23.5036 444.047Z"
          fill="#D0E1E9"
        />
        <path
          d="M23.9697 444.513L23.5051 444.049L23.0344 444.52L23.4989 444.984L23.9697 444.513Z"
          fill="#D0E1E9"
        />
        <path
          d="M24.4341 444.978L23.9696 444.513L23.4989 444.984L23.9634 445.449L24.4341 444.978Z"
          fill="#809AA7"
        />
        <path
          d="M24.9002 445.444L24.4357 444.979L23.9649 445.45L24.4295 445.915L24.9002 445.444Z"
          fill="#D0E1E9"
        />
        <path
          d="M25.3647 445.909L24.9001 445.444L24.4294 445.915L24.8939 446.379L25.3647 445.909Z"
          fill="#D0E1E9"
        />
        <path
          d="M25.8307 446.375L25.3662 445.91L24.8955 446.381L25.36 446.845L25.8307 446.375Z"
          fill="#D0E1E9"
        />
        <path
          d="M26.2952 446.839L25.8307 446.375L25.36 446.845L25.8245 447.31L26.2952 446.839Z"
          fill="#D0E1E9"
        />
        <path
          d="M26.7613 447.305L26.2953 446.839L25.8246 447.31L26.2906 447.776L26.7613 447.305Z"
          fill="#D0E1E9"
        />
        <path
          d="M27.2259 447.77L26.7614 447.305L26.2906 447.776L26.7552 448.24L27.2259 447.77Z"
          fill="#9AB0BB"
        />
        <path
          d="M27.6919 448.236L27.2258 447.77L26.7551 448.24L27.2211 448.706L27.6919 448.236Z"
          fill="#9AB0BB"
        />
        <path
          d="M28.1564 448.7L27.6919 448.236L27.2212 448.706L27.6857 449.171L28.1564 448.7Z"
          fill="#F0FFFF"
        />
        <path
          d="M28.6209 449.165L28.1564 448.7L27.6856 449.171L28.1502 449.635L28.6209 449.165Z"
          fill="#809AA7"
        />
        <path
          d="M29.087 449.631L28.6224 449.166L28.1517 449.637L28.6162 450.101L29.087 449.631Z"
          fill="#809AA7"
        />
        <path
          d="M29.5515 450.095L29.0869 449.631L28.6162 450.101L29.0807 450.566L29.5515 450.095Z"
          fill="#809AA7"
        />
        <path
          d="M30.0175 450.561L29.553 450.097L29.0822 450.568L29.5468 451.032L30.0175 450.561Z"
          fill="#809AA7"
        />
        <path
          d="M30.482 451.026L30.0175 450.561L29.5467 451.032L30.0113 451.497L30.482 451.026Z"
          fill="#809AA7"
        />
        <path
          d="M30.9481 451.492L30.4835 451.027L30.0128 451.498L30.4773 451.963L30.9481 451.492Z"
          fill="#809AA7"
        />
        <path
          d="M31.4127 451.956L30.9481 451.492L30.4774 451.963L30.9419 452.427L31.4127 451.956Z"
          fill="#809AA7"
        />
        <path
          d="M31.8786 452.422L31.4141 451.958L30.9433 452.429L31.4079 452.893L31.8786 452.422Z"
          fill="#9AB0BB"
        />
        <path
          d="M32.3432 452.887L31.8787 452.422L31.4079 452.893L31.8725 453.358L32.3432 452.887Z"
          fill="#9AB0BB"
        />
        <path
          d="M32.8092 453.353L32.3431 452.887L31.8724 453.358L32.3384 453.824L32.8092 453.353Z"
          fill="#D0E1E9"
        />
        <path
          d="M33.2737 453.818L32.8092 453.353L32.3385 453.824L32.803 454.288L33.2737 453.818Z"
          fill="#D0E1E9"
        />
        <path
          d="M33.7382 454.282L33.2737 453.818L32.8029 454.288L33.2675 454.753L33.7382 454.282Z"
          fill="#D0E1E9"
        />
        <path
          d="M34.2043 454.748L33.7397 454.284L33.269 454.754L33.7335 455.219L34.2043 454.748Z"
          fill="#D0E1E9"
        />
        <path
          d="M34.6689 455.213L34.2043 454.748L33.7336 455.219L34.1981 455.683L34.6689 455.213Z"
          fill="#D0E1E9"
        />
        <path
          d="M35.1348 455.679L34.6703 455.214L34.1996 455.685L34.6641 456.149L35.1348 455.679Z"
          fill="#F0FFFF"
        />
        <path
          d="M35.5994 456.143L35.1349 455.679L34.6642 456.149L35.1287 456.614L35.5994 456.143Z"
          fill="#D0E1E9"
        />
        <path
          d="M36.0654 456.609L35.6008 456.145L35.1301 456.615L35.5946 457.08L36.0654 456.609Z"
          fill="#D0E1E9"
        />
        <path
          d="M36.53 457.074L36.0654 456.609L35.5947 457.08L36.0592 457.544L36.53 457.074Z"
          fill="#D0E1E9"
        />
        <path
          d="M36.9959 457.54L36.5314 457.075L36.0606 457.546L36.5252 458.01L36.9959 457.54Z"
          fill="#D0E1E9"
        />
        <path
          d="M37.4605 458.004L36.996 457.54L36.5252 458.011L36.9898 458.475L37.4605 458.004Z"
          fill="#9AB0BB"
        />
        <path
          d="M37.925 458.469L37.4604 458.004L36.9897 458.475L37.4543 458.94L37.925 458.469Z"
          fill="#809AA7"
        />
        <path
          d="M38.3911 458.935L37.9265 458.47L37.4558 458.941L37.9203 459.406L38.3911 458.935Z"
          fill="#D0E1E9"
        />
        <path
          d="M20.7072 442.192L20.2427 441.728L19.7704 442.2L20.235 442.665L20.7072 442.192Z"
          fill="#D0E1E9"
        />
        <path
          d="M21.1717 442.657L20.7072 442.192L20.2349 442.665L20.6995 443.129L21.1717 442.657Z"
          fill="#809AA7"
        />
        <path
          d="M21.6378 443.123L21.1732 442.658L20.701 443.131L21.1655 443.595L21.6378 443.123Z"
          fill="#809AA7"
        />
        <path
          d="M22.1024 443.588L21.6378 443.123L21.1656 443.595L21.6301 444.06L22.1024 443.588Z"
          fill="#809AA7"
        />
        <path
          d="M22.5698 444.055L22.1038 443.589L21.6315 444.061L22.0975 444.527L22.5698 444.055Z"
          fill="#809AA7"
        />
        <path
          d="M23.0329 444.518L22.5684 444.054L22.0961 444.526L22.5607 444.99L23.0329 444.518Z"
          fill="#809AA7"
        />
        <path
          d="M23.4988 444.984L23.0343 444.52L22.5621 444.992L23.0266 445.456L23.4988 444.984Z"
          fill="#809AA7"
        />
        <path
          d="M23.9634 445.449L23.4989 444.984L23.0267 445.456L23.4912 445.921L23.9634 445.449Z"
          fill="#809AA7"
        />
        <path
          d="M24.4294 445.915L23.9648 445.45L23.4926 445.922L23.9571 446.387L24.4294 445.915Z"
          fill="#809AA7"
        />
        <path
          d="M24.894 446.379L24.4294 445.915L23.9572 446.387L24.4217 446.851L24.894 446.379Z"
          fill="#809AA7"
        />
        <path
          d="M25.36 446.845L24.8955 446.381L24.4233 446.853L24.8878 447.317L25.36 446.845Z"
          fill="#809AA7"
        />
        <path
          d="M25.8245 447.31L25.36 446.845L24.8877 447.317L25.3523 447.782L25.8245 447.31Z"
          fill="#809AA7"
        />
        <path
          d="M26.2906 447.776L25.8246 447.31L25.3523 447.782L25.8184 448.248L26.2906 447.776Z"
          fill="#809AA7"
        />
        <path
          d="M26.7551 448.24L26.2905 447.776L25.8183 448.248L26.2828 448.713L26.7551 448.24Z"
          fill="#809AA7"
        />
        <path
          d="M28.1502 449.635L27.6857 449.171L27.2134 449.643L27.678 450.108L28.1502 449.635Z"
          fill="#373B3E"
        />
        <path
          d="M28.6163 450.101L28.1517 449.637L27.6795 450.109L28.144 450.574L28.6163 450.101Z"
          fill="#43484B"
        />
        <path
          d="M29.0807 450.566L28.6162 450.101L28.144 450.574L28.6085 451.038L29.0807 450.566Z"
          fill="#43484B"
        />
        <path
          d="M29.5468 451.032L29.0823 450.568L28.61 451.04L29.0746 451.504L29.5468 451.032Z"
          fill="#373B3E"
        />
        <path
          d="M30.0113 451.497L29.5468 451.032L29.0745 451.504L29.5391 451.969L30.0113 451.497Z"
          fill="#26282A"
        />
        <path
          d="M30.4774 451.963L30.0128 451.498L29.5406 451.97L30.0051 452.435L30.4774 451.963Z"
          fill="#26282A"
        />
        <path
          d="M31.8724 453.358L31.4078 452.893L30.9356 453.365L31.4001 453.83L31.8724 453.358Z"
          fill="#809AA7"
        />
        <path
          d="M32.3385 453.824L31.8724 453.358L31.4002 453.83L31.8662 454.296L32.3385 453.824Z"
          fill="#809AA7"
        />
        <path
          d="M32.803 454.288L32.3385 453.824L31.8663 454.296L32.3308 454.761L32.803 454.288Z"
          fill="#809AA7"
        />
        <path
          d="M33.2675 454.753L32.803 454.288L32.3307 454.76L32.7953 455.225L33.2675 454.753Z"
          fill="#809AA7"
        />
        <path
          d="M33.7336 455.219L33.269 454.754L32.7968 455.227L33.2613 455.691L33.7336 455.219Z"
          fill="#809AA7"
        />
        <path
          d="M34.1981 455.683L33.7335 455.219L33.2613 455.691L33.7258 456.156L34.1981 455.683Z"
          fill="#809AA7"
        />
        <path
          d="M34.6641 456.149L34.1996 455.685L33.7273 456.157L34.1919 456.622L34.6641 456.149Z"
          fill="#809AA7"
        />
        <path
          d="M35.1286 456.614L34.6641 456.149L34.1918 456.622L34.6564 457.086L35.1286 456.614Z"
          fill="#809AA7"
        />
        <path
          d="M35.5947 457.08L35.1301 456.615L34.6579 457.088L35.1224 457.552L35.5947 457.08Z"
          fill="#809AA7"
        />
        <path
          d="M36.0593 457.544L35.5947 457.08L35.1225 457.552L35.587 458.017L36.0593 457.544Z"
          fill="#809AA7"
        />
        <path
          d="M36.5252 458.01L36.0607 457.546L35.5884 458.018L36.053 458.483L36.5252 458.01Z"
          fill="#809AA7"
        />
        <path
          d="M36.9898 458.475L36.5253 458.01L36.053 458.483L36.5176 458.947L36.9898 458.475Z"
          fill="#809AA7"
        />
        <path
          d="M37.4543 458.94L36.9897 458.475L36.5175 458.947L36.982 459.412L37.4543 458.94Z"
          fill="#809AA7"
        />
        <path
          d="M37.9203 459.406L37.4558 458.941L36.9836 459.413L37.4481 459.878L37.9203 459.406Z"
          fill="#D0E1E9"
        />
        <path
          d="M20.2349 442.665L19.7704 442.2L19.2996 442.671L19.7642 443.135L20.2349 442.665Z"
          fill="#809AA7"
        />
        <path
          d="M22.5606 444.99L22.0961 444.526L21.6253 444.997L22.0899 445.461L22.5606 444.99Z"
          fill="#373B3E"
        />
        <path
          d="M23.0267 445.456L22.5621 444.992L22.0914 445.463L22.5559 445.927L23.0267 445.456Z"
          fill="#43484B"
        />
        <path
          d="M23.4911 445.921L23.0266 445.456L22.5559 445.927L23.0204 446.392L23.4911 445.921Z"
          fill="#43484B"
        />
        <path
          d="M23.9572 446.387L23.4927 445.922L23.0219 446.393L23.4865 446.858L23.9572 446.387Z"
          fill="#373B3E"
        />
        <path
          d="M24.4217 446.851L23.9572 446.387L23.4864 446.858L23.951 447.322L24.4217 446.851Z"
          fill="#373B3E"
        />
        <path
          d="M24.8878 447.318L24.4232 446.853L23.9525 447.324L24.417 447.788L24.8878 447.318Z"
          fill="#26282A"
        />
        <path
          d="M25.3522 447.782L24.8877 447.318L24.417 447.788L24.8815 448.253L25.3522 447.782Z"
          fill="#26282A"
        />
        <path
          d="M28.144 450.574L27.6794 450.109L27.2087 450.58L27.6732 451.044L28.144 450.574Z"
          fill="#373B3E"
        />
        <path
          d="M28.6085 451.038L28.1439 450.574L27.6732 451.044L28.1377 451.509L28.6085 451.038Z"
          fill="#43484B"
        />
        <path
          d="M29.0745 451.504L28.61 451.04L28.1392 451.51L28.6038 451.975L29.0745 451.504Z"
          fill="#373B3E"
        />
        <path
          d="M29.5391 451.969L29.0746 451.504L28.6038 451.975L29.0684 452.44L29.5391 451.969Z"
          fill="#26282A"
        />
        <path
          d="M31.8662 454.296L31.4001 453.83L30.9294 454.301L31.3954 454.767L31.8662 454.296Z"
          fill="#373B3E"
        />
        <path
          d="M32.3307 454.76L31.8662 454.296L31.3955 454.767L31.86 455.231L32.3307 454.76Z"
          fill="#43484B"
        />
        <path
          d="M32.7953 455.225L32.3308 454.76L31.8601 455.231L32.3246 455.696L32.7953 455.225Z"
          fill="#43484B"
        />
        <path
          d="M33.2613 455.691L32.7968 455.227L32.326 455.697L32.7906 456.162L33.2613 455.691Z"
          fill="#373B3E"
        />
        <path
          d="M33.7259 456.156L33.2614 455.691L32.7906 456.162L33.2552 456.626L33.7259 456.156Z"
          fill="#373B3E"
        />
        <path
          d="M34.1918 456.622L33.7273 456.157L33.2566 456.628L33.7211 457.092L34.1918 456.622Z"
          fill="#26282A"
        />
        <path
          d="M34.6564 457.086L34.1919 456.622L33.7212 457.092L34.1857 457.557L34.6564 457.086Z"
          fill="#26282A"
        />
        <path
          d="M37.4481 459.878L36.9835 459.413L36.5128 459.884L36.9773 460.349L37.4481 459.878Z"
          fill="#809AA7"
        />
        <path
          d="M22.5558 445.927L22.0913 445.463L21.6206 445.933L22.0851 446.398L22.5558 445.927Z"
          fill="#373B3E"
        />
        <path
          d="M23.0204 446.392L22.5559 445.927L22.0852 446.398L22.5497 446.862L23.0204 446.392Z"
          fill="#43484B"
        />
        <path
          d="M23.4865 446.858L23.022 446.393L22.5512 446.864L23.0158 447.328L23.4865 446.858Z"
          fill="#373B3E"
        />
        <path
          d="M23.951 447.322L23.4865 446.858L23.0157 447.328L23.4803 447.793L23.951 447.322Z"
          fill="#373B3E"
        />
        <path
          d="M24.4171 447.788L23.9525 447.324L23.4818 447.794L23.9463 448.259L24.4171 447.788Z"
          fill="#26282A"
        />
        <path
          d="M27.6733 451.044L27.2087 450.58L26.738 451.051L27.2025 451.515L27.6733 451.044Z"
          fill="#FFCA34"
        />
        <path
          d="M28.1378 451.509L27.6732 451.044L27.2025 451.515L27.667 451.98L28.1378 451.509Z"
          fill="#FFAD32"
        />
        <path
          d="M28.6038 451.975L28.1393 451.51L27.6685 451.981L28.1331 452.446L28.6038 451.975Z"
          fill="#FFCA34"
        />
        <path
          d="M29.0683 452.44L28.6038 451.975L28.133 452.446L28.5976 452.91L29.0683 452.44Z"
          fill="#FFCA34"
        />
        <path
          d="M31.86 455.231L31.3955 454.767L30.9248 455.237L31.3893 455.702L31.86 455.231Z"
          fill="#373B3E"
        />
        <path
          d="M32.3245 455.696L31.86 455.231L31.3892 455.702L31.8538 456.167L32.3245 455.696Z"
          fill="#43484B"
        />
        <path
          d="M32.7906 456.162L32.326 455.697L31.8553 456.168L32.3199 456.633L32.7906 456.162Z"
          fill="#373B3E"
        />
        <path
          d="M33.2551 456.626L32.7905 456.162L32.3198 456.633L32.7843 457.097L33.2551 456.626Z"
          fill="#373B3E"
        />
        <path
          d="M33.7211 457.092L33.2566 456.628L32.7859 457.099L33.2504 457.563L33.7211 457.092Z"
          fill="#26282A"
        />
        <path
          d="M22.0837 446.399L21.6191 445.935L21.1484 446.406L21.6129 446.87L22.0837 446.399Z"
          fill="#FFCA34"
        />
        <path
          d="M22.5482 446.864L22.0836 446.399L21.6129 446.87L22.0774 447.335L22.5482 446.864Z"
          fill="#FFAD32"
        />
        <path
          d="M23.0142 447.33L22.5497 446.865L22.0789 447.336L22.5435 447.801L23.0142 447.33Z"
          fill="#FFCA34"
        />
        <path
          d="M23.4787 447.794L23.0142 447.33L22.5434 447.801L23.008 448.265L23.4787 447.794Z"
          fill="#FFAD32"
        />
        <path
          d="M23.9448 448.26L23.4802 447.796L23.0095 448.267L23.474 448.731L23.9448 448.26Z"
          fill="#FFCA34"
        />
        <path
          d="M27.201 451.517L26.7365 451.052L26.2657 451.523L26.7303 451.987L27.201 451.517Z"
          fill="#FFAD32"
        />
        <path
          d="M27.6656 451.981L27.201 451.517L26.7303 451.987L27.1949 452.452L27.6656 451.981Z"
          fill="#F7852F"
        />
        <path
          d="M28.1315 452.447L27.667 451.983L27.1963 452.453L27.6608 452.918L28.1315 452.447Z"
          fill="#FFAD32"
        />
        <path
          d="M28.5961 452.912L28.1316 452.447L27.6609 452.918L28.1254 453.383L28.5961 452.912Z"
          fill="#FFAD32"
        />
        <path
          d="M31.3878 455.703L30.9232 455.239L30.4525 455.71L30.917 456.174L31.3878 455.703Z"
          fill="#FFCA34"
        />
        <path
          d="M31.8524 456.168L31.3878 455.703L30.9171 456.174L31.3816 456.639L31.8524 456.168Z"
          fill="#FFAD32"
        />
        <path
          d="M32.3183 456.634L31.8538 456.169L31.383 456.64L31.8476 457.105L32.3183 456.634Z"
          fill="#FFCA34"
        />
        <path
          d="M32.7829 457.099L32.3184 456.634L31.8476 457.105L32.3122 457.569L32.7829 457.099Z"
          fill="#FFAD32"
        />
        <path
          d="M33.2488 457.565L32.7843 457.1L32.3136 457.571L32.7781 458.035L33.2488 457.565Z"
          fill="#FFCA34"
        />
        <path
          d="M21.613 446.87L21.1484 446.406L20.6762 446.878L21.1407 447.342L21.613 446.87Z"
          fill="#FFAD32"
        />
        <path
          d="M22.0775 447.335L21.6129 446.87L21.1407 447.342L21.6052 447.807L22.0775 447.335Z"
          fill="#F7852F"
        />
        <path
          d="M22.5435 447.801L22.079 447.336L21.6067 447.808L22.0713 448.273L22.5435 447.801Z"
          fill="#FFAD32"
        />
        <path
          d="M23.008 448.265L22.5435 447.801L22.0712 448.273L22.5358 448.737L23.008 448.265Z"
          fill="#F7852F"
        />
        <path
          d="M23.4741 448.731L23.0095 448.267L22.5373 448.739L23.0018 449.203L23.4741 448.731Z"
          fill="#FFAD32"
        />
        <path
          d="M26.7303 451.987L26.2657 451.523L25.7935 451.995L26.258 452.46L26.7303 451.987Z"
          fill="#F7852F"
        />
        <path
          d="M27.1948 452.452L26.7302 451.987L26.258 452.46L26.7225 452.924L27.1948 452.452Z"
          fill="#FFAD32"
        />
        <path
          d="M27.6608 452.918L27.1963 452.453L26.724 452.926L27.1886 453.39L27.6608 452.918Z"
          fill="#D8531A"
        />
        <path
          d="M28.1253 453.383L27.6608 452.918L27.1885 453.39L27.6531 453.855L28.1253 453.383Z"
          fill="#F7852F"
        />
        <path
          d="M30.9171 456.174L30.4525 455.71L29.9803 456.182L30.4448 456.646L30.9171 456.174Z"
          fill="#FFAD32"
        />
        <path
          d="M31.3815 456.639L30.917 456.174L30.4448 456.646L30.9093 457.111L31.3815 456.639Z"
          fill="#F7852F"
        />
        <path
          d="M31.8476 457.105L31.3831 456.64L30.9108 457.112L31.3754 457.577L31.8476 457.105Z"
          fill="#FFAD32"
        />
        <path
          d="M32.3122 457.569L31.8477 457.105L31.3754 457.577L31.84 458.042L32.3122 457.569Z"
          fill="#F7852F"
        />
        <path
          d="M32.7781 458.035L32.3136 457.571L31.8414 458.043L32.3059 458.508L32.7781 458.035Z"
          fill="#FFAD32"
        />
        <path
          d="M21.1407 447.342L20.6761 446.878L20.2054 447.349L20.6699 447.813L21.1407 447.342Z"
          fill="#F7852F"
        />
        <path
          d="M21.6052 447.807L21.1406 447.342L20.6699 447.813L21.1344 448.278L21.6052 447.807Z"
          fill="#FFAD32"
        />
        <path
          d="M22.0712 448.273L21.6067 447.808L21.136 448.279L21.6005 448.744L22.0712 448.273Z"
          fill="#D8531A"
        />
        <path
          d="M22.5358 448.737L22.0713 448.273L21.6006 448.744L22.0651 449.208L22.5358 448.737Z"
          fill="#FFAD32"
        />
        <path
          d="M23.0018 449.203L22.5372 448.739L22.0665 449.21L22.531 449.674L23.0018 449.203Z"
          fill="#F7852F"
        />
        <path
          d="M26.7226 452.924L26.2581 452.46L25.7873 452.93L26.2519 453.395L26.7226 452.924Z"
          fill="#D8531A"
        />
        <path
          d="M27.1885 453.39L26.724 452.926L26.2533 453.396L26.7178 453.861L27.1885 453.39Z"
          fill="#F7852F"
        />
        <path
          d="M27.6531 453.855L27.1886 453.39L26.7179 453.861L27.1824 454.326L27.6531 453.855Z"
          fill="#F7852F"
        />
        <path
          d="M30.4448 456.646L29.9802 456.182L29.5095 456.653L29.974 457.117L30.4448 456.646Z"
          fill="#F7852F"
        />
        <path
          d="M30.9094 457.111L30.4448 456.646L29.9741 457.117L30.4386 457.582L30.9094 457.111Z"
          fill="#FFAD32"
        />
        <path
          d="M31.3753 457.577L30.9108 457.112L30.44 457.583L30.9046 458.048L31.3753 457.577Z"
          fill="#D8531A"
        />
        <path
          d="M31.8399 458.042L31.3754 457.577L30.9046 458.048L31.3692 458.512L31.8399 458.042Z"
          fill="#FFAD32"
        />
        <path
          d="M32.306 458.508L31.8414 458.043L31.3707 458.514L31.8352 458.978L32.306 458.508Z"
          fill="#F7852F"
        />
        <path
          d="M21.1345 448.278L20.6699 447.813L20.1992 448.284L20.6637 448.748L21.1345 448.278Z"
          fill="#D8531A"
        />
        <path
          d="M21.6005 448.744L21.136 448.279L20.6653 448.75L21.1298 449.214L21.6005 448.744Z"
          fill="#F7852F"
        />
        <path
          d="M22.065 449.208L21.6005 448.744L21.1297 449.214L21.5943 449.679L22.065 449.208Z"
          fill="#D8531A"
        />
        <path
          d="M26.2518 453.395L25.7872 452.93L25.3165 453.401L25.781 453.866L26.2518 453.395Z"
          fill="#D8531A"
        />
        <path
          d="M26.7178 453.861L26.2533 453.396L25.7826 453.867L26.2471 454.332L26.7178 453.861Z"
          fill="#D8531A"
        />
        <path
          d="M30.4387 457.582L29.9741 457.117L29.5034 457.588L29.9679 458.052L30.4387 457.582Z"
          fill="#D8531A"
        />
        <path
          d="M30.9046 458.048L30.4401 457.583L29.9693 458.054L30.4339 458.518L30.9046 458.048Z"
          fill="#F7852F"
        />
        <path
          d="M31.3692 458.512L30.9047 458.048L30.4339 458.518L30.8985 458.983L31.3692 458.512Z"
          fill="#D8531A"
        />
        <path
          d="M20.6623 448.75L20.1978 448.285L19.727 448.756L20.1916 449.221L20.6623 448.75Z"
          fill="#D8531A"
        />
        <path
          d="M21.1282 449.216L20.6637 448.751L20.193 449.222L20.6575 449.687L21.1282 449.216Z"
          fill="#D8531A"
        />
        <path
          d="M21.5928 449.68L21.1283 449.216L20.6576 449.687L21.1221 450.151L21.5928 449.68Z"
          fill="#D8531A"
        />
        <path
          d="M26.2457 454.333L25.7811 453.869L25.3104 454.339L25.7749 454.804L26.2457 454.333Z"
          fill="#D8531A"
        />
        <path
          d="M29.9664 458.054L29.5018 457.589L29.0311 458.06L29.4956 458.525L29.9664 458.054Z"
          fill="#D8531A"
        />
        <path
          d="M30.4324 458.52L29.9679 458.055L29.4972 458.526L29.9617 458.991L30.4324 458.52Z"
          fill="#D8531A"
        />
        <path
          d="M30.8969 458.985L30.4324 458.52L29.9616 458.991L30.4262 459.455L30.8969 458.985Z"
          fill="#D8531A"
        />
        <path
          d="M20.6575 449.687L20.193 449.222L19.7223 449.693L20.1868 450.157L20.6575 449.687Z"
          fill="#D8531A"
        />
        <path
          d="M29.9616 458.991L29.4971 458.526L29.0263 458.997L29.4909 459.461L29.9616 458.991Z"
          fill="#D8531A"
        />
      </g>
      <g opacity="0.2" clipPath="url(#clip8_0_1)">
        <path
          d="M104.681 160.457L104.065 159.67L103.276 160.288L103.892 161.075L104.681 160.457Z"
          fill="#F0FFFF"
        />
        <path
          d="M105.297 161.244L104.681 160.457L103.892 161.075L104.508 161.862L105.297 161.244Z"
          fill="#809AA7"
        />
        <path
          d="M103.276 160.288L102.66 159.501L101.873 160.117L102.489 160.904L103.276 160.288Z"
          fill="#F0FFFF"
        />
        <path
          d="M103.892 161.075L103.276 160.288L102.489 160.904L103.105 161.691L103.892 161.075Z"
          fill="#D0E1E9"
        />
        <path
          d="M104.507 161.862L103.892 161.075L103.105 161.691L103.72 162.478L104.507 161.862Z"
          fill="#D0E1E9"
        />
        <path
          d="M105.125 162.652L104.51 161.865L103.722 162.481L104.338 163.268L105.125 162.652Z"
          fill="#809AA7"
        />
        <path
          d="M100.021 157.751L99.4054 156.964L98.6158 157.581L99.2317 158.369L100.021 157.751Z"
          fill="#373B3E"
        />
        <path
          d="M100.637 158.538L100.021 157.751L99.2318 158.369L99.8476 159.156L100.637 158.538Z"
          fill="#26282A"
        />
        <path
          d="M102.489 160.904L101.873 160.117L101.083 160.735L101.699 161.522L102.489 160.904Z"
          fill="#F0FFFF"
        />
        <path
          d="M103.105 161.691L102.489 160.904L101.699 161.522L102.315 162.309L103.105 161.691Z"
          fill="#D0E1E9"
        />
        <path
          d="M103.72 162.478L103.105 161.691L102.315 162.309L102.931 163.096L103.72 162.478Z"
          fill="#D0E1E9"
        />
        <path
          d="M104.338 163.268L103.722 162.481L102.933 163.098L103.549 163.886L104.338 163.268Z"
          fill="#809AA7"
        />
        <path
          d="M106.19 165.634L105.572 164.844L104.782 165.462L105.4 166.252L106.19 165.634Z"
          fill="#373B3E"
        />
        <path
          d="M106.806 166.421L106.19 165.634L105.4 166.252L106.016 167.039L106.806 166.421Z"
          fill="#26282A"
        />
        <path
          d="M96.7669 155.213L96.151 154.426L95.3639 155.042L95.9798 155.829L96.7669 155.213Z"
          fill="#373B3E"
        />
        <path
          d="M99.2343 158.367L98.6184 157.58L97.8313 158.195L98.4472 158.982L99.2343 158.367Z"
          fill="#43484B"
        />
        <path
          d="M99.8501 159.154L99.2343 158.367L98.4472 158.982L99.0631 159.77L99.8501 159.154Z"
          fill="#373B3E"
        />
        <path
          d="M101.084 160.73L100.468 159.943L99.6808 160.559L100.297 161.346L101.084 160.73Z"
          fill="#F0FFFF"
        />
        <path
          d="M101.702 161.52L101.086 160.733L100.299 161.349L100.915 162.136L101.702 161.52Z"
          fill="#D0E1E9"
        />
        <path
          d="M102.318 162.307L101.702 161.52L100.915 162.136L101.53 162.923L102.318 162.307Z"
          fill="#F0FFFF"
        />
        <path
          d="M102.933 163.094L102.318 162.307L101.53 162.923L102.146 163.71L102.933 163.094Z"
          fill="#F0FFFF"
        />
        <path
          d="M103.551 163.884L102.935 163.096L102.148 163.712L102.764 164.499L103.551 163.884Z"
          fill="#D0E1E9"
        />
        <path
          d="M104.167 164.671L103.551 163.884L102.764 164.499L103.38 165.287L104.167 164.671Z"
          fill="#809AA7"
        />
        <path
          d="M105.403 166.25L104.785 165.46L103.998 166.076L104.616 166.866L105.403 166.25Z"
          fill="#43484B"
        />
        <path
          d="M106.019 167.037L105.403 166.25L104.616 166.866L105.232 167.653L106.019 167.037Z"
          fill="#373B3E"
        />
        <path
          d="M108.486 170.19L107.87 169.403L107.083 170.019L107.699 170.806L108.486 170.19Z"
          fill="#373B3E"
        />
        <path
          d="M95.9773 155.831L95.3615 155.044L94.5744 155.66L95.1902 156.447L95.9773 155.831Z"
          fill="#43484B"
        />
        <path
          d="M98.4447 158.984L97.8289 158.197L97.0418 158.813L97.6577 159.6L98.4447 158.984Z"
          fill="#373B3E"
        />
        <path
          d="M99.0606 159.771L98.4447 158.984L97.6576 159.6L98.2735 160.387L99.0606 159.771Z"
          fill="#26282A"
        />
        <path
          d="M100.294 161.348L99.6783 160.561L98.8913 161.177L99.5071 161.964L100.294 161.348Z"
          fill="#F0FFFF"
        />
        <path
          d="M100.912 162.138L100.296 161.351L99.5092 161.967L100.125 162.754L100.912 162.138Z"
          fill="#F0FFFF"
        />
        <path
          d="M101.528 162.925L100.912 162.138L100.125 162.754L100.741 163.541L101.528 162.925Z"
          fill="#394B53"
        />
        <path
          d="M102.144 163.712L101.528 162.925L100.741 163.541L101.357 164.328L102.144 163.712Z"
          fill="#394B53"
        />
        <path
          d="M102.762 164.501L102.146 163.714L101.359 164.33L101.975 165.117L102.762 164.501Z"
          fill="#F0FFFF"
        />
        <path
          d="M103.377 165.288L102.762 164.501L101.975 165.117L102.59 165.904L103.377 165.288Z"
          fill="#809AA7"
        />
        <path
          d="M104.613 166.868L103.995 166.078L103.208 166.694L103.826 167.484L104.613 166.868Z"
          fill="#373B3E"
        />
        <path
          d="M105.229 167.655L104.613 166.868L103.826 167.483L104.442 168.271L105.229 167.655Z"
          fill="#26282A"
        />
        <path
          d="M107.696 170.808L107.081 170.021L106.294 170.637L106.909 171.424L107.696 170.808Z"
          fill="#43484B"
        />
        <path
          d="M95.1902 156.447L94.5743 155.66L93.7873 156.276L94.4031 157.063L95.1902 156.447Z"
          fill="#373B3E"
        />
        <path
          d="M97.6576 159.6L97.0417 158.813L96.2547 159.429L96.8705 160.216L97.6576 159.6Z"
          fill="#373B3E"
        />
        <path
          d="M98.2735 160.387L97.6576 159.6L96.8705 160.216L97.4864 161.003L98.2735 160.387Z"
          fill="#26282A"
        />
        <path
          d="M99.5072 161.964L98.8914 161.177L98.1043 161.793L98.7202 162.58L99.5072 161.964Z"
          fill="#F0FFFF"
        />
        <path
          d="M100.125 162.754L99.5092 161.966L98.7221 162.582L99.338 163.369L100.125 162.754Z"
          fill="#394B53"
        />
        <path
          d="M100.741 163.541L100.125 162.754L99.3379 163.369L99.9538 164.156L100.741 163.541Z"
          fill="#51DFF2"
        />
        <path
          d="M101.357 164.328L100.741 163.541L99.9538 164.157L100.57 164.944L101.357 164.328Z"
          fill="#05B8CF"
        />
        <path
          d="M101.975 165.117L101.359 164.33L100.572 164.946L101.188 165.733L101.975 165.117Z"
          fill="#394B53"
        />
        <path
          d="M102.59 165.904L101.975 165.117L101.188 165.733L101.803 166.52L102.59 165.904Z"
          fill="#809AA7"
        />
        <path
          d="M103.826 167.483L103.208 166.694L102.421 167.31L103.039 168.099L103.826 167.483Z"
          fill="#373B3E"
        />
        <path
          d="M104.442 168.271L103.826 167.484L103.039 168.099L103.655 168.886L104.442 168.271Z"
          fill="#26282A"
        />
        <path
          d="M106.909 171.424L106.293 170.637L105.506 171.253L106.122 172.04L106.909 171.424Z"
          fill="#373B3E"
        />
        <path
          d="M93.7829 156.275L93.167 155.488L92.3799 156.104L92.9958 156.891L93.7829 156.275Z"
          fill="#F0FFFF"
        />
        <path
          d="M94.4007 157.065L93.7848 156.278L92.9977 156.894L93.6136 157.681L94.4007 157.065Z"
          fill="#373B3E"
        />
        <path
          d="M95.0165 157.852L94.4006 157.065L93.6136 157.681L94.2294 158.468L95.0165 157.852Z"
          fill="#9AB0BB"
        />
        <path
          d="M96.8681 160.218L96.2522 159.431L95.4651 160.047L96.081 160.834L96.8681 160.218Z"
          fill="#373B3E"
        />
        <path
          d="M97.4839 161.005L96.868 160.218L96.081 160.834L96.6968 161.621L97.4839 161.005Z"
          fill="#26282A"
        />
        <path
          d="M98.7176 162.582L98.1017 161.795L97.3146 162.411L97.9305 163.198L98.7176 162.582Z"
          fill="#F0FFFF"
        />
        <path
          d="M99.3355 163.371L98.7196 162.584L97.9325 163.2L98.5484 163.987L99.3355 163.371Z"
          fill="#394B53"
        />
        <path
          d="M99.9513 164.158L99.3354 163.371L98.5484 163.987L99.1642 164.774L99.9513 164.158Z"
          fill="#05B8CF"
        />
        <path
          d="M100.567 164.946L99.9513 164.158L99.1642 164.774L99.7801 165.561L100.567 164.946Z"
          fill="#058499"
        />
        <path
          d="M101.185 165.735L100.569 164.948L99.782 165.564L100.398 166.351L101.185 165.735Z"
          fill="#394B53"
        />
        <path
          d="M101.801 166.522L101.185 165.735L100.398 166.351L101.014 167.138L101.801 166.522Z"
          fill="#809AA7"
        />
        <path
          d="M103.037 168.101L102.419 167.312L101.632 167.928L102.249 168.717L103.037 168.101Z"
          fill="#373B3E"
        />
        <path
          d="M103.652 168.888L103.036 168.101L102.249 168.717L102.865 169.504L103.652 168.888Z"
          fill="#26282A"
        />
        <path
          d="M105.502 171.252L104.886 170.465L104.099 171.081L104.715 171.868L105.502 171.252Z"
          fill="#F0FFFF"
        />
        <path
          d="M106.12 172.042L105.504 171.255L104.717 171.871L105.333 172.658L106.12 172.042Z"
          fill="#373B3E"
        />
        <path
          d="M106.736 172.829L106.12 172.042L105.333 172.658L105.949 173.445L106.736 172.829Z"
          fill="#9AB0BB"
        />
        <path
          d="M92.9957 156.891L92.3799 156.104L91.5928 156.72L92.2087 157.507L92.9957 156.891Z"
          fill="#D0E1E9"
        />
        <path
          d="M93.6135 157.681L92.9977 156.894L92.2106 157.509L92.8265 158.297L93.6135 157.681Z"
          fill="#D0E1E9"
        />
        <path
          d="M94.2294 158.468L93.6135 157.681L92.8265 158.297L93.4423 159.084L94.2294 158.468Z"
          fill="#809AA7"
        />
        <path
          d="M96.081 160.834L95.4651 160.047L94.678 160.663L95.2939 161.45L96.081 160.834Z"
          fill="#373B3E"
        />
        <path
          d="M96.6968 161.621L96.0809 160.834L95.2939 161.45L95.9097 162.237L96.6968 161.621Z"
          fill="#26282A"
        />
        <path
          d="M97.9306 163.198L97.3147 162.411L96.5276 163.027L97.1435 163.814L97.9306 163.198Z"
          fill="#F0FFFF"
        />
        <path
          d="M98.5484 163.987L97.9325 163.2L97.1454 163.816L97.7613 164.603L98.5484 163.987Z"
          fill="#394B53"
        />
        <path
          d="M99.1642 164.774L98.5483 163.987L97.7613 164.603L98.3771 165.39L99.1642 164.774Z"
          fill="#51DFF2"
        />
        <path
          d="M99.7801 165.561L99.1642 164.774L98.3771 165.39L98.993 166.177L99.7801 165.561Z"
          fill="#05B8CF"
        />
        <path
          d="M100.398 166.351L99.7821 165.564L98.995 166.18L99.6109 166.967L100.398 166.351Z"
          fill="#394B53"
        />
        <path
          d="M101.014 167.138L100.398 166.351L99.6109 166.967L100.227 167.754L101.014 167.138Z"
          fill="#809AA7"
        />
        <path
          d="M102.249 168.717L101.632 167.928L100.845 168.543L101.462 169.333L102.249 168.717Z"
          fill="#373B3E"
        />
        <path
          d="M102.865 169.504L102.25 168.717L101.462 169.333L102.078 170.12L102.865 169.504Z"
          fill="#26282A"
        />
        <path
          d="M104.715 171.868L104.099 171.081L103.312 171.697L103.928 172.484L104.715 171.868Z"
          fill="#D0E1E9"
        />
        <path
          d="M105.333 172.658L104.717 171.87L103.93 172.486L104.546 173.273L105.333 172.658Z"
          fill="#D0E1E9"
        />
        <path
          d="M105.949 173.445L105.333 172.658L104.546 173.273L105.162 174.06L105.949 173.445Z"
          fill="#809AA7"
        />
        <path
          d="M91.5902 156.722L90.9744 155.935L90.1873 156.551L90.8032 157.338L91.5902 156.722Z"
          fill="#809AA7"
        />
        <path
          d="M92.2062 157.509L91.5903 156.722L90.8033 157.338L91.4191 158.125L92.2062 157.509Z"
          fill="#809AA7"
        />
        <path
          d="M92.824 158.298L92.2081 157.511L91.4211 158.127L92.0369 158.914L92.824 158.298Z"
          fill="#809AA7"
        />
        <path
          d="M93.4398 159.086L92.824 158.299L92.0369 158.914L92.6528 159.701L93.4398 159.086Z"
          fill="#809AA7"
        />
        <path
          d="M94.0576 159.875L93.4418 159.088L92.6547 159.704L93.2706 160.491L94.0576 159.875Z"
          fill="#809AA7"
        />
        <path
          d="M95.2914 161.452L94.6755 160.665L93.8885 161.281L94.5043 162.068L95.2914 161.452Z"
          fill="#373B3E"
        />
        <path
          d="M95.9072 162.239L95.2914 161.452L94.5043 162.068L95.1202 162.855L95.9072 162.239Z"
          fill="#26282A"
        />
        <path
          d="M97.1409 163.816L96.525 163.028L95.738 163.644L96.3538 164.431L97.1409 163.816Z"
          fill="#F0FFFF"
        />
        <path
          d="M97.7588 164.605L97.1429 163.818L96.3559 164.434L96.9717 165.221L97.7588 164.605Z"
          fill="#394B53"
        />
        <path
          d="M98.3747 165.392L97.7588 164.605L96.9717 165.221L97.5876 166.008L98.3747 165.392Z"
          fill="#05B8CF"
        />
        <path
          d="M98.9905 166.179L98.3746 165.392L97.5876 166.008L98.2034 166.795L98.9905 166.179Z"
          fill="#058499"
        />
        <path
          d="M99.6083 166.969L98.9924 166.182L98.2054 166.798L98.8212 167.585L99.6083 166.969Z"
          fill="#394B53"
        />
        <path
          d="M100.224 167.756L99.6084 166.969L98.8213 167.585L99.4372 168.372L100.224 167.756Z"
          fill="#809AA7"
        />
        <path
          d="M101.46 169.335L100.842 168.545L100.055 169.161L100.673 169.951L101.46 169.335Z"
          fill="#373B3E"
        />
        <path
          d="M102.076 170.122L101.46 169.335L100.673 169.951L101.289 170.738L102.076 170.122Z"
          fill="#26282A"
        />
        <path
          d="M103.925 172.486L103.309 171.699L102.522 172.315L103.138 173.102L103.925 172.486Z"
          fill="#809AA7"
        />
        <path
          d="M104.543 173.275L103.927 172.488L103.14 173.104L103.756 173.891L104.543 173.275Z"
          fill="#809AA7"
        />
        <path
          d="M105.159 174.062L104.543 173.275L103.756 173.891L104.372 174.678L105.159 174.062Z"
          fill="#809AA7"
        />
        <path
          d="M105.777 174.852L105.161 174.065L104.374 174.681L104.99 175.468L105.777 174.852Z"
          fill="#809AA7"
        />
        <path
          d="M90.8032 157.338L90.1874 156.551L89.4003 157.167L90.0162 157.954L90.8032 157.338Z"
          fill="#F0FFFF"
        />
        <path
          d="M91.4191 158.125L90.8032 157.338L90.0162 157.954L90.632 158.741L91.4191 158.125Z"
          fill="#D0E1E9"
        />
        <path
          d="M92.0369 158.914L91.421 158.127L90.634 158.743L91.2498 159.53L92.0369 158.914Z"
          fill="#D0E1E9"
        />
        <path
          d="M92.6527 159.701L92.0369 158.914L91.2498 159.53L91.8657 160.317L92.6527 159.701Z"
          fill="#9AB0BB"
        />
        <path
          d="M93.2707 160.491L92.6548 159.704L91.8677 160.32L92.4836 161.107L93.2707 160.491Z"
          fill="#809AA7"
        />
        <path
          d="M94.5043 162.068L93.8884 161.281L93.1014 161.896L93.7172 162.684L94.5043 162.068Z"
          fill="#373B3E"
        />
        <path
          d="M95.1201 162.855L94.5043 162.068L93.7172 162.684L94.3331 163.471L95.1201 162.855Z"
          fill="#26282A"
        />
        <path
          d="M96.3539 164.431L95.738 163.644L94.951 164.26L95.5668 165.047L96.3539 164.431Z"
          fill="#F0FFFF"
        />
        <path
          d="M96.9717 165.221L96.3558 164.434L95.5688 165.05L96.1846 165.837L96.9717 165.221Z"
          fill="#394B53"
        />
        <path
          d="M97.5875 166.008L96.9717 165.221L96.1846 165.837L96.8005 166.624L97.5875 166.008Z"
          fill="#51DFF2"
        />
        <path
          d="M98.2034 166.795L97.5875 166.008L96.8005 166.624L97.4163 167.411L98.2034 166.795Z"
          fill="#05B8CF"
        />
        <path
          d="M98.8213 167.585L98.2054 166.798L97.4184 167.413L98.0342 168.201L98.8213 167.585Z"
          fill="#394B53"
        />
        <path
          d="M99.4372 168.372L98.8213 167.585L98.0342 168.201L98.6501 168.988L99.4372 168.372Z"
          fill="#809AA7"
        />
        <path
          d="M100.673 169.951L100.055 169.161L99.2679 169.777L99.8857 170.567L100.673 169.951Z"
          fill="#373B3E"
        />
        <path
          d="M101.289 170.738L100.673 169.951L99.8858 170.567L100.502 171.354L101.289 170.738Z"
          fill="#26282A"
        />
        <path
          d="M102.522 172.315L101.906 171.528L101.119 172.143L101.735 172.93L102.522 172.315Z"
          fill="#F0FFFF"
        />
        <path
          d="M103.138 173.102L102.522 172.315L101.735 172.931L102.351 173.718L103.138 173.102Z"
          fill="#D0E1E9"
        />
        <path
          d="M103.756 173.891L103.14 173.104L102.353 173.72L102.969 174.507L103.756 173.891Z"
          fill="#D0E1E9"
        />
        <path
          d="M104.372 174.678L103.756 173.891L102.969 174.507L103.585 175.294L104.372 174.678Z"
          fill="#9AB0BB"
        />
        <path
          d="M104.99 175.468L104.374 174.681L103.587 175.297L104.203 176.084L104.99 175.468Z"
          fill="#809AA7"
        />
        <path
          d="M90.0137 157.956L89.3978 157.169L88.6082 157.786L89.2241 158.573L90.0137 157.956Z"
          fill="#FFAD32"
        />
        <path
          d="M90.6295 158.743L90.0137 157.956L89.2241 158.573L89.84 159.36L90.6295 158.743Z"
          fill="#F7852F"
        />
        <path
          d="M91.2473 159.532L90.6315 158.745L89.8419 159.363L90.4578 160.15L91.2473 159.532Z"
          fill="#F7852F"
        />
        <path
          d="M91.8632 160.319L91.2473 159.532L90.4577 160.15L91.0736 160.937L91.8632 160.319Z"
          fill="#F7852F"
        />
        <path
          d="M92.481 161.109L91.8651 160.322L91.0755 160.94L91.6914 161.727L92.481 161.109Z"
          fill="#D8531A"
        />
        <path
          d="M93.7147 162.686L93.0989 161.898L92.3093 162.516L92.9252 163.303L93.7147 162.686Z"
          fill="#373B3E"
        />
        <path
          d="M94.3306 163.473L93.7147 162.685L92.9251 163.303L93.541 164.09L94.3306 163.473Z"
          fill="#26282A"
        />
        <path
          d="M95.5644 165.049L94.9485 164.262L94.1589 164.88L94.7748 165.667L95.5644 165.049Z"
          fill="#F0FFFF"
        />
        <path
          d="M96.1821 165.839L95.5663 165.052L94.7767 165.67L95.3926 166.457L96.1821 165.839Z"
          fill="#809AA7"
        />
        <path
          d="M96.798 166.626L96.1821 165.839L95.3925 166.457L96.0084 167.244L96.798 166.626Z"
          fill="#394B53"
        />
        <path
          d="M97.4138 167.413L96.798 166.626L96.0084 167.244L96.6243 168.031L97.4138 167.413Z"
          fill="#394B53"
        />
        <path
          d="M98.0316 168.203L97.4158 167.415L96.6262 168.033L97.2421 168.82L98.0316 168.203Z"
          fill="#809AA7"
        />
        <path
          d="M98.6476 168.99L98.0317 168.203L97.2422 168.82L97.858 169.607L98.6476 168.99Z"
          fill="#809AA7"
        />
        <path
          d="M99.8832 170.569L99.2654 169.779L98.4758 170.397L99.0936 171.187L99.8832 170.569Z"
          fill="#373B3E"
        />
        <path
          d="M100.499 171.356L99.8832 170.569L99.0936 171.187L99.7095 171.974L100.499 171.356Z"
          fill="#26282A"
        />
        <path
          d="M101.733 172.932L101.117 172.145L100.327 172.763L100.943 173.55L101.733 172.932Z"
          fill="#FFAD32"
        />
        <path
          d="M102.349 173.72L101.733 172.932L100.943 173.55L101.559 174.337L102.349 173.72Z"
          fill="#F7852F"
        />
        <path
          d="M102.966 174.509L102.351 173.722L101.561 174.34L102.177 175.127L102.966 174.509Z"
          fill="#F7852F"
        />
        <path
          d="M103.582 175.296L102.967 174.509L102.177 175.127L102.793 175.914L103.582 175.296Z"
          fill="#F7852F"
        />
        <path
          d="M104.2 176.086L103.584 175.299L102.795 175.916L103.411 176.704L104.2 176.086Z"
          fill="#D8531A"
        />
        <path
          d="M89.2266 158.571L88.6107 157.784L87.8237 158.4L88.4395 159.187L89.2266 158.571Z"
          fill="#F0FFFF"
        />
        <path
          d="M89.8424 159.358L89.2266 158.571L88.4395 159.187L89.0554 159.974L89.8424 159.358Z"
          fill="#D0E1E9"
        />
        <path
          d="M90.4602 160.148L89.8444 159.361L89.0573 159.977L89.6732 160.764L90.4602 160.148Z"
          fill="#D0E1E9"
        />
        <path
          d="M91.0762 160.935L90.4603 160.148L89.6733 160.764L90.2891 161.551L91.0762 160.935Z"
          fill="#9AB0BB"
        />
        <path
          d="M91.694 161.725L91.0781 160.938L90.2911 161.554L90.9069 162.341L91.694 161.725Z"
          fill="#809AA7"
        />
        <path
          d="M92.9276 163.301L92.3118 162.514L91.5247 163.13L92.1406 163.917L92.9276 163.301Z"
          fill="#373B3E"
        />
        <path
          d="M93.5435 164.088L92.9276 163.301L92.1405 163.917L92.7564 164.704L93.5435 164.088Z"
          fill="#26282A"
        />
        <path
          d="M94.1614 164.878L93.5455 164.091L92.7585 164.707L93.3743 165.494L94.1614 164.878Z"
          fill="#9AB0BB"
        />
        <path
          d="M94.7772 165.665L94.1614 164.878L93.3743 165.494L93.9902 166.281L94.7772 165.665Z"
          fill="#F0FFFF"
        />
        <path
          d="M95.395 166.455L94.7792 165.668L93.9921 166.283L94.608 167.071L95.395 166.455Z"
          fill="#D0E1E9"
        />
        <path
          d="M96.0109 167.242L95.395 166.455L94.608 167.071L95.2238 167.858L96.0109 167.242Z"
          fill="#809AA7"
        />
        <path
          d="M96.6269 168.029L96.011 167.242L95.2239 167.858L95.8398 168.645L96.6269 168.029Z"
          fill="#809AA7"
        />
        <path
          d="M97.2446 168.818L96.6288 168.031L95.8417 168.647L96.4576 169.434L97.2446 168.818Z"
          fill="#D0E1E9"
        />
        <path
          d="M97.8605 169.605L97.2446 168.818L96.4576 169.434L97.0734 170.221L97.8605 169.605Z"
          fill="#809AA7"
        />
        <path
          d="M98.4783 170.395L97.8624 169.608L97.0754 170.224L97.6912 171.011L98.4783 170.395Z"
          fill="#9AB0BB"
        />
        <path
          d="M99.0961 171.185L98.4783 170.395L97.6912 171.011L98.309 171.8L99.0961 171.185Z"
          fill="#373B3E"
        />
        <path
          d="M99.7121 171.972L99.0962 171.185L98.3091 171.8L98.925 172.588L99.7121 171.972Z"
          fill="#26282A"
        />
        <path
          d="M100.946 173.548L100.33 172.761L99.5428 173.377L100.159 174.164L100.946 173.548Z"
          fill="#F0FFFF"
        />
        <path
          d="M101.562 174.335L100.946 173.548L100.159 174.164L100.774 174.951L101.562 174.335Z"
          fill="#D0E1E9"
        />
        <path
          d="M102.179 175.125L101.564 174.338L100.777 174.954L101.392 175.741L102.179 175.125Z"
          fill="#D0E1E9"
        />
        <path
          d="M102.795 175.912L102.179 175.125L101.392 175.741L102.008 176.528L102.795 175.912Z"
          fill="#9AB0BB"
        />
        <path
          d="M103.413 176.702L102.797 175.915L102.01 176.53L102.626 177.317L103.413 176.702Z"
          fill="#809AA7"
        />
        <path
          d="M88.4395 159.187L87.8236 158.4L87.0365 159.016L87.6524 159.803L88.4395 159.187Z"
          fill="#FFAD32"
        />
        <path
          d="M89.0553 159.974L88.4395 159.187L87.6524 159.803L88.2683 160.59L89.0553 159.974Z"
          fill="#F7852F"
        />
        <path
          d="M89.6732 160.764L89.0574 159.977L88.2703 160.593L88.8862 161.38L89.6732 160.764Z"
          fill="#F7852F"
        />
        <path
          d="M90.2891 161.551L89.6732 160.764L88.8862 161.38L89.502 162.167L90.2891 161.551Z"
          fill="#F7852F"
        />
        <path
          d="M90.9069 162.341L90.291 161.554L89.5039 162.169L90.1198 162.956L90.9069 162.341Z"
          fill="#D8531A"
        />
        <path
          d="M92.1406 163.917L91.5248 163.13L90.7377 163.746L91.3536 164.533L92.1406 163.917Z"
          fill="#F0FFFF"
        />
        <path
          d="M92.7565 164.704L92.1406 163.917L91.3536 164.533L91.9694 165.32L92.7565 164.704Z"
          fill="#D0E1E9"
        />
        <path
          d="M93.3743 165.494L92.7584 164.707L91.9714 165.323L92.5872 166.11L93.3743 165.494Z"
          fill="#809AA7"
        />
        <path
          d="M93.9901 166.281L93.3743 165.494L92.5872 166.11L93.2031 166.897L93.9901 166.281Z"
          fill="#F0FFFF"
        />
        <path
          d="M94.6081 167.071L93.9922 166.283L93.2051 166.899L93.821 167.686L94.6081 167.071Z"
          fill="#D0E1E9"
        />
        <path
          d="M95.2239 167.858L94.608 167.07L93.821 167.686L94.4368 168.473L95.2239 167.858Z"
          fill="#D0E1E9"
        />
        <path
          d="M95.8397 168.645L95.2239 167.858L94.4368 168.473L95.0527 169.261L95.8397 168.645Z"
          fill="#D0E1E9"
        />
        <path
          d="M96.4575 169.434L95.8417 168.647L95.0546 169.263L95.6705 170.05L96.4575 169.434Z"
          fill="#D0E1E9"
        />
        <path
          d="M97.0734 170.221L96.4575 169.434L95.6705 170.05L96.2863 170.837L97.0734 170.221Z"
          fill="#809AA7"
        />
        <path
          d="M97.6913 171.011L97.0754 170.224L96.2884 170.84L96.9042 171.627L97.6913 171.011Z"
          fill="#D0E1E9"
        />
        <path
          d="M98.3091 171.8L97.6913 171.011L96.9042 171.627L97.5221 172.416L98.3091 171.8Z"
          fill="#D0E1E9"
        />
        <path
          d="M98.9249 172.588L98.3091 171.8L97.522 172.416L98.1379 173.203L98.9249 172.588Z"
          fill="#809AA7"
        />
        <path
          d="M100.159 174.164L99.5428 173.377L98.7558 173.993L99.3716 174.78L100.159 174.164Z"
          fill="#FFAD32"
        />
        <path
          d="M100.775 174.951L100.159 174.164L99.3716 174.78L99.9875 175.567L100.775 174.951Z"
          fill="#F7852F"
        />
        <path
          d="M101.392 175.741L100.776 174.954L99.9894 175.57L100.605 176.357L101.392 175.741Z"
          fill="#F7852F"
        />
        <path
          d="M102.008 176.528L101.392 175.741L100.605 176.357L101.221 177.144L102.008 176.528Z"
          fill="#F7852F"
        />
        <path
          d="M102.626 177.317L102.01 176.53L101.223 177.146L101.839 177.933L102.626 177.317Z"
          fill="#D8531A"
        />
        <path
          d="M87.6499 159.805L87.0341 159.018L86.247 159.634L86.8629 160.421L87.6499 159.805Z"
          fill="#F0FFFF"
        />
        <path
          d="M88.2658 160.592L87.6499 159.805L86.8628 160.421L87.4787 161.208L88.2658 160.592Z"
          fill="#D0E1E9"
        />
        <path
          d="M88.8836 161.382L88.2677 160.595L87.4806 161.211L88.0965 161.998L88.8836 161.382Z"
          fill="#D0E1E9"
        />
        <path
          d="M89.4995 162.169L88.8837 161.382L88.0966 161.998L88.7125 162.785L89.4995 162.169Z"
          fill="#9AB0BB"
        />
        <path
          d="M90.1173 162.958L89.5015 162.171L88.7144 162.787L89.3303 163.574L90.1173 162.958Z"
          fill="#809AA7"
        />
        <path
          d="M90.7351 163.748L90.1173 162.958L89.3302 163.574L89.9481 164.364L90.7351 163.748Z"
          fill="#809AA7"
        />
        <path
          d="M91.351 164.535L90.7351 163.748L89.948 164.364L90.5639 165.151L91.351 164.535Z"
          fill="#F0FFFF"
        />
        <path
          d="M91.9669 165.322L91.3511 164.535L90.564 165.151L91.1799 165.938L91.9669 165.322Z"
          fill="#D0E1E9"
        />
        <path
          d="M92.5847 166.112L91.9689 165.325L91.1818 165.941L91.7977 166.728L92.5847 166.112Z"
          fill="#809AA7"
        />
        <path
          d="M93.2006 166.899L92.5847 166.112L91.7977 166.728L92.4135 167.515L93.2006 166.899Z"
          fill="#F0FFFF"
        />
        <path
          d="M93.8184 167.688L93.2025 166.901L92.4154 167.517L93.0313 168.304L93.8184 167.688Z"
          fill="#D0E1E9"
        />
        <path
          d="M94.4342 168.475L93.8184 167.688L93.0313 168.304L93.6472 169.091L94.4342 168.475Z"
          fill="#D0E1E9"
        />
        <path
          d="M95.0502 169.262L94.4343 168.475L93.6473 169.091L94.2631 169.878L95.0502 169.262Z"
          fill="#D0E1E9"
        />
        <path
          d="M95.668 170.052L95.0521 169.265L94.2651 169.881L94.8809 170.668L95.668 170.052Z"
          fill="#D0E1E9"
        />
        <path
          d="M96.2838 170.839L95.668 170.052L94.8809 170.668L95.4968 171.455L96.2838 170.839Z"
          fill="#809AA7"
        />
        <path
          d="M96.9016 171.629L96.2858 170.842L95.4987 171.458L96.1146 172.245L96.9016 171.629Z"
          fill="#D0E1E9"
        />
        <path
          d="M97.5196 172.418L96.9017 171.629L96.1147 172.245L96.7325 173.034L97.5196 172.418Z"
          fill="#D0E1E9"
        />
        <path
          d="M98.1354 173.205L97.5195 172.418L96.7325 173.034L97.3483 173.821L98.1354 173.205Z"
          fill="#D0E1E9"
        />
        <path
          d="M98.7532 173.995L98.1354 173.205L97.3483 173.821L97.9661 174.611L98.7532 173.995Z"
          fill="#809AA7"
        />
        <path
          d="M99.369 174.782L98.7532 173.995L97.9661 174.611L98.582 175.398L99.369 174.782Z"
          fill="#F0FFFF"
        />
        <path
          d="M99.985 175.569L99.3691 174.782L98.5821 175.398L99.1979 176.185L99.985 175.569Z"
          fill="#D0E1E9"
        />
        <path
          d="M100.603 176.359L99.9869 175.572L99.1999 176.187L99.8157 176.975L100.603 176.359Z"
          fill="#D0E1E9"
        />
        <path
          d="M101.219 177.146L100.603 176.359L99.8157 176.975L100.432 177.762L101.219 177.146Z"
          fill="#9AB0BB"
        />
        <path
          d="M101.836 177.935L101.221 177.148L100.434 177.764L101.049 178.551L101.836 177.935Z"
          fill="#809AA7"
        />
        <path
          d="M86.8628 160.421L86.2469 159.634L85.4599 160.25L86.0757 161.037L86.8628 160.421Z"
          fill="#F0FFFF"
        />
        <path
          d="M87.4788 161.208L86.8629 160.421L86.0758 161.037L86.6917 161.824L87.4788 161.208Z"
          fill="#D0E1E9"
        />
        <path
          d="M88.0966 161.998L87.4807 161.211L86.6936 161.826L87.3095 162.614L88.0966 161.998Z"
          fill="#D0E1E9"
        />
        <path
          d="M88.7124 162.785L88.0966 161.998L87.3095 162.613L87.9254 163.401L88.7124 162.785Z"
          fill="#F0FFFF"
        />
        <path
          d="M89.3302 163.574L88.7144 162.787L87.9273 163.403L88.5432 164.19L89.3302 163.574Z"
          fill="#D0E1E9"
        />
        <path
          d="M89.948 164.364L89.3302 163.574L88.5431 164.19L89.161 164.98L89.948 164.364Z"
          fill="#9AB0BB"
        />
        <path
          d="M90.564 165.151L89.9481 164.364L89.1611 164.98L89.7769 165.767L90.564 165.151Z"
          fill="#D0E1E9"
        />
        <path
          d="M91.1798 165.938L90.564 165.151L89.7769 165.767L90.3928 166.554L91.1798 165.938Z"
          fill="#D0E1E9"
        />
        <path
          d="M91.7976 166.728L91.1818 165.94L90.3947 166.556L91.0106 167.343L91.7976 166.728Z"
          fill="#809AA7"
        />
        <path
          d="M92.4135 167.515L91.7976 166.728L91.0105 167.343L91.6264 168.131L92.4135 167.515Z"
          fill="#FFAD32"
        />
        <path
          d="M93.0314 168.304L92.4155 167.517L91.6285 168.133L92.2443 168.92L93.0314 168.304Z"
          fill="#F7852F"
        />
        <path
          d="M93.6472 169.091L93.0314 168.304L92.2443 168.92L92.8602 169.707L93.6472 169.091Z"
          fill="#F7852F"
        />
        <path
          d="M94.2631 169.878L93.6472 169.091L92.8602 169.707L93.476 170.494L94.2631 169.878Z"
          fill="#F7852F"
        />
        <path
          d="M94.8809 170.668L94.265 169.881L93.4779 170.497L94.0938 171.284L94.8809 170.668Z"
          fill="#F7852F"
        />
        <path
          d="M95.4967 171.455L94.8809 170.668L94.0938 171.284L94.7097 172.071L95.4967 171.455Z"
          fill="#D8531A"
        />
        <path
          d="M96.1146 172.245L95.4988 171.458L94.7117 172.073L95.3276 172.86L96.1146 172.245Z"
          fill="#9AB0BB"
        />
        <path
          d="M96.7325 173.034L96.1146 172.245L95.3276 172.86L95.9454 173.65L96.7325 173.034Z"
          fill="#D0E1E9"
        />
        <path
          d="M97.3483 173.821L96.7324 173.034L95.9454 173.65L96.5612 174.437L97.3483 173.821Z"
          fill="#D0E1E9"
        />
        <path
          d="M97.9661 174.611L97.3483 173.821L96.5612 174.437L97.179 175.227L97.9661 174.611Z"
          fill="#809AA7"
        />
        <path
          d="M98.5821 175.398L97.9662 174.611L97.1791 175.227L97.795 176.014L98.5821 175.398Z"
          fill="#809AA7"
        />
        <path
          d="M99.1979 176.185L98.582 175.398L97.795 176.014L98.4108 176.801L99.1979 176.185Z"
          fill="#F0FFFF"
        />
        <path
          d="M99.8157 176.975L99.1998 176.187L98.4128 176.803L99.0286 177.59L99.8157 176.975Z"
          fill="#D0E1E9"
        />
        <path
          d="M100.432 177.762L99.8157 176.975L99.0286 177.59L99.6445 178.377L100.432 177.762Z"
          fill="#9AB0BB"
        />
        <path
          d="M101.049 178.551L100.434 177.764L99.6465 178.38L100.262 179.167L101.049 178.551Z"
          fill="#809AA7"
        />
        <path
          d="M86.0733 161.039L85.4574 160.252L84.6703 160.868L85.2862 161.655L86.0733 161.039Z"
          fill="#F0FFFF"
        />
        <path
          d="M86.6891 161.826L86.0732 161.039L85.2862 161.655L85.902 162.442L86.6891 161.826Z"
          fill="#D0E1E9"
        />
        <path
          d="M87.307 162.615L86.6912 161.828L85.9041 162.444L86.52 163.231L87.307 162.615Z"
          fill="#F0FFFF"
        />
        <path
          d="M87.9229 163.403L87.307 162.615L86.5199 163.231L87.1358 164.018L87.9229 163.403Z"
          fill="#D0E1E9"
        />
        <path
          d="M88.5407 164.192L87.9248 163.405L87.1377 164.021L87.7536 164.808L88.5407 164.192Z"
          fill="#D0E1E9"
        />
        <path
          d="M89.1585 164.982L88.5406 164.192L87.7536 164.808L88.3714 165.598L89.1585 164.982Z"
          fill="#9AB0BB"
        />
        <path
          d="M89.7744 165.769L89.1586 164.982L88.3715 165.598L88.9874 166.385L89.7744 165.769Z"
          fill="#D0E1E9"
        />
        <path
          d="M90.3903 166.556L89.7744 165.769L88.9873 166.385L89.6032 167.172L90.3903 166.556Z"
          fill="#9AB0BB"
        />
        <path
          d="M91.0081 167.345L90.3922 166.558L89.6051 167.174L90.221 167.961L91.0081 167.345Z"
          fill="#809AA7"
        />
        <path
          d="M91.6239 168.132L91.0081 167.345L90.221 167.961L90.8369 168.748L91.6239 168.132Z"
          fill="#F0FFFF"
        />
        <path
          d="M92.2417 168.922L91.6259 168.135L90.8388 168.751L91.4547 169.538L92.2417 168.922Z"
          fill="#D0E1E9"
        />
        <path
          d="M92.8577 169.709L92.2418 168.922L91.4548 169.538L92.0706 170.325L92.8577 169.709Z"
          fill="#D0E1E9"
        />
        <path
          d="M93.4735 170.496L92.8577 169.709L92.0706 170.325L92.6865 171.112L93.4735 170.496Z"
          fill="#D0E1E9"
        />
        <path
          d="M94.0913 171.286L93.4755 170.499L92.6884 171.115L93.3043 171.902L94.0913 171.286Z"
          fill="#D0E1E9"
        />
        <path
          d="M94.7072 172.073L94.0913 171.286L93.3042 171.902L93.9201 172.689L94.7072 172.073Z"
          fill="#809AA7"
        />
        <path
          d="M95.3251 172.862L94.7092 172.075L93.9222 172.691L94.538 173.478L95.3251 172.862Z"
          fill="#809AA7"
        />
        <path
          d="M95.9429 173.652L95.3251 172.862L94.538 173.478L95.1558 174.268L95.9429 173.652Z"
          fill="#9AB0BB"
        />
        <path
          d="M96.5587 174.439L95.9429 173.652L95.1558 174.268L95.7717 175.055L96.5587 174.439Z"
          fill="#D0E1E9"
        />
        <path
          d="M97.1765 175.229L96.5587 174.439L95.7716 175.055L96.3895 175.845L97.1765 175.229Z"
          fill="#809AA7"
        />
        <path
          d="M97.7924 176.016L97.1765 175.229L96.3894 175.845L97.0053 176.632L97.7924 176.016Z"
          fill="#D0E1E9"
        />
        <path
          d="M98.4083 176.803L97.7925 176.016L97.0054 176.632L97.6213 177.419L98.4083 176.803Z"
          fill="#809AA7"
        />
        <path
          d="M99.0261 177.592L98.4103 176.805L97.6232 177.421L98.2391 178.208L99.0261 177.592Z"
          fill="#F0FFFF"
        />
        <path
          d="M99.642 178.379L99.0261 177.592L98.2391 178.208L98.8549 178.995L99.642 178.379Z"
          fill="#9AB0BB"
        />
        <path
          d="M100.26 179.169L99.6439 178.382L98.8569 178.998L99.4727 179.785L100.26 179.169Z"
          fill="#809AA7"
        />
        <path
          d="M85.2862 161.655L84.6703 160.868L83.8832 161.483L84.4991 162.271L85.2862 161.655Z"
          fill="#F0FFFF"
        />
        <path
          d="M85.9021 162.442L85.2863 161.655L84.4992 162.271L85.1151 163.058L85.9021 162.442Z"
          fill="#F0FFFF"
        />
        <path
          d="M86.5199 163.231L85.9041 162.444L85.117 163.06L85.7329 163.847L86.5199 163.231Z"
          fill="#D0E1E9"
        />
        <path
          d="M87.1358 164.018L86.5199 163.231L85.7328 163.847L86.3487 164.634L87.1358 164.018Z"
          fill="#D0E1E9"
        />
        <path
          d="M87.7536 164.808L87.1377 164.021L86.3506 164.637L86.9665 165.424L87.7536 164.808Z"
          fill="#D0E1E9"
        />
        <path
          d="M88.3715 165.598L87.7537 164.808L86.9666 165.424L87.5844 166.213L88.3715 165.598Z"
          fill="#9AB0BB"
        />
        <path
          d="M88.9873 166.385L88.3715 165.598L87.5844 166.213L88.2003 167L88.9873 166.385Z"
          fill="#9AB0BB"
        />
        <path
          d="M89.6032 167.172L88.9873 166.385L88.2002 167.001L88.8161 167.788L89.6032 167.172Z"
          fill="#809AA7"
        />
        <path
          d="M90.8368 168.748L90.2209 167.961L89.4339 168.577L90.0497 169.364L90.8368 168.748Z"
          fill="#F0FFFF"
        />
        <path
          d="M91.4547 169.538L90.8389 168.751L90.0518 169.367L90.6677 170.154L91.4547 169.538Z"
          fill="#809AA7"
        />
        <path
          d="M92.0706 170.325L91.4547 169.538L90.6676 170.154L91.2835 170.941L92.0706 170.325Z"
          fill="#809AA7"
        />
        <path
          d="M92.6864 171.112L92.0706 170.325L91.2835 170.941L91.8994 171.728L92.6864 171.112Z"
          fill="#809AA7"
        />
        <path
          d="M93.3042 171.902L92.6884 171.115L91.9013 171.73L92.5172 172.517L93.3042 171.902Z"
          fill="#809AA7"
        />
        <path
          d="M93.9202 172.689L93.3043 171.902L92.5173 172.517L93.1331 173.305L93.9202 172.689Z"
          fill="#809AA7"
        />
        <path
          d="M95.1558 174.268L94.538 173.478L93.7509 174.094L94.3687 174.884L95.1558 174.268Z"
          fill="#809AA7"
        />
        <path
          d="M95.7716 175.055L95.1558 174.268L94.3687 174.884L94.9846 175.671L95.7716 175.055Z"
          fill="#9AB0BB"
        />
        <path
          d="M96.3896 175.845L95.7717 175.055L94.9847 175.671L95.6025 176.46L96.3896 175.845Z"
          fill="#809AA7"
        />
        <path
          d="M97.0054 176.632L96.3895 175.844L95.6025 176.46L96.2183 177.247L97.0054 176.632Z"
          fill="#D0E1E9"
        />
        <path
          d="M97.6212 177.419L97.0054 176.632L96.2183 177.247L96.8342 178.035L97.6212 177.419Z"
          fill="#D0E1E9"
        />
        <path
          d="M98.239 178.208L97.6232 177.421L96.8361 178.037L97.452 178.824L98.239 178.208Z"
          fill="#809AA7"
        />
        <path
          d="M98.8549 178.995L98.239 178.208L97.4519 178.824L98.0678 179.611L98.8549 178.995Z"
          fill="#F0FFFF"
        />
        <path
          d="M99.4728 179.785L98.8569 178.998L98.0699 179.614L98.6857 180.401L99.4728 179.785Z"
          fill="#809AA7"
        />
        <path
          d="M84.4966 162.273L83.8807 161.485L83.0937 162.101L83.7095 162.888L84.4966 162.273Z"
          fill="#F0FFFF"
        />
        <path
          d="M85.1124 163.06L84.4966 162.273L83.7095 162.888L84.3254 163.675L85.1124 163.06Z"
          fill="#D0E1E9"
        />
        <path
          d="M85.7304 163.849L85.1145 163.062L84.3274 163.678L84.9433 164.465L85.7304 163.849Z"
          fill="#D0E1E9"
        />
        <path
          d="M86.3462 164.636L85.7303 163.849L84.9433 164.465L85.5591 165.252L86.3462 164.636Z"
          fill="#D0E1E9"
        />
        <path
          d="M86.964 165.426L86.3481 164.639L85.5611 165.255L86.1769 166.042L86.964 165.426Z"
          fill="#D0E1E9"
        />
        <path
          d="M87.5818 166.215L86.964 165.426L86.1769 166.042L86.7948 166.831L87.5818 166.215Z"
          fill="#9AB0BB"
        />
        <path
          d="M88.1978 167.002L87.5819 166.215L86.7948 166.831L87.4107 167.618L88.1978 167.002Z"
          fill="#809AA7"
        />
        <path
          d="M88.8136 167.79L88.1978 167.002L87.4107 167.618L88.0266 168.405L88.8136 167.79Z"
          fill="#809AA7"
        />
        <path
          d="M90.6652 170.156L90.0493 169.369L89.2623 169.985L89.8781 170.772L90.6652 170.156Z"
          fill="#F0FFFF"
        />
        <path
          d="M91.281 170.943L90.6652 170.156L89.8781 170.772L90.494 171.559L91.281 170.943Z"
          fill="#D0E1E9"
        />
        <path
          d="M91.8969 171.73L91.281 170.943L90.4939 171.559L91.1098 172.346L91.8969 171.73Z"
          fill="#D0E1E9"
        />
        <path
          d="M92.5147 172.519L91.8988 171.732L91.1117 172.348L91.7276 173.135L92.5147 172.519Z"
          fill="#809AA7"
        />
        <path
          d="M94.3662 174.886L93.7484 174.096L92.9613 174.712L93.5792 175.502L94.3662 174.886Z"
          fill="#809AA7"
        />
        <path
          d="M94.9821 175.673L94.3662 174.886L93.5791 175.502L94.195 176.289L94.9821 175.673Z"
          fill="#809AA7"
        />
        <path
          d="M95.5999 176.462L94.9821 175.673L94.195 176.289L94.8128 177.078L95.5999 176.462Z"
          fill="#809AA7"
        />
        <path
          d="M96.2158 177.249L95.6 176.462L94.8129 177.078L95.4288 177.865L96.2158 177.249Z"
          fill="#9AB0BB"
        />
        <path
          d="M96.8317 178.036L96.2158 177.249L95.4288 177.865L96.0446 178.652L96.8317 178.036Z"
          fill="#D0E1E9"
        />
        <path
          d="M97.4495 178.826L96.8336 178.039L96.0466 178.655L96.6624 179.442L97.4495 178.826Z"
          fill="#D0E1E9"
        />
        <path
          d="M98.0653 179.613L97.4495 178.826L96.6624 179.442L97.2783 180.229L98.0653 179.613Z"
          fill="#809AA7"
        />
        <path
          d="M98.6831 180.403L98.0673 179.616L97.2802 180.232L97.8961 181.019L98.6831 180.403Z"
          fill="#F0FFFF"
        />
        <path
          d="M83.7096 162.888L83.0938 162.101L82.3067 162.717L82.9225 163.504L83.7096 162.888Z"
          fill="#F0FFFF"
        />
        <path
          d="M84.3255 163.675L83.7096 162.888L82.9225 163.504L83.5384 164.291L84.3255 163.675Z"
          fill="#D0E1E9"
        />
        <path
          d="M84.9433 164.465L84.3274 163.678L83.5403 164.294L84.1562 165.081L84.9433 164.465Z"
          fill="#D0E1E9"
        />
        <path
          d="M85.5591 165.252L84.9432 164.465L84.1562 165.081L84.772 165.868L85.5591 165.252Z"
          fill="#D0E1E9"
        />
        <path
          d="M86.177 166.042L85.5612 165.255L84.7741 165.871L85.39 166.658L86.177 166.042Z"
          fill="#9AB0BB"
        />
        <path
          d="M86.7948 166.831L86.177 166.042L85.3899 166.658L86.0078 167.447L86.7948 166.831Z"
          fill="#809AA7"
        />
        <path
          d="M87.4107 167.618L86.7948 166.831L86.0077 167.447L86.6236 168.234L87.4107 167.618Z"
          fill="#D0E1E9"
        />
        <path
          d="M88.0265 168.405L87.4106 167.618L86.6236 168.234L87.2394 169.021L88.0265 168.405Z"
          fill="#809AA7"
        />
        <path
          d="M90.4939 171.559L89.8781 170.772L89.091 171.387L89.7069 172.175L90.4939 171.559Z"
          fill="#373B3E"
        />
        <path
          d="M91.1098 172.346L90.4939 171.559L89.7068 172.175L90.3227 172.962L91.1098 172.346Z"
          fill="#26282A"
        />
        <path
          d="M93.5791 175.502L92.9613 174.712L92.1742 175.328L92.7921 176.117L93.5791 175.502Z"
          fill="#809AA7"
        />
        <path
          d="M94.195 176.289L93.5791 175.502L92.792 176.117L93.4079 176.905L94.195 176.289Z"
          fill="#D0E1E9"
        />
        <path
          d="M94.8129 177.078L94.1951 176.289L93.408 176.905L94.0258 177.694L94.8129 177.078Z"
          fill="#809AA7"
        />
        <path
          d="M95.4287 177.865L94.8129 177.078L94.0258 177.694L94.6417 178.481L95.4287 177.865Z"
          fill="#809AA7"
        />
        <path
          d="M96.0446 178.652L95.4287 177.865L94.6416 178.481L95.2575 179.268L96.0446 178.652Z"
          fill="#9AB0BB"
        />
        <path
          d="M96.6624 179.442L96.0465 178.655L95.2594 179.271L95.8753 180.058L96.6624 179.442Z"
          fill="#D0E1E9"
        />
        <path
          d="M97.2783 180.229L96.6625 179.442L95.8754 180.058L96.4913 180.845L97.2783 180.229Z"
          fill="#D0E1E9"
        />
        <path
          d="M97.8961 181.019L97.2803 180.232L96.4932 180.847L97.1091 181.634L97.8961 181.019Z"
          fill="#809AA7"
        />
        <path
          d="M82.9199 163.506L82.3041 162.719L81.517 163.335L82.1329 164.122L82.9199 163.506Z"
          fill="#F0FFFF"
        />
        <path
          d="M83.5359 164.293L82.92 163.506L82.133 164.122L82.7488 164.909L83.5359 164.293Z"
          fill="#809AA7"
        />
        <path
          d="M84.1537 165.083L83.5378 164.296L82.7508 164.912L83.3666 165.699L84.1537 165.083Z"
          fill="#809AA7"
        />
        <path
          d="M84.7696 165.87L84.1537 165.083L83.3666 165.699L83.9825 166.486L84.7696 165.87Z"
          fill="#809AA7"
        />
        <path
          d="M85.3873 166.66L84.7715 165.872L83.9844 166.488L84.6003 167.275L85.3873 166.66Z"
          fill="#809AA7"
        />
        <path
          d="M86.0052 167.449L85.3873 166.659L84.6003 167.275L85.2181 168.065L86.0052 167.449Z"
          fill="#D0E1E9"
        />
        <path
          d="M86.6211 168.236L86.0052 167.449L85.2182 168.065L85.834 168.852L86.6211 168.236Z"
          fill="#D0E1E9"
        />
        <path
          d="M87.237 169.023L86.6211 168.236L85.834 168.852L86.4499 169.639L87.237 169.023Z"
          fill="#809AA7"
        />
        <path
          d="M89.0885 171.389L88.4727 170.602L87.6856 171.218L88.3015 172.005L89.0885 171.389Z"
          fill="#43484B"
        />
        <path
          d="M89.7044 172.177L89.0885 171.389L88.3014 172.005L88.9173 172.792L89.7044 172.177Z"
          fill="#373B3E"
        />
        <path
          d="M90.3202 172.964L89.7043 172.177L88.9173 172.792L89.5331 173.579L90.3202 172.964Z"
          fill="#373B3E"
        />
        <path
          d="M90.938 173.753L90.3221 172.966L89.5351 173.582L90.1509 174.369L90.938 173.753Z"
          fill="#26282A"
        />
        <path
          d="M92.7896 176.119L92.1718 175.33L91.3847 175.946L92.0025 176.735L92.7896 176.119Z"
          fill="#809AA7"
        />
        <path
          d="M93.4054 176.906L92.7896 176.119L92.0025 176.735L92.6184 177.522L93.4054 176.906Z"
          fill="#D0E1E9"
        />
        <path
          d="M94.0232 177.696L93.4054 176.906L92.6183 177.522L93.2362 178.312L94.0232 177.696Z"
          fill="#D0E1E9"
        />
        <path
          d="M94.6392 178.483L94.0233 177.696L93.2363 178.312L93.8521 179.099L94.6392 178.483Z"
          fill="#809AA7"
        />
        <path
          d="M95.255 179.27L94.6392 178.483L93.8521 179.099L94.468 179.886L95.255 179.27Z"
          fill="#809AA7"
        />
        <path
          d="M95.8728 180.06L95.257 179.273L94.4699 179.889L95.0858 180.676L95.8728 180.06Z"
          fill="#809AA7"
        />
        <path
          d="M96.4887 180.847L95.8728 180.06L95.0857 180.676L95.7016 181.463L96.4887 180.847Z"
          fill="#809AA7"
        />
        <path
          d="M97.1066 181.636L96.4907 180.849L95.7037 181.465L96.3195 182.252L97.1066 181.636Z"
          fill="#809AA7"
        />
        <path
          d="M82.133 164.122L81.5171 163.335L80.73 163.951L81.3459 164.738L82.133 164.122Z"
          fill="#F0FFFF"
        />
        <path
          d="M82.7488 164.909L82.1329 164.122L81.3459 164.738L81.9617 165.525L82.7488 164.909Z"
          fill="#D0E1E9"
        />
        <path
          d="M83.3666 165.699L82.7507 164.912L81.9637 165.528L82.5795 166.315L83.3666 165.699Z"
          fill="#9AB0BB"
        />
        <path
          d="M83.9824 166.486L83.3666 165.699L82.5795 166.315L83.1954 167.102L83.9824 166.486Z"
          fill="#809AA7"
        />
        <path
          d="M84.6004 167.275L83.9845 166.488L83.1974 167.104L83.8133 167.891L84.6004 167.275Z"
          fill="#43484B"
        />
        <path
          d="M85.2182 168.065L84.6003 167.275L83.8133 167.891L84.4311 168.681L85.2182 168.065Z"
          fill="#373B3E"
        />
        <path
          d="M85.834 168.852L85.2181 168.065L84.4311 168.681L85.0469 169.468L85.834 168.852Z"
          fill="#373B3E"
        />
        <path
          d="M86.4498 169.639L85.834 168.852L85.0469 169.468L85.6628 170.255L86.4498 169.639Z"
          fill="#26282A"
        />
        <path
          d="M88.3014 172.005L87.6855 171.218L86.8985 171.834L87.5143 172.621L88.3014 172.005Z"
          fill="#43484B"
        />
        <path
          d="M88.9173 172.792L88.3014 172.005L87.5143 172.621L88.1302 173.408L88.9173 172.792Z"
          fill="#43484B"
        />
        <path
          d="M89.5331 173.579L88.9172 172.792L88.1302 173.408L88.746 174.195L89.5331 173.579Z"
          fill="#373B3E"
        />
        <path
          d="M90.151 174.369L89.5352 173.582L88.7481 174.198L89.364 174.985L90.151 174.369Z"
          fill="#26282A"
        />
        <path
          d="M92.0025 176.735L91.3846 175.946L90.5976 176.562L91.2154 177.351L92.0025 176.735Z"
          fill="#43484B"
        />
        <path
          d="M92.6184 177.522L92.0026 176.735L91.2155 177.351L91.8314 178.138L92.6184 177.522Z"
          fill="#373B3E"
        />
        <path
          d="M93.2362 178.312L92.6184 177.522L91.8313 178.138L92.4492 178.928L93.2362 178.312Z"
          fill="#373B3E"
        />
        <path
          d="M93.8521 179.099L93.2362 178.312L92.4491 178.928L93.065 179.715L93.8521 179.099Z"
          fill="#26282A"
        />
        <path
          d="M94.4679 179.886L93.8521 179.099L93.065 179.715L93.6809 180.502L94.4679 179.886Z"
          fill="#809AA7"
        />
        <path
          d="M95.0858 180.676L94.47 179.889L93.6829 180.504L94.2988 181.291L95.0858 180.676Z"
          fill="#9AB0BB"
        />
        <path
          d="M95.7017 181.463L95.0858 180.676L94.2987 181.291L94.9146 182.079L95.7017 181.463Z"
          fill="#D0E1E9"
        />
        <path
          d="M96.3195 182.252L95.7036 181.465L94.9165 182.081L95.5324 182.868L96.3195 182.252Z"
          fill="#809AA7"
        />
        <path
          d="M81.3433 164.74L80.7274 163.953L79.9404 164.569L80.5562 165.356L81.3433 164.74Z"
          fill="#F0FFFF"
        />
        <path
          d="M81.9592 165.527L81.3434 164.74L80.5563 165.356L81.1722 166.143L81.9592 165.527Z"
          fill="#9AB0BB"
        />
        <path
          d="M82.577 166.317L81.9612 165.529L81.1741 166.145L81.79 166.932L82.577 166.317Z"
          fill="#809AA7"
        />
        <path
          d="M83.8107 167.893L83.1948 167.106L82.4078 167.722L83.0236 168.509L83.8107 167.893Z"
          fill="#43484B"
        />
        <path
          d="M84.4286 168.683L83.8108 167.893L83.0237 168.509L83.6416 169.299L84.4286 168.683Z"
          fill="#373B3E"
        />
        <path
          d="M85.0445 169.47L84.4286 168.683L83.6415 169.299L84.2574 170.086L85.0445 169.47Z"
          fill="#373B3E"
        />
        <path
          d="M85.6603 170.257L85.0444 169.47L84.2574 170.086L84.8732 170.873L85.6603 170.257Z"
          fill="#26282A"
        />
        <path
          d="M87.5119 172.623L86.896 171.836L86.1089 172.452L86.7248 173.239L87.5119 172.623Z"
          fill="#FFCA34"
        />
        <path
          d="M88.1277 173.41L87.5118 172.623L86.7248 173.239L87.3406 174.026L88.1277 173.41Z"
          fill="#FFAD32"
        />
        <path
          d="M88.7436 174.197L88.1277 173.41L87.3406 174.026L87.9565 174.813L88.7436 174.197Z"
          fill="#FFAD32"
        />
        <path
          d="M89.3613 174.987L88.7455 174.2L87.9584 174.816L88.5743 175.603L89.3613 174.987Z"
          fill="#FFCA34"
        />
        <path
          d="M91.2129 177.353L90.5951 176.564L89.808 177.179L90.4259 177.969L91.2129 177.353Z"
          fill="#43484B"
        />
        <path
          d="M91.8288 178.14L91.2129 177.353L90.4258 177.969L91.0417 178.756L91.8288 178.14Z"
          fill="#373B3E"
        />
        <path
          d="M92.4466 178.93L91.8287 178.14L91.0417 178.756L91.6595 179.546L92.4466 178.93Z"
          fill="#373B3E"
        />
        <path
          d="M93.0625 179.717L92.4467 178.93L91.6596 179.546L92.2755 180.333L93.0625 179.717Z"
          fill="#26282A"
        />
        <path
          d="M94.2962 181.293L93.6803 180.506L92.8932 181.122L93.5091 181.909L94.2962 181.293Z"
          fill="#809AA7"
        />
        <path
          d="M94.912 182.081L94.2961 181.293L93.5091 181.909L94.1249 182.696L94.912 182.081Z"
          fill="#9AB0BB"
        />
        <path
          d="M95.5299 182.87L94.9141 182.083L94.127 182.699L94.7429 183.486L95.5299 182.87Z"
          fill="#809AA7"
        />
        <path
          d="M80.5563 165.356L79.9404 164.569L79.1534 165.185L79.7692 165.972L80.5563 165.356Z"
          fill="#F0FFFF"
        />
        <path
          d="M81.1721 166.143L80.5563 165.356L79.7692 165.972L80.3851 166.759L81.1721 166.143Z"
          fill="#809AA7"
        />
        <path
          d="M83.0237 168.509L82.4078 167.722L81.6208 168.338L82.2366 169.125L83.0237 168.509Z"
          fill="#FFCA34"
        />
        <path
          d="M83.6415 169.299L83.0237 168.509L82.2366 169.125L82.8544 169.915L83.6415 169.299Z"
          fill="#FFAD32"
        />
        <path
          d="M84.2573 170.086L83.6415 169.299L82.8544 169.915L83.4703 170.702L84.2573 170.086Z"
          fill="#FFAD32"
        />
        <path
          d="M84.8732 170.873L84.2573 170.086L83.4703 170.702L84.0861 171.489L84.8732 170.873Z"
          fill="#FFCA34"
        />
        <path
          d="M86.7248 173.239L86.1089 172.452L85.3218 173.068L85.9377 173.855L86.7248 173.239Z"
          fill="#FFAD32"
        />
        <path
          d="M87.3406 174.026L86.7247 173.239L85.9377 173.855L86.5535 174.642L87.3406 174.026Z"
          fill="#FFCA34"
        />
        <path
          d="M87.9564 174.813L87.3406 174.026L86.5535 174.642L87.1694 175.429L87.9564 174.813Z"
          fill="#FFCA34"
        />
        <path
          d="M88.5744 175.603L87.9585 174.816L87.1714 175.432L87.7873 176.219L88.5744 175.603Z"
          fill="#FFAD32"
        />
        <path
          d="M90.4258 177.969L89.808 177.179L89.0209 177.795L89.6388 178.585L90.4258 177.969Z"
          fill="#FFCA34"
        />
        <path
          d="M91.0418 178.756L90.4259 177.969L89.6388 178.585L90.2547 179.372L91.0418 178.756Z"
          fill="#FFAD32"
        />
        <path
          d="M91.6596 179.546L91.0417 178.756L90.2547 179.372L90.8725 180.161L91.6596 179.546Z"
          fill="#FFAD32"
        />
        <path
          d="M92.2754 180.333L91.6595 179.546L90.8725 180.161L91.4883 180.949L92.2754 180.333Z"
          fill="#FFCA34"
        />
        <path
          d="M94.125 182.696L93.5092 181.909L92.7221 182.525L93.338 183.312L94.125 182.696Z"
          fill="#809AA7"
        />
        <path
          d="M94.7428 183.486L94.127 182.699L93.3399 183.315L93.9558 184.102L94.7428 183.486Z"
          fill="#809AA7"
        />
        <path
          d="M79.7667 165.974L79.1509 165.187L78.3638 165.802L78.9797 166.589L79.7667 165.974Z"
          fill="#F0FFFF"
        />
        <path
          d="M82.2342 169.127L81.6183 168.34L80.8312 168.956L81.4471 169.743L82.2342 169.127Z"
          fill="#FFAD32"
        />
        <path
          d="M82.852 169.916L82.2341 169.127L81.4471 169.743L82.0649 170.532L82.852 169.916Z"
          fill="#FFCA34"
        />
        <path
          d="M83.4678 170.704L82.8519 169.917L82.0649 170.532L82.6807 171.319L83.4678 170.704Z"
          fill="#FFCA34"
        />
        <path
          d="M84.0836 171.491L83.4678 170.704L82.6807 171.319L83.2966 172.106L84.0836 171.491Z"
          fill="#FFAD32"
        />
        <path
          d="M85.9352 173.857L85.3193 173.07L84.5323 173.686L85.1481 174.473L85.9352 173.857Z"
          fill="#D8531A"
        />
        <path
          d="M86.551 174.644L85.9352 173.857L85.1481 174.473L85.764 175.26L86.551 174.644Z"
          fill="#F7852F"
        />
        <path
          d="M87.1669 175.431L86.551 174.644L85.764 175.26L86.3798 176.047L87.1669 175.431Z"
          fill="#F7852F"
        />
        <path
          d="M87.7848 176.221L87.1689 175.433L86.3819 176.049L86.9977 176.836L87.7848 176.221Z"
          fill="#D8531A"
        />
        <path
          d="M89.6363 178.587L89.0184 177.797L88.2314 178.413L88.8492 179.203L89.6363 178.587Z"
          fill="#FFAD32"
        />
        <path
          d="M90.2521 179.374L89.6362 178.587L88.8492 179.203L89.465 179.99L90.2521 179.374Z"
          fill="#FFCA34"
        />
        <path
          d="M90.87 180.163L90.2522 179.374L89.4651 179.99L90.083 180.779L90.87 180.163Z"
          fill="#FFCA34"
        />
        <path
          d="M91.4859 180.951L90.87 180.163L90.0829 180.779L90.6988 181.566L91.4859 180.951Z"
          fill="#FFAD32"
        />
        <path
          d="M93.9533 184.104L93.3374 183.317L92.5503 183.933L93.1662 184.72L93.9533 184.104Z"
          fill="#809AA7"
        />
        <path
          d="M78.9796 166.589L78.3638 165.802L77.5767 166.418L78.1926 167.205L78.9796 166.589Z"
          fill="#F0FFFF"
        />
        <path
          d="M81.447 169.743L80.8312 168.956L80.0441 169.572L80.66 170.359L81.447 169.743Z"
          fill="#D8531A"
        />
        <path
          d="M82.0649 170.532L81.447 169.743L80.66 170.359L81.2778 171.148L82.0649 170.532Z"
          fill="#F7852F"
        />
        <path
          d="M82.6807 171.319L82.0648 170.532L81.2778 171.148L81.8936 171.935L82.6807 171.319Z"
          fill="#F7852F"
        />
        <path
          d="M83.2965 172.106L82.6807 171.319L81.8936 171.935L82.5095 172.722L83.2965 172.106Z"
          fill="#D8531A"
        />
        <path
          d="M85.7639 175.26L85.1481 174.473L84.361 175.089L84.9769 175.876L85.7639 175.26Z"
          fill="#D8531A"
        />
        <path
          d="M86.3799 176.047L85.764 175.26L84.977 175.876L85.5928 176.663L86.3799 176.047Z"
          fill="#F7852F"
        />
        <path
          d="M88.8492 179.203L88.2313 178.413L87.4443 179.029L88.0621 179.819L88.8492 179.203Z"
          fill="#D8531A"
        />
        <path
          d="M89.4651 179.99L88.8492 179.203L88.0622 179.819L88.678 180.606L89.4651 179.99Z"
          fill="#F7852F"
        />
        <path
          d="M90.0829 180.779L89.4651 179.99L88.678 180.606L89.2959 181.395L90.0829 180.779Z"
          fill="#F7852F"
        />
        <path
          d="M90.6988 181.566L90.0829 180.779L89.2958 181.395L89.9117 182.182L90.6988 181.566Z"
          fill="#D8531A"
        />
        <path
          d="M93.1662 184.72L92.5503 183.933L91.7632 184.548L92.3791 185.336L93.1662 184.72Z"
          fill="#809AA7"
        />
        <path
          d="M81.2777 171.148L80.6599 170.359L79.8728 170.975L80.4907 171.764L81.2777 171.148Z"
          fill="#D8531A"
        />
        <path
          d="M81.8937 171.935L81.2778 171.148L80.4908 171.764L81.1066 172.551L81.8937 171.935Z"
          fill="#F7852F"
        />
        <path
          d="M85.5928 176.663L84.9769 175.876L84.1899 176.492L84.8057 177.279L85.5928 176.663Z"
          fill="#D8531A"
        />
        <path
          d="M88.678 180.606L88.0621 179.819L87.2751 180.434L87.8909 181.221L88.678 180.606Z"
          fill="#D8531A"
        />
        <path
          d="M89.2958 181.395L88.678 180.606L87.8909 181.221L88.5087 182.011L89.2958 181.395Z"
          fill="#F7852F"
        />
        <path
          d="M81.104 172.553L80.4882 171.766L79.6986 172.384L80.3144 173.171L81.104 172.553Z"
          fill="#D8531A"
        />
        <path
          d="M88.5063 182.013L87.8884 181.223L87.0988 181.841L87.7167 182.631L88.5063 182.013Z"
          fill="#D8531A"
        />
      </g>
      <g opacity="0.3" clipPath="url(#clip9_0_1)">
        <path
          d="M346.475 424.555L345.977 423.982L346.55 423.485L347.048 424.058L346.475 424.555Z"
          fill="#F0FFFF"
        />
        <path
          d="M346.974 425.131L346.476 424.557L347.049 424.06L347.547 424.633L346.974 425.131Z"
          fill="#D0E1E9"
        />
        <path
          d="M347.472 425.704L346.974 425.131L347.547 424.633L348.045 425.206L347.472 425.704Z"
          fill="#809AA7"
        />
        <path
          d="M346.552 423.486L346.052 422.911L346.626 422.414L347.125 422.989L346.552 423.486Z"
          fill="#F0FFFF"
        />
        <path
          d="M347.048 424.058L346.55 423.485L347.123 422.987L347.621 423.56L347.048 424.058Z"
          fill="#D0E1E9"
        />
        <path
          d="M347.547 424.633L347.049 424.06L347.623 423.562L348.12 424.135L347.547 424.633Z"
          fill="#D0E1E9"
        />
        <path
          d="M348.045 425.206L347.547 424.633L348.12 424.135L348.618 424.708L348.045 425.206Z"
          fill="#D0E1E9"
        />
        <path
          d="M348.546 425.783L348.046 425.208L348.619 424.71L349.119 425.285L348.546 425.783Z"
          fill="#809AA7"
        />
        <path
          d="M346.628 422.412L346.13 421.839L346.703 421.341L347.201 421.914L346.628 422.412Z"
          fill="#F0FFFF"
        />
        <path
          d="M347.127 422.987L346.628 422.412L347.201 421.914L347.7 422.489L347.127 422.987Z"
          fill="#D0E1E9"
        />
        <path
          d="M347.623 423.559L347.125 422.985L347.698 422.488L348.196 423.061L347.623 423.559Z"
          fill="#D0E1E9"
        />
        <path
          d="M348.122 424.134L347.624 423.56L348.198 423.063L348.695 423.636L348.122 424.134Z"
          fill="#D0E1E9"
        />
        <path
          d="M348.62 424.707L348.122 424.134L348.695 423.636L349.193 424.209L348.62 424.707Z"
          fill="#D0E1E9"
        />
        <path
          d="M349.121 425.284L348.621 424.709L349.195 424.211L349.694 424.786L349.121 425.284Z"
          fill="#D0E1E9"
        />
        <path
          d="M349.617 425.855L349.119 425.282L349.692 424.784L350.19 425.357L349.617 425.855Z"
          fill="#809AA7"
        />
        <path
          d="M347.201 421.914L346.703 421.341L347.278 420.842L347.776 421.415L347.201 421.914Z"
          fill="#FFAD32"
        />
        <path
          d="M347.7 422.49L347.201 421.914L347.776 421.415L348.275 421.99L347.7 422.49Z"
          fill="#F7852F"
        />
        <path
          d="M348.196 423.061L347.698 422.488L348.273 421.988L348.771 422.562L348.196 423.061Z"
          fill="#F7852F"
        />
        <path
          d="M348.695 423.636L348.198 423.063L348.773 422.563L349.27 423.137L348.695 423.636Z"
          fill="#F7852F"
        />
        <path
          d="M349.193 424.209L348.695 423.636L349.27 423.137L349.768 423.71L349.193 424.209Z"
          fill="#F7852F"
        />
        <path
          d="M349.694 424.786L349.195 424.211L349.77 423.712L350.269 424.287L349.694 424.786Z"
          fill="#F7852F"
        />
        <path
          d="M350.19 425.357L349.692 424.784L350.267 424.285L350.765 424.858L350.19 425.357Z"
          fill="#D8531A"
        />
        <path
          d="M347.776 421.415L347.278 420.842L347.851 420.344L348.349 420.918L347.776 421.415Z"
          fill="#F0FFFF"
        />
        <path
          d="M348.275 421.99L347.776 421.415L348.349 420.918L348.848 421.493L348.275 421.99Z"
          fill="#D0E1E9"
        />
        <path
          d="M348.771 422.562L348.273 421.988L348.847 421.491L349.344 422.064L348.771 422.562Z"
          fill="#D0E1E9"
        />
        <path
          d="M349.27 423.137L348.773 422.563L349.346 422.066L349.844 422.639L349.27 423.137Z"
          fill="#D0E1E9"
        />
        <path
          d="M349.768 423.71L349.27 423.137L349.844 422.639L350.341 423.212L349.768 423.71Z"
          fill="#D0E1E9"
        />
        <path
          d="M350.269 424.287L349.77 423.712L350.343 423.214L350.842 423.789L350.269 424.287Z"
          fill="#D0E1E9"
        />
        <path
          d="M350.765 424.858L350.267 424.285L350.841 423.787L351.338 424.361L350.765 424.858Z"
          fill="#809AA7"
        />
        <path
          d="M348.349 420.918L347.851 420.344L348.425 419.847L348.922 420.42L348.349 420.918Z"
          fill="#F0FFFF"
        />
        <path
          d="M348.848 421.493L348.349 420.918L348.922 420.42L349.421 420.995L348.848 421.493Z"
          fill="#D0E1E9"
        />
        <path
          d="M349.344 422.064L348.847 421.491L349.42 420.993L349.918 421.566L349.344 422.064Z"
          fill="#D0E1E9"
        />
        <path
          d="M349.844 422.639L349.346 422.066L349.919 421.568L350.417 422.141L349.844 422.639Z"
          fill="#394B53"
        />
        <path
          d="M350.341 423.212L349.844 422.639L350.417 422.141L350.915 422.715L350.341 423.212Z"
          fill="#D0E1E9"
        />
        <path
          d="M350.842 423.789L350.343 423.214L350.916 422.716L351.415 423.291L350.842 423.789Z"
          fill="#D0E1E9"
        />
        <path
          d="M351.338 424.361L350.841 423.787L351.414 423.29L351.911 423.863L351.338 424.361Z"
          fill="#809AA7"
        />
        <path
          d="M348.425 419.843L347.927 419.27L348.5 418.772L348.998 419.346L348.425 419.843Z"
          fill="#F0FFFF"
        />
        <path
          d="M348.924 420.418L348.426 419.845L349 419.347L349.497 419.921L348.924 420.418Z"
          fill="#F0FFFF"
        />
        <path
          d="M349.423 420.993L348.924 420.418L349.497 419.921L349.997 420.496L349.423 420.993Z"
          fill="#D0E1E9"
        />
        <path
          d="M349.919 421.565L349.422 420.991L349.995 420.494L350.493 421.067L349.919 421.565Z"
          fill="#394B53"
        />
        <path
          d="M350.419 422.14L349.921 421.567L350.494 421.069L350.992 421.642L350.419 422.14Z"
          fill="#394B53"
        />
        <path
          d="M350.916 422.713L350.419 422.14L350.992 421.642L351.49 422.215L350.916 422.713Z"
          fill="#394B53"
        />
        <path
          d="M351.417 423.29L350.918 422.715L351.491 422.217L351.99 422.792L351.417 423.29Z"
          fill="#D0E1E9"
        />
        <path
          d="M351.913 423.861L351.416 423.288L351.989 422.79L352.486 423.364L351.913 423.861Z"
          fill="#809AA7"
        />
        <path
          d="M352.412 424.436L351.915 423.863L352.488 423.365L352.986 423.939L352.412 424.436Z"
          fill="#809AA7"
        />
        <path
          d="M344.513 414.179L344.015 413.606L344.59 413.107L345.088 413.68L344.513 414.179Z"
          fill="#373B3E"
        />
        <path
          d="M345.01 414.752L344.513 414.179L345.088 413.68L345.585 414.253L345.01 414.752Z"
          fill="#26282A"
        />
        <path
          d="M348.5 418.772L348.003 418.199L348.578 417.7L349.075 418.273L348.5 418.772Z"
          fill="#F0FFFF"
        />
        <path
          d="M348.998 419.346L348.5 418.772L349.075 418.273L349.573 418.846L348.998 419.346Z"
          fill="#D0E1E9"
        />
        <path
          d="M349.497 419.921L349 419.347L349.575 418.848L350.072 419.421L349.497 419.921Z"
          fill="#F0FFFF"
        />
        <path
          d="M349.997 420.496L349.497 419.921L350.072 419.421L350.572 419.996L349.997 420.496Z"
          fill="#D0E1E9"
        />
        <path
          d="M350.493 421.067L349.995 420.494L350.57 419.995L351.068 420.568L350.493 421.067Z"
          fill="#394B53"
        />
        <path
          d="M350.992 421.642L350.494 421.069L351.069 420.57L351.567 421.143L350.992 421.642Z"
          fill="#51DFF2"
        />
        <path
          d="M351.489 422.215L350.992 421.642L351.567 421.143L352.065 421.716L351.489 422.215Z"
          fill="#394B53"
        />
        <path
          d="M351.99 422.792L351.491 422.217L352.066 421.718L352.565 422.293L351.99 422.792Z"
          fill="#D0E1E9"
        />
        <path
          d="M352.486 423.364L351.989 422.79L352.564 422.291L353.061 422.864L352.486 423.364Z"
          fill="#809AA7"
        />
        <path
          d="M352.986 423.939L352.488 423.365L353.063 422.866L353.561 423.439L352.986 423.939Z"
          fill="#D0E1E9"
        />
        <path
          d="M353.483 424.512L352.986 423.939L353.561 423.439L354.058 424.013L353.483 424.512Z"
          fill="#809AA7"
        />
        <path
          d="M356.972 428.53L356.474 427.957L357.049 427.457L357.547 428.031L356.972 428.53Z"
          fill="#373B3E"
        />
        <path
          d="M357.471 429.105L356.973 428.532L357.548 428.032L358.046 428.606L357.471 429.105Z"
          fill="#26282A"
        />
        <path
          d="M345.088 413.68L344.59 413.107L345.163 412.609L345.661 413.182L345.088 413.68Z"
          fill="#43484B"
        />
        <path
          d="M345.585 414.253L345.088 413.68L345.661 413.182L346.159 413.756L345.585 414.253Z"
          fill="#373B3E"
        />
        <path
          d="M348.576 417.698L348.078 417.125L348.652 416.627L349.149 417.2L348.576 417.698Z"
          fill="#F0FFFF"
        />
        <path
          d="M349.075 418.273L348.578 417.7L349.151 417.202L349.649 417.775L349.075 418.273Z"
          fill="#9AB0BB"
        />
        <path
          d="M349.573 418.846L349.075 418.273L349.649 417.775L350.146 418.349L349.573 418.846Z"
          fill="#D0E1E9"
        />
        <path
          d="M350.072 419.421L349.575 418.848L350.148 418.35L350.646 418.924L350.072 419.421Z"
          fill="#F0FFFF"
        />
        <path
          d="M350.572 419.996L350.072 419.421L350.646 418.924L351.145 419.499L350.572 419.996Z"
          fill="#D0E1E9"
        />
        <path
          d="M351.068 420.568L350.57 419.995L351.143 419.497L351.641 420.07L351.068 420.568Z"
          fill="#394B53"
        />
        <path
          d="M351.567 421.143L351.069 420.57L351.642 420.072L352.14 420.645L351.567 421.143Z"
          fill="#05B8CF"
        />
        <path
          d="M352.065 421.716L351.567 421.143L352.14 420.645L352.638 421.218L352.065 421.716Z"
          fill="#394B53"
        />
        <path
          d="M352.565 422.293L352.066 421.718L352.639 421.22L353.139 421.795L352.565 422.293Z"
          fill="#D0E1E9"
        />
        <path
          d="M353.062 422.864L352.564 422.291L353.137 421.793L353.635 422.367L353.062 422.864Z"
          fill="#809AA7"
        />
        <path
          d="M353.561 423.439L353.063 422.866L353.636 422.368L354.134 422.942L353.561 423.439Z"
          fill="#D0E1E9"
        />
        <path
          d="M354.058 424.013L353.561 423.439L354.134 422.942L354.632 423.515L354.058 424.013Z"
          fill="#F0FFFF"
        />
        <path
          d="M354.559 424.589L354.06 424.014L354.633 423.517L355.133 424.092L354.559 424.589Z"
          fill="#809AA7"
        />
        <path
          d="M357.547 428.031L357.049 427.457L357.622 426.96L358.12 427.533L357.547 428.031Z"
          fill="#43484B"
        />
        <path
          d="M358.046 428.606L357.548 428.033L358.122 427.535L358.619 428.108L358.046 428.606Z"
          fill="#373B3E"
        />
        <path
          d="M345.661 413.182L345.163 412.609L345.736 412.111L346.234 412.685L345.661 413.182Z"
          fill="#373B3E"
        />
        <path
          d="M346.159 413.756L345.661 413.182L346.234 412.685L346.732 413.258L346.159 413.756Z"
          fill="#26282A"
        />
        <path
          d="M348.652 416.627L348.154 416.054L348.727 415.556L349.225 416.129L348.652 416.627Z"
          fill="#F0FFFF"
        />
        <path
          d="M349.149 417.2L348.652 416.627L349.225 416.129L349.723 416.703L349.149 417.2Z"
          fill="#D0E1E9"
        />
        <path
          d="M349.649 417.775L349.151 417.202L349.724 416.705L350.222 417.278L349.649 417.775Z"
          fill="#9AB0BB"
        />
        <path
          d="M350.146 418.349L349.649 417.775L350.222 417.278L350.72 417.851L350.146 418.349Z"
          fill="#D0E1E9"
        />
        <path
          d="M350.645 418.924L350.148 418.35L350.721 417.853L351.219 418.426L350.645 418.924Z"
          fill="#F0FFFF"
        />
        <path
          d="M351.145 419.499L350.646 418.924L351.219 418.426L351.718 419.001L351.145 419.499Z"
          fill="#D0E1E9"
        />
        <path
          d="M351.641 420.07L351.143 419.497L351.716 418.999L352.214 419.572L351.641 420.07Z"
          fill="#394B53"
        />
        <path
          d="M352.14 420.645L351.642 420.072L352.216 419.574L352.713 420.147L352.14 420.645Z"
          fill="#51DFF2"
        />
        <path
          d="M352.638 421.218L352.14 420.645L352.713 420.148L353.211 420.721L352.638 421.218Z"
          fill="#394B53"
        />
        <path
          d="M353.139 421.795L352.639 421.22L353.213 420.723L353.712 421.298L353.139 421.795Z"
          fill="#D0E1E9"
        />
        <path
          d="M353.635 422.367L353.137 421.793L353.71 421.296L354.208 421.869L353.635 422.367Z"
          fill="#809AA7"
        />
        <path
          d="M354.134 422.942L353.636 422.368L354.21 421.871L354.707 422.444L354.134 422.942Z"
          fill="#D0E1E9"
        />
        <path
          d="M354.632 423.515L354.134 422.942L354.707 422.444L355.205 423.017L354.632 423.515Z"
          fill="#F0FFFF"
        />
        <path
          d="M355.133 424.092L354.633 423.517L355.207 423.019L355.706 423.594L355.133 424.092Z"
          fill="#D0E1E9"
        />
        <path
          d="M355.629 424.663L355.131 424.09L355.704 423.592L356.202 424.166L355.629 424.663Z"
          fill="#809AA7"
        />
        <path
          d="M358.12 427.533L357.622 426.96L358.196 426.462L358.693 427.035L358.12 427.533Z"
          fill="#373B3E"
        />
        <path
          d="M358.619 428.108L358.122 427.535L358.695 427.037L359.193 427.61L358.619 428.108Z"
          fill="#26282A"
        />
        <path
          d="M346.236 412.683L345.738 412.11L346.312 411.612L346.809 412.185L346.236 412.683Z"
          fill="#373B3E"
        />
        <path
          d="M346.734 413.256L346.236 412.683L346.809 412.185L347.307 412.759L346.734 413.256Z"
          fill="#26282A"
        />
        <path
          d="M349.227 416.128L348.729 415.555L349.302 415.057L349.8 415.63L349.227 416.128Z"
          fill="#F0FFFF"
        />
        <path
          d="M349.724 416.701L349.227 416.128L349.8 415.63L350.298 416.203L349.724 416.701Z"
          fill="#D0E1E9"
        />
        <path
          d="M350.224 417.276L349.726 416.703L350.299 416.205L350.797 416.778L350.224 417.276Z"
          fill="#9AB0BB"
        />
        <path
          d="M350.721 417.849L350.224 417.276L350.797 416.778L351.295 417.352L350.721 417.849Z"
          fill="#D0E1E9"
        />
        <path
          d="M351.221 418.424L350.723 417.851L351.296 417.354L351.794 417.927L351.221 418.424Z"
          fill="#F0FFFF"
        />
        <path
          d="M351.72 418.999L351.221 418.424L351.794 417.927L352.293 418.502L351.72 418.999Z"
          fill="#D0E1E9"
        />
        <path
          d="M352.216 419.571L351.718 418.998L352.291 418.5L352.789 419.073L352.216 419.571Z"
          fill="#394B53"
        />
        <path
          d="M352.715 420.146L352.218 419.573L352.791 419.075L353.288 419.648L352.715 420.146Z"
          fill="#05B8CF"
        />
        <path
          d="M353.213 420.719L352.715 420.146L353.288 419.648L353.786 420.221L353.213 420.719Z"
          fill="#394B53"
        />
        <path
          d="M353.714 421.296L353.214 420.721L353.788 420.223L354.287 420.798L353.714 421.296Z"
          fill="#D0E1E9"
        />
        <path
          d="M354.21 421.867L353.712 421.294L354.285 420.797L354.783 421.37L354.21 421.867Z"
          fill="#809AA7"
        />
        <path
          d="M354.709 422.442L354.211 421.869L354.785 421.372L355.282 421.945L354.709 422.442Z"
          fill="#D0E1E9"
        />
        <path
          d="M355.207 423.016L354.709 422.442L355.282 421.945L355.78 422.518L355.207 423.016Z"
          fill="#F0FFFF"
        />
        <path
          d="M355.708 423.593L355.208 423.018L355.781 422.52L356.281 423.095L355.708 423.593Z"
          fill="#D0E1E9"
        />
        <path
          d="M356.204 424.164L355.706 423.591L356.279 423.093L356.777 423.666L356.204 424.164Z"
          fill="#809AA7"
        />
        <path
          d="M358.695 427.034L358.198 426.461L358.771 425.963L359.268 426.536L358.695 427.034Z"
          fill="#373B3E"
        />
        <path
          d="M359.194 427.609L358.697 427.036L359.27 426.538L359.768 427.111L359.194 427.609Z"
          fill="#26282A"
        />
        <path
          d="M346.809 412.185L346.312 411.612L346.887 411.113L347.384 411.686L346.809 412.185Z"
          fill="#373B3E"
        />
        <path
          d="M347.307 412.759L346.809 412.185L347.384 411.686L347.882 412.259L347.307 412.759Z"
          fill="#26282A"
        />
        <path
          d="M349.8 415.63L349.302 415.057L349.877 414.558L350.375 415.131L349.8 415.63Z"
          fill="#F0FFFF"
        />
        <path
          d="M350.298 416.203L349.8 415.63L350.375 415.131L350.873 415.704L350.298 416.203Z"
          fill="#D0E1E9"
        />
        <path
          d="M350.797 416.778L350.299 416.205L350.874 415.706L351.372 416.279L350.797 416.778Z"
          fill="#9AB0BB"
        />
        <path
          d="M351.295 417.352L350.797 416.778L351.372 416.279L351.87 416.852L351.295 417.352Z"
          fill="#D0E1E9"
        />
        <path
          d="M351.794 417.927L351.296 417.354L351.871 416.854L352.369 417.427L351.794 417.927Z"
          fill="#F0FFFF"
        />
        <path
          d="M352.293 418.502L351.794 417.927L352.369 417.427L352.868 418.003L352.293 418.502Z"
          fill="#D0E1E9"
        />
        <path
          d="M352.789 419.073L352.292 418.5L352.867 418.001L353.364 418.574L352.789 419.073Z"
          fill="#394B53"
        />
        <path
          d="M353.288 419.648L352.791 419.075L353.366 418.576L353.863 419.149L353.288 419.648Z"
          fill="#058499"
        />
        <path
          d="M353.786 420.221L353.288 419.648L353.864 419.149L354.361 419.722L353.786 420.221Z"
          fill="#394B53"
        />
        <path
          d="M354.287 420.798L353.788 420.223L354.363 419.724L354.862 420.299L354.287 420.798Z"
          fill="#D0E1E9"
        />
        <path
          d="M354.783 421.37L354.285 420.797L354.86 420.297L355.358 420.87L354.783 421.37Z"
          fill="#809AA7"
        />
        <path
          d="M355.282 421.945L354.785 421.372L355.36 420.872L355.857 421.446L355.282 421.945Z"
          fill="#D0E1E9"
        />
        <path
          d="M355.78 422.518L355.282 421.945L355.857 421.446L356.355 422.019L355.78 422.518Z"
          fill="#F0FFFF"
        />
        <path
          d="M356.281 423.095L355.781 422.52L356.357 422.021L356.856 422.596L356.281 423.095Z"
          fill="#D0E1E9"
        />
        <path
          d="M356.777 423.666L356.279 423.093L356.854 422.594L357.352 423.167L356.777 423.666Z"
          fill="#809AA7"
        />
        <path
          d="M359.268 426.536L358.771 425.963L359.346 425.464L359.843 426.037L359.268 426.536Z"
          fill="#373B3E"
        />
        <path
          d="M359.768 427.111L359.27 426.538L359.845 426.039L360.343 426.612L359.768 427.111Z"
          fill="#26282A"
        />
        <path
          d="M347.384 411.686L346.887 411.113L347.46 410.615L347.957 411.188L347.384 411.686Z"
          fill="#373B3E"
        />
        <path
          d="M347.882 412.259L347.384 411.686L347.957 411.188L348.455 411.762L347.882 412.259Z"
          fill="#26282A"
        />
        <path
          d="M350.375 415.131L349.877 414.558L350.451 414.06L350.948 414.633L350.375 415.131Z"
          fill="#F0FFFF"
        />
        <path
          d="M350.873 415.704L350.375 415.131L350.948 414.633L351.446 415.206L350.873 415.704Z"
          fill="#D0E1E9"
        />
        <path
          d="M351.372 416.279L350.874 415.706L351.447 415.208L351.945 415.782L351.372 416.279Z"
          fill="#9AB0BB"
        />
        <path
          d="M351.87 416.852L351.372 416.279L351.945 415.782L352.443 416.355L351.87 416.852Z"
          fill="#D0E1E9"
        />
        <path
          d="M352.369 417.427L351.871 416.854L352.444 416.357L352.942 416.93L352.369 417.427Z"
          fill="#F0FFFF"
        />
        <path
          d="M352.868 418.003L352.369 417.427L352.942 416.93L353.441 417.505L352.868 418.003Z"
          fill="#D0E1E9"
        />
        <path
          d="M353.364 418.574L352.867 418.001L353.44 417.503L353.937 418.076L353.364 418.574Z"
          fill="#394B53"
        />
        <path
          d="M353.864 419.149L353.366 418.576L353.939 418.078L354.437 418.651L353.864 419.149Z"
          fill="#394B53"
        />
        <path
          d="M354.361 419.722L353.864 419.149L354.437 418.651L354.934 419.225L354.361 419.722Z"
          fill="#394B53"
        />
        <path
          d="M354.862 420.299L354.363 419.724L354.936 419.226L355.435 419.801L354.862 420.299Z"
          fill="#D0E1E9"
        />
        <path
          d="M355.358 420.87L354.86 420.297L355.434 419.8L355.931 420.373L355.358 420.87Z"
          fill="#809AA7"
        />
        <path
          d="M355.857 421.446L355.36 420.872L355.933 420.375L356.431 420.948L355.857 421.446Z"
          fill="#D0E1E9"
        />
        <path
          d="M356.355 422.019L355.857 421.446L356.431 420.948L356.928 421.521L356.355 422.019Z"
          fill="#F0FFFF"
        />
        <path
          d="M356.856 422.596L356.357 422.021L356.93 421.523L357.429 422.098L356.856 422.596Z"
          fill="#D0E1E9"
        />
        <path
          d="M357.352 423.167L356.854 422.594L357.427 422.096L357.925 422.669L357.352 423.167Z"
          fill="#809AA7"
        />
        <path
          d="M359.843 426.037L359.346 425.464L359.919 424.966L360.417 425.539L359.843 426.037Z"
          fill="#373B3E"
        />
        <path
          d="M360.343 426.612L359.845 426.039L360.418 425.541L360.916 426.114L360.343 426.612Z"
          fill="#26282A"
        />
        <path
          d="M347.958 411.188L347.46 410.615L348.033 410.118L348.531 410.691L347.958 411.188Z"
          fill="#373B3E"
        />
        <path
          d="M348.455 411.762L347.958 411.188L348.531 410.691L349.028 411.264L348.455 411.762Z"
          fill="#26282A"
        />
        <path
          d="M350.449 414.058L349.951 413.485L350.525 412.987L351.022 413.561L350.449 414.058Z"
          fill="#F0FFFF"
        />
        <path
          d="M350.948 414.633L350.451 414.06L351.024 413.562L351.521 414.136L350.948 414.633Z"
          fill="#D0E1E9"
        />
        <path
          d="M351.446 415.206L350.948 414.633L351.521 414.136L352.019 414.709L351.446 415.206Z"
          fill="#D0E1E9"
        />
        <path
          d="M351.945 415.782L351.448 415.208L352.021 414.711L352.518 415.284L351.945 415.782Z"
          fill="#9AB0BB"
        />
        <path
          d="M352.443 416.355L351.945 415.782L352.518 415.284L353.016 415.857L352.443 416.355Z"
          fill="#D0E1E9"
        />
        <path
          d="M352.942 416.93L352.444 416.357L353.018 415.859L353.515 416.432L352.942 416.93Z"
          fill="#F0FFFF"
        />
        <path
          d="M353.441 417.505L352.942 416.93L353.515 416.432L354.015 417.007L353.441 417.505Z"
          fill="#D0E1E9"
        />
        <path
          d="M353.937 418.076L353.44 417.503L354.013 417.005L354.511 417.579L353.937 418.076Z"
          fill="#D0E1E9"
        />
        <path
          d="M354.437 418.651L353.939 418.078L354.512 417.58L355.01 418.154L354.437 418.651Z"
          fill="#D0E1E9"
        />
        <path
          d="M354.934 419.225L354.437 418.651L355.01 418.154L355.508 418.727L354.934 419.225Z"
          fill="#D0E1E9"
        />
        <path
          d="M355.435 419.801L354.936 419.226L355.509 418.729L356.008 419.304L355.435 419.801Z"
          fill="#D0E1E9"
        />
        <path
          d="M355.931 420.373L355.434 419.8L356.007 419.302L356.504 419.875L355.931 420.373Z"
          fill="#809AA7"
        />
        <path
          d="M356.431 420.948L355.933 420.375L356.506 419.877L357.004 420.45L356.431 420.948Z"
          fill="#D0E1E9"
        />
        <path
          d="M356.928 421.521L356.431 420.948L357.004 420.45L357.501 421.023L356.928 421.521Z"
          fill="#F0FFFF"
        />
        <path
          d="M357.429 422.098L356.93 421.523L357.503 421.025L358.002 421.6L357.429 422.098Z"
          fill="#D0E1E9"
        />
        <path
          d="M357.925 422.669L357.427 422.096L358.001 421.598L358.498 422.172L357.925 422.669Z"
          fill="#D0E1E9"
        />
        <path
          d="M358.424 423.244L357.927 422.671L358.5 422.174L358.998 422.747L358.424 423.244Z"
          fill="#809AA7"
        />
        <path
          d="M360.417 425.539L359.919 424.966L360.492 424.468L360.99 425.041L360.417 425.539Z"
          fill="#373B3E"
        />
        <path
          d="M360.916 426.114L360.418 425.541L360.991 425.043L361.489 425.617L360.916 426.114Z"
          fill="#26282A"
        />
        <path
          d="M348.531 410.691L348.033 410.118L348.608 409.618L349.106 410.192L348.531 410.691Z"
          fill="#373B3E"
        />
        <path
          d="M349.028 411.264L348.531 410.691L349.106 410.192L349.603 410.765L349.028 411.264Z"
          fill="#26282A"
        />
        <path
          d="M350.525 412.987L350.027 412.414L350.602 411.915L351.1 412.488L350.525 412.987Z"
          fill="#F0FFFF"
        />
        <path
          d="M351.022 413.561L350.525 412.987L351.1 412.488L351.597 413.061L351.022 413.561Z"
          fill="#F0FFFF"
        />
        <path
          d="M351.521 414.136L351.024 413.562L351.599 413.063L352.097 413.636L351.521 414.136Z"
          fill="#D0E1E9"
        />
        <path
          d="M352.019 414.709L351.521 414.136L352.097 413.636L352.594 414.21L352.019 414.709Z"
          fill="#D0E1E9"
        />
        <path
          d="M352.518 415.284L352.021 414.711L352.596 414.211L353.093 414.785L352.518 415.284Z"
          fill="#9AB0BB"
        />
        <path
          d="M353.016 415.857L352.518 415.284L353.093 414.785L353.591 415.358L353.016 415.857Z"
          fill="#F0FFFF"
        />
        <path
          d="M353.515 416.432L353.018 415.859L353.593 415.36L354.09 415.933L353.515 416.432Z"
          fill="#D0E1E9"
        />
        <path
          d="M354.015 417.007L353.515 416.432L354.09 415.933L354.59 416.508L354.015 417.007Z"
          fill="#D0E1E9"
        />
        <path
          d="M354.511 417.579L354.013 417.005L354.588 416.506L355.086 417.079L354.511 417.579Z"
          fill="#394B53"
        />
        <path
          d="M355.01 418.154L354.512 417.58L355.087 417.081L355.585 417.654L355.01 418.154Z"
          fill="#394B53"
        />
        <path
          d="M355.508 418.727L355.01 418.154L355.585 417.654L356.083 418.228L355.508 418.727Z"
          fill="#394B53"
        />
        <path
          d="M356.008 419.304L355.509 418.729L356.084 418.229L356.583 418.804L356.008 419.304Z"
          fill="#D0E1E9"
        />
        <path
          d="M356.505 419.875L356.007 419.302L356.582 418.803L357.08 419.376L356.505 419.875Z"
          fill="#D0E1E9"
        />
        <path
          d="M357.004 420.45L356.506 419.877L357.081 419.378L357.579 419.951L357.004 420.45Z"
          fill="#809AA7"
        />
        <path
          d="M357.501 421.023L357.004 420.45L357.579 419.951L358.077 420.524L357.501 421.023Z"
          fill="#F0FFFF"
        />
        <path
          d="M358.002 421.6L357.503 421.025L358.078 420.526L358.577 421.101L358.002 421.6Z"
          fill="#D0E1E9"
        />
        <path
          d="M358.498 422.172L358.001 421.598L358.576 421.099L359.073 421.672L358.498 422.172Z"
          fill="#D0E1E9"
        />
        <path
          d="M358.998 422.747L358.5 422.174L359.075 421.674L359.573 422.247L358.998 422.747Z"
          fill="#9AB0BB"
        />
        <path
          d="M359.495 423.32L358.998 422.747L359.573 422.247L360.07 422.821L359.495 423.32Z"
          fill="#809AA7"
        />
        <path
          d="M360.99 425.041L360.492 424.468L361.067 423.969L361.565 424.542L360.99 425.041Z"
          fill="#373B3E"
        />
        <path
          d="M361.489 425.617L360.991 425.043L361.567 424.544L362.064 425.117L361.489 425.617Z"
          fill="#26282A"
        />
        <path
          d="M348.109 409.043L347.611 408.47L348.184 407.972L348.682 408.546L348.109 409.043Z"
          fill="#F0FFFF"
        />
        <path
          d="M349.106 410.192L348.608 409.618L349.181 409.121L349.679 409.694L349.106 410.192Z"
          fill="#373B3E"
        />
        <path
          d="M349.603 410.765L349.106 410.191L349.679 409.694L350.177 410.267L349.603 410.765Z"
          fill="#26282A"
        />
        <path
          d="M350.6 411.913L350.103 411.34L350.676 410.842L351.174 411.415L350.6 411.913Z"
          fill="#F0FFFF"
        />
        <path
          d="M351.1 412.488L350.602 411.915L351.175 411.417L351.673 411.99L351.1 412.488Z"
          fill="#D0E1E9"
        />
        <path
          d="M351.597 413.061L351.1 412.488L351.673 411.99L352.17 412.564L351.597 413.061Z"
          fill="#F0FFFF"
        />
        <path
          d="M352.097 413.636L351.599 413.063L352.172 412.565L352.67 413.139L352.097 413.636Z"
          fill="#D0E1E9"
        />
        <path
          d="M352.594 414.21L352.097 413.636L352.67 413.139L353.167 413.712L352.594 414.21Z"
          fill="#D0E1E9"
        />
        <path
          d="M353.093 414.785L352.596 414.211L353.169 413.714L353.667 414.287L353.093 414.785Z"
          fill="#9AB0BB"
        />
        <path
          d="M353.591 415.358L353.094 414.785L353.667 414.287L354.164 414.86L353.591 415.358Z"
          fill="#F0FFFF"
        />
        <path
          d="M354.09 415.933L353.593 415.36L354.166 414.862L354.664 415.435L354.09 415.933Z"
          fill="#D0E1E9"
        />
        <path
          d="M354.59 416.508L354.09 415.933L354.664 415.435L355.163 416.01L354.59 416.508Z"
          fill="#D0E1E9"
        />
        <path
          d="M355.086 417.079L354.588 416.506L355.161 416.008L355.659 416.582L355.086 417.079Z"
          fill="#394B53"
        />
        <path
          d="M355.585 417.654L355.087 417.081L355.661 416.584L356.158 417.157L355.585 417.654Z"
          fill="#51DFF2"
        />
        <path
          d="M356.083 418.228L355.585 417.654L356.158 417.157L356.656 417.73L356.083 418.228Z"
          fill="#394B53"
        />
        <path
          d="M356.583 418.804L356.084 418.229L356.657 417.732L357.157 418.307L356.583 418.804Z"
          fill="#D0E1E9"
        />
        <path
          d="M357.08 419.376L356.582 418.803L357.155 418.305L357.653 418.878L357.08 419.376Z"
          fill="#D0E1E9"
        />
        <path
          d="M357.579 419.951L357.081 419.378L357.654 418.88L358.152 419.453L357.579 419.951Z"
          fill="#809AA7"
        />
        <path
          d="M358.077 420.524L357.579 419.951L358.152 419.453L358.65 420.026L358.077 420.524Z"
          fill="#F0FFFF"
        />
        <path
          d="M358.577 421.101L358.078 420.526L358.651 420.028L359.151 420.603L358.577 421.101Z"
          fill="#D0E1E9"
        />
        <path
          d="M359.073 421.672L358.576 421.099L359.149 420.602L359.647 421.175L359.073 421.672Z"
          fill="#D0E1E9"
        />
        <path
          d="M359.573 422.247L359.075 421.674L359.648 421.177L360.146 421.75L359.573 422.247Z"
          fill="#9AB0BB"
        />
        <path
          d="M360.07 422.821L359.573 422.247L360.146 421.75L360.644 422.323L360.07 422.821Z"
          fill="#D0E1E9"
        />
        <path
          d="M360.57 423.396L360.072 422.823L360.645 422.325L361.143 422.898L360.57 423.396Z"
          fill="#809AA7"
        />
        <path
          d="M361.565 424.542L361.067 423.969L361.64 423.471L362.138 424.045L361.565 424.542Z"
          fill="#373B3E"
        />
        <path
          d="M362.064 425.117L361.567 424.544L362.14 424.046L362.637 424.62L362.064 425.117Z"
          fill="#26282A"
        />
        <path
          d="M363.061 426.266L362.563 425.692L363.137 425.195L363.634 425.768L363.061 426.266Z"
          fill="#F0FFFF"
        />
        <path
          d="M348.682 408.546L348.184 407.972L348.758 407.475L349.255 408.048L348.682 408.546Z"
          fill="#D0E1E9"
        />
        <path
          d="M349.679 409.694L349.181 409.121L349.754 408.623L350.252 409.196L349.679 409.694Z"
          fill="#D0E1E9"
        />
        <path
          d="M350.177 410.267L349.679 409.694L350.252 409.196L350.75 409.769L350.177 410.267Z"
          fill="#9AB0BB"
        />
        <path
          d="M350.676 410.842L350.178 410.269L350.751 409.771L351.249 410.344L350.676 410.842Z"
          fill="#809AA7"
        />
        <path
          d="M351.174 411.415L350.676 410.842L351.249 410.344L351.747 410.918L351.174 411.415Z"
          fill="#D0E1E9"
        />
        <path
          d="M351.673 411.99L351.175 411.417L351.748 410.92L352.246 411.493L351.673 411.99Z"
          fill="#D0E1E9"
        />
        <path
          d="M352.171 412.564L351.673 411.99L352.246 411.493L352.744 412.066L352.171 412.564Z"
          fill="#F0FFFF"
        />
        <path
          d="M352.67 413.139L352.172 412.565L352.745 412.068L353.243 412.641L352.67 413.139Z"
          fill="#D0E1E9"
        />
        <path
          d="M353.167 413.712L352.67 413.139L353.243 412.641L353.741 413.214L353.167 413.712Z"
          fill="#D0E1E9"
        />
        <path
          d="M353.667 414.287L353.169 413.714L353.742 413.216L354.24 413.789L353.667 414.287Z"
          fill="#9AB0BB"
        />
        <path
          d="M354.164 414.86L353.667 414.287L354.24 413.789L354.738 414.363L354.164 414.86Z"
          fill="#F0FFFF"
        />
        <path
          d="M354.664 415.435L354.166 414.862L354.739 414.364L355.237 414.938L354.664 415.435Z"
          fill="#D0E1E9"
        />
        <path
          d="M355.163 416.01L354.664 415.435L355.237 414.938L355.736 415.513L355.163 416.01Z"
          fill="#394B53"
        />
        <path
          d="M355.659 416.582L355.161 416.008L355.734 415.511L356.232 416.084L355.659 416.582Z"
          fill="#51DFF2"
        />
        <path
          d="M356.158 417.157L355.661 416.583L356.234 416.086L356.731 416.659L356.158 417.157Z"
          fill="#05B8CF"
        />
        <path
          d="M356.656 417.73L356.158 417.157L356.731 416.659L357.229 417.232L356.656 417.73Z"
          fill="#058499"
        />
        <path
          d="M357.157 418.307L356.657 417.732L357.231 417.234L357.73 417.809L357.157 418.307Z"
          fill="#394B53"
        />
        <path
          d="M357.653 418.878L357.155 418.305L357.728 417.807L358.226 418.381L357.653 418.878Z"
          fill="#D0E1E9"
        />
        <path
          d="M358.152 419.453L357.654 418.88L358.228 418.382L358.725 418.956L358.152 419.453Z"
          fill="#809AA7"
        />
        <path
          d="M358.65 420.026L358.152 419.453L358.725 418.956L359.223 419.529L358.65 420.026Z"
          fill="#F0FFFF"
        />
        <path
          d="M359.151 420.603L358.651 420.028L359.225 419.531L359.724 420.106L359.151 420.603Z"
          fill="#D0E1E9"
        />
        <path
          d="M359.647 421.175L359.149 420.602L359.722 420.104L360.22 420.677L359.647 421.175Z"
          fill="#D0E1E9"
        />
        <path
          d="M360.146 421.75L359.648 421.177L360.221 420.679L360.719 421.252L360.146 421.75Z"
          fill="#9AB0BB"
        />
        <path
          d="M360.644 422.323L360.146 421.75L360.719 421.252L361.217 421.825L360.644 422.323Z"
          fill="#D0E1E9"
        />
        <path
          d="M361.143 422.898L360.645 422.325L361.218 421.827L361.716 422.4L361.143 422.898Z"
          fill="#D0E1E9"
        />
        <path
          d="M361.64 423.471L361.143 422.898L361.716 422.4L362.214 422.974L361.64 423.471Z"
          fill="#F0FFFF"
        />
        <path
          d="M362.138 424.045L361.641 423.471L362.214 422.974L362.711 423.547L362.138 424.045Z"
          fill="#D0E1E9"
        />
        <path
          d="M362.637 424.62L362.14 424.046L362.713 423.549L363.211 424.122L362.637 424.62Z"
          fill="#9AB0BB"
        />
        <path
          d="M363.634 425.768L363.137 425.195L363.71 424.697L364.208 425.27L363.634 425.768Z"
          fill="#D0E1E9"
        />
        <path
          d="M349.257 408.046L348.759 407.473L349.333 406.975L349.83 407.549L349.257 408.046Z"
          fill="#D0E1E9"
        />
        <path
          d="M349.755 408.62L349.257 408.046L349.83 407.549L350.328 408.122L349.755 408.62Z"
          fill="#F0FFFF"
        />
        <path
          d="M350.254 409.195L349.756 408.621L350.33 408.124L350.827 408.697L350.254 409.195Z"
          fill="#D0E1E9"
        />
        <path
          d="M350.752 409.768L350.254 409.195L350.827 408.697L351.325 409.27L350.752 409.768Z"
          fill="#D0E1E9"
        />
        <path
          d="M351.251 410.343L350.753 409.77L351.327 409.272L351.824 409.845L351.251 410.343Z"
          fill="#809AA7"
        />
        <path
          d="M351.749 410.916L351.251 410.343L351.824 409.845L352.322 410.418L351.749 410.916Z"
          fill="#D8531A"
        />
        <path
          d="M352.248 411.491L351.75 410.918L352.323 410.42L352.821 410.993L352.248 411.491Z"
          fill="#F7852F"
        />
        <path
          d="M352.746 412.064L352.248 411.491L352.821 410.993L353.319 411.567L352.746 412.064Z"
          fill="#F7852F"
        />
        <path
          d="M353.245 412.639L352.747 412.066L353.32 411.569L353.818 412.142L353.245 412.639Z"
          fill="#F7852F"
        />
        <path
          d="M353.742 413.213L353.245 412.639L353.818 412.142L354.316 412.715L353.742 413.213Z"
          fill="#F7852F"
        />
        <path
          d="M354.242 413.788L353.744 413.214L354.317 412.717L354.815 413.29L354.242 413.788Z"
          fill="#FFAD32"
        />
        <path
          d="M354.739 414.361L354.242 413.788L354.815 413.29L355.313 413.863L354.739 414.361Z"
          fill="#F0FFFF"
        />
        <path
          d="M355.239 414.936L354.741 414.363L355.314 413.865L355.812 414.438L355.239 414.936Z"
          fill="#D0E1E9"
        />
        <path
          d="M355.738 415.511L355.239 414.936L355.812 414.438L356.311 415.013L355.738 415.511Z"
          fill="#394B53"
        />
        <path
          d="M356.234 416.082L355.736 415.509L356.31 415.012L356.807 415.585L356.234 416.082Z"
          fill="#51DFF2"
        />
        <path
          d="M356.733 416.657L356.236 416.084L356.809 415.587L357.306 416.16L356.733 416.657Z"
          fill="#05B8CF"
        />
        <path
          d="M357.231 417.231L356.733 416.657L357.306 416.16L357.804 416.733L357.231 417.231Z"
          fill="#05B8CF"
        />
        <path
          d="M357.732 417.808L357.233 417.233L357.806 416.735L358.305 417.31L357.732 417.808Z"
          fill="#394B53"
        />
        <path
          d="M358.228 418.379L357.73 417.806L358.303 417.308L358.801 417.881L358.228 418.379Z"
          fill="#D0E1E9"
        />
        <path
          d="M358.727 418.954L358.229 418.381L358.803 417.883L359.3 418.456L358.727 418.954Z"
          fill="#809AA7"
        />
        <path
          d="M359.225 419.527L358.727 418.954L359.3 418.456L359.798 419.03L359.225 419.527Z"
          fill="#D8531A"
        />
        <path
          d="M359.726 420.104L359.226 419.529L359.8 419.031L360.299 419.606L359.726 420.104Z"
          fill="#F7852F"
        />
        <path
          d="M360.222 420.676L359.724 420.102L360.297 419.605L360.795 420.178L360.222 420.676Z"
          fill="#F7852F"
        />
        <path
          d="M360.721 421.251L360.223 420.677L360.796 420.18L361.294 420.753L360.721 421.251Z"
          fill="#F7852F"
        />
        <path
          d="M361.219 421.824L360.721 421.251L361.294 420.753L361.792 421.326L361.219 421.824Z"
          fill="#F7852F"
        />
        <path
          d="M361.718 422.399L361.22 421.826L361.793 421.328L362.291 421.901L361.718 422.399Z"
          fill="#FFAD32"
        />
        <path
          d="M362.216 422.972L361.718 422.399L362.291 421.901L362.789 422.474L362.216 422.972Z"
          fill="#F0FFFF"
        />
        <path
          d="M362.713 423.545L362.216 422.972L362.789 422.474L363.286 423.048L362.713 423.545Z"
          fill="#D0E1E9"
        />
        <path
          d="M363.213 424.12L362.715 423.547L363.288 423.049L363.786 423.623L363.213 424.12Z"
          fill="#D0E1E9"
        />
        <path
          d="M363.712 424.695L363.213 424.12L363.786 423.623L364.285 424.198L363.712 424.695Z"
          fill="#809AA7"
        />
        <path
          d="M364.209 425.269L363.712 424.695L364.285 424.198L364.783 424.771L364.209 425.269Z"
          fill="#D0E1E9"
        />
        <path
          d="M349.83 407.549L349.333 406.975L349.906 406.478L350.404 407.051L349.83 407.549Z"
          fill="#D0E1E9"
        />
        <path
          d="M350.328 408.122L349.83 407.549L350.404 407.051L350.901 407.624L350.328 408.122Z"
          fill="#F0FFFF"
        />
        <path
          d="M350.827 408.697L350.33 408.124L350.903 407.626L351.4 408.199L350.827 408.697Z"
          fill="#D0E1E9"
        />
        <path
          d="M351.325 409.27L350.827 408.697L351.4 408.199L351.898 408.772L351.325 409.27Z"
          fill="#D0E1E9"
        />
        <path
          d="M351.824 409.845L351.327 409.272L351.9 408.774L352.397 409.348L351.824 409.845Z"
          fill="#809AA7"
        />
        <path
          d="M352.322 410.418L351.824 409.845L352.397 409.348L352.895 409.921L352.322 410.418Z"
          fill="#D0E1E9"
        />
        <path
          d="M352.821 410.993L352.323 410.42L352.897 409.923L353.394 410.496L352.821 410.993Z"
          fill="#D0E1E9"
        />
        <path
          d="M353.319 411.567L352.821 410.993L353.394 410.496L353.892 411.069L353.319 411.567Z"
          fill="#D0E1E9"
        />
        <path
          d="M353.818 412.142L353.32 411.569L353.894 411.071L354.391 411.644L353.818 412.142Z"
          fill="#D0E1E9"
        />
        <path
          d="M354.316 412.715L353.818 412.142L354.391 411.644L354.889 412.217L354.316 412.715Z"
          fill="#D0E1E9"
        />
        <path
          d="M354.815 413.29L354.317 412.717L354.89 412.219L355.388 412.792L354.815 413.29Z"
          fill="#D0E1E9"
        />
        <path
          d="M355.313 413.863L354.815 413.29L355.388 412.792L355.886 413.366L355.313 413.863Z"
          fill="#F0FFFF"
        />
        <path
          d="M355.812 414.438L355.314 413.865L355.887 413.367L356.385 413.941L355.812 414.438Z"
          fill="#D0E1E9"
        />
        <path
          d="M356.311 415.013L355.812 414.438L356.385 413.941L356.884 414.516L356.311 415.013Z"
          fill="#394B53"
        />
        <path
          d="M356.807 415.585L356.31 415.012L356.883 414.514L357.38 415.087L356.807 415.585Z"
          fill="#51DFF2"
        />
        <path
          d="M357.307 416.16L356.809 415.587L357.382 415.089L357.88 415.662L357.307 416.16Z"
          fill="#05B8CF"
        />
        <path
          d="M357.804 416.733L357.307 416.16L357.88 415.662L358.377 416.235L357.804 416.733Z"
          fill="#058499"
        />
        <path
          d="M358.305 417.31L357.806 416.735L358.379 416.237L358.878 416.812L358.305 417.31Z"
          fill="#394B53"
        />
        <path
          d="M358.801 417.881L358.303 417.308L358.877 416.81L359.374 417.384L358.801 417.881Z"
          fill="#D0E1E9"
        />
        <path
          d="M359.3 418.456L358.803 417.883L359.376 417.385L359.874 417.959L359.3 418.456Z"
          fill="#809AA7"
        />
        <path
          d="M359.798 419.03L359.3 418.456L359.874 417.959L360.371 418.532L359.798 419.03Z"
          fill="#D0E1E9"
        />
        <path
          d="M360.299 419.606L359.8 419.031L360.373 418.534L360.872 419.109L360.299 419.606Z"
          fill="#D0E1E9"
        />
        <path
          d="M360.795 420.178L360.297 419.605L360.87 419.107L361.368 419.68L360.795 420.178Z"
          fill="#D0E1E9"
        />
        <path
          d="M361.294 420.753L360.797 420.18L361.37 419.682L361.867 420.255L361.294 420.753Z"
          fill="#D0E1E9"
        />
        <path
          d="M361.792 421.326L361.294 420.753L361.867 420.255L362.365 420.828L361.792 421.326Z"
          fill="#D0E1E9"
        />
        <path
          d="M362.291 421.901L361.793 421.328L362.367 420.83L362.864 421.403L362.291 421.901Z"
          fill="#D0E1E9"
        />
        <path
          d="M362.789 422.474L362.291 421.901L362.864 421.404L363.362 421.977L362.789 422.474Z"
          fill="#F0FFFF"
        />
        <path
          d="M363.286 423.048L362.789 422.474L363.362 421.977L363.86 422.55L363.286 423.048Z"
          fill="#D0E1E9"
        />
        <path
          d="M363.786 423.623L363.288 423.049L363.861 422.552L364.359 423.125L363.786 423.623Z"
          fill="#D0E1E9"
        />
        <path
          d="M364.285 424.198L363.786 423.623L364.359 423.125L364.858 423.7L364.285 424.198Z"
          fill="#809AA7"
        />
        <path
          d="M364.783 424.771L364.285 424.198L364.858 423.7L365.356 424.273L364.783 424.771Z"
          fill="#D0E1E9"
        />
        <path
          d="M350.405 407.049L349.908 406.476L350.481 405.978L350.979 406.552L350.405 407.049Z"
          fill="#D0E1E9"
        />
        <path
          d="M350.903 407.623L350.405 407.049L350.979 406.552L351.476 407.125L350.903 407.623Z"
          fill="#F0FFFF"
        />
        <path
          d="M351.402 408.198L350.905 407.624L351.478 407.127L351.976 407.7L351.402 408.198Z"
          fill="#D0E1E9"
        />
        <path
          d="M351.9 408.771L351.402 408.198L351.976 407.7L352.473 408.273L351.9 408.771Z"
          fill="#D0E1E9"
        />
        <path
          d="M352.399 409.346L351.902 408.773L352.475 408.275L352.973 408.848L352.399 409.346Z"
          fill="#809AA7"
        />
        <path
          d="M352.897 409.919L352.399 409.346L352.972 408.848L353.47 409.422L352.897 409.919Z"
          fill="#D8531A"
        />
        <path
          d="M353.396 410.494L352.898 409.921L353.472 409.423L353.969 409.997L353.396 410.494Z"
          fill="#F7852F"
        />
        <path
          d="M353.894 411.067L353.396 410.494L353.969 409.997L354.467 410.57L353.894 411.067Z"
          fill="#F7852F"
        />
        <path
          d="M354.393 411.642L353.895 411.069L354.469 410.572L354.966 411.145L354.393 411.642Z"
          fill="#F7852F"
        />
        <path
          d="M354.891 412.216L354.393 411.642L354.966 411.145L355.464 411.718L354.891 412.216Z"
          fill="#F7852F"
        />
        <path
          d="M355.39 412.791L354.892 412.218L355.466 411.72L355.963 412.293L355.39 412.791Z"
          fill="#FFAD32"
        />
        <path
          d="M355.888 413.364L355.39 412.791L355.963 412.293L356.461 412.866L355.888 413.364Z"
          fill="#F0FFFF"
        />
        <path
          d="M356.387 413.939L355.889 413.366L356.463 412.868L356.96 413.441L356.387 413.939Z"
          fill="#D0E1E9"
        />
        <path
          d="M356.886 414.514L356.387 413.939L356.96 413.441L357.459 414.016L356.886 414.514Z"
          fill="#394B53"
        />
        <path
          d="M357.382 415.085L356.885 414.512L357.458 414.015L357.956 414.588L357.382 415.085Z"
          fill="#05B8CF"
        />
        <path
          d="M357.882 415.661L357.384 415.087L357.957 414.59L358.455 415.163L357.882 415.661Z"
          fill="#05B8CF"
        />
        <path
          d="M358.379 416.234L357.881 415.661L358.455 415.163L358.952 415.736L358.379 416.234Z"
          fill="#058499"
        />
        <path
          d="M358.88 416.811L358.381 416.236L358.954 415.738L359.453 416.313L358.88 416.811Z"
          fill="#394B53"
        />
        <path
          d="M359.376 417.382L358.878 416.809L359.452 416.311L359.949 416.884L359.376 417.382Z"
          fill="#D0E1E9"
        />
        <path
          d="M359.875 417.957L359.378 417.384L359.951 416.886L360.449 417.459L359.875 417.957Z"
          fill="#809AA7"
        />
        <path
          d="M360.373 418.53L359.875 417.957L360.449 417.459L360.946 418.033L360.373 418.53Z"
          fill="#D8531A"
        />
        <path
          d="M360.874 419.107L360.375 418.532L360.948 418.034L361.447 418.61L360.874 419.107Z"
          fill="#F7852F"
        />
        <path
          d="M361.37 419.679L360.872 419.105L361.446 418.608L361.943 419.181L361.37 419.679Z"
          fill="#F7852F"
        />
        <path
          d="M361.869 420.254L361.372 419.68L361.945 419.183L362.442 419.756L361.869 420.254Z"
          fill="#F7852F"
        />
        <path
          d="M362.367 420.827L361.869 420.254L362.442 419.756L362.94 420.329L362.367 420.827Z"
          fill="#F7852F"
        />
        <path
          d="M362.866 421.402L362.369 420.829L362.942 420.331L363.439 420.904L362.866 421.402Z"
          fill="#FFAD32"
        />
        <path
          d="M363.364 421.975L362.866 421.402L363.439 420.904L363.937 421.477L363.364 421.975Z"
          fill="#F0FFFF"
        />
        <path
          d="M363.861 422.548L363.364 421.975L363.937 421.477L364.435 422.051L363.861 422.548Z"
          fill="#D0E1E9"
        />
        <path
          d="M364.361 423.123L363.863 422.55L364.436 422.053L364.934 422.626L364.361 423.123Z"
          fill="#D0E1E9"
        />
        <path
          d="M364.86 423.698L364.361 423.123L364.934 422.626L365.433 423.201L364.86 423.698Z"
          fill="#809AA7"
        />
        <path
          d="M365.358 424.272L364.86 423.698L365.433 423.201L365.931 423.774L365.358 424.272Z"
          fill="#D0E1E9"
        />
        <path
          d="M350.979 406.552L350.481 405.978L351.054 405.481L351.552 406.054L350.979 406.552Z"
          fill="#D0E1E9"
        />
        <path
          d="M351.476 407.125L350.979 406.552L351.552 406.054L352.05 406.627L351.476 407.125Z"
          fill="#F0FFFF"
        />
        <path
          d="M351.976 407.7L351.478 407.127L352.051 406.629L352.549 407.202L351.976 407.7Z"
          fill="#D0E1E9"
        />
        <path
          d="M352.473 408.273L351.975 407.7L352.549 407.202L353.046 407.776L352.473 408.273Z"
          fill="#D0E1E9"
        />
        <path
          d="M352.972 408.848L352.475 408.275L353.048 407.777L353.546 408.351L352.972 408.848Z"
          fill="#D0E1E9"
        />
        <path
          d="M353.47 409.421L352.972 408.848L353.546 408.351L354.043 408.924L353.47 409.421Z"
          fill="#D0E1E9"
        />
        <path
          d="M353.969 409.997L353.472 409.423L354.045 408.926L354.543 409.499L353.969 409.997Z"
          fill="#D0E1E9"
        />
        <path
          d="M354.467 410.57L353.969 409.997L354.543 409.499L355.04 410.072L354.467 410.57Z"
          fill="#F0FFFF"
        />
        <path
          d="M354.966 411.145L354.469 410.572L355.042 410.074L355.54 410.647L354.966 411.145Z"
          fill="#D0E1E9"
        />
        <path
          d="M355.464 411.718L354.966 411.145L355.54 410.647L356.037 411.22L355.464 411.718Z"
          fill="#D0E1E9"
        />
        <path
          d="M355.963 412.293L355.466 411.72L356.039 411.222L356.536 411.795L355.963 412.293Z"
          fill="#D0E1E9"
        />
        <path
          d="M356.461 412.866L355.963 412.293L356.536 411.795L357.034 412.369L356.461 412.866Z"
          fill="#F0FFFF"
        />
        <path
          d="M356.96 413.441L356.463 412.868L357.036 412.37L357.533 412.944L356.96 413.441Z"
          fill="#D0E1E9"
        />
        <path
          d="M357.459 414.016L356.96 413.441L357.533 412.944L358.033 413.519L357.459 414.016Z"
          fill="#394B53"
        />
        <path
          d="M357.955 414.588L357.458 414.015L358.031 413.517L358.529 414.09L357.955 414.588Z"
          fill="#51DFF2"
        />
        <path
          d="M358.455 415.163L357.957 414.59L358.53 414.092L359.028 414.665L358.455 415.163Z"
          fill="#05B8CF"
        />
        <path
          d="M358.952 415.736L358.455 415.163L359.028 414.665L359.526 415.238L358.952 415.736Z"
          fill="#058499"
        />
        <path
          d="M359.453 416.313L358.954 415.738L359.527 415.24L360.026 415.815L359.453 416.313Z"
          fill="#394B53"
        />
        <path
          d="M359.949 416.884L359.452 416.311L360.025 415.813L360.523 416.387L359.949 416.884Z"
          fill="#D0E1E9"
        />
        <path
          d="M360.449 417.459L359.951 416.886L360.524 416.389L361.022 416.962L360.449 417.459Z"
          fill="#809AA7"
        />
        <path
          d="M360.946 418.033L360.449 417.459L361.022 416.962L361.519 417.535L360.946 418.033Z"
          fill="#D0E1E9"
        />
        <path
          d="M361.447 418.61L360.948 418.034L361.521 417.537L362.02 418.112L361.447 418.61Z"
          fill="#D0E1E9"
        />
        <path
          d="M361.943 419.181L361.446 418.608L362.019 418.11L362.516 418.683L361.943 419.181Z"
          fill="#D0E1E9"
        />
        <path
          d="M362.442 419.756L361.945 419.183L362.518 418.685L363.016 419.258L362.442 419.756Z"
          fill="#9AB0BB"
        />
        <path
          d="M362.94 420.329L362.443 419.756L363.016 419.258L363.513 419.832L362.94 420.329Z"
          fill="#D0E1E9"
        />
        <path
          d="M363.439 420.904L362.942 420.331L363.515 419.833L364.013 420.407L363.439 420.904Z"
          fill="#D0E1E9"
        />
        <path
          d="M363.937 421.477L363.439 420.904L364.013 420.407L364.51 420.98L363.937 421.477Z"
          fill="#D0E1E9"
        />
        <path
          d="M364.435 422.051L363.937 421.477L364.51 420.98L365.008 421.553L364.435 422.051Z"
          fill="#D0E1E9"
        />
        <path
          d="M364.934 422.626L364.436 422.053L365.01 421.555L365.507 422.128L364.934 422.626Z"
          fill="#D0E1E9"
        />
        <path
          d="M365.433 423.201L364.934 422.626L365.507 422.128L366.006 422.703L365.433 423.201Z"
          fill="#809AA7"
        />
        <path
          d="M365.931 423.774L365.433 423.201L366.006 422.703L366.504 423.276L365.931 423.774Z"
          fill="#D0E1E9"
        />
        <path
          d="M351.554 406.052L351.056 405.479L351.629 404.982L352.127 405.555L351.554 406.052Z"
          fill="#D0E1E9"
        />
        <path
          d="M352.551 407.201L352.053 406.628L352.626 406.13L353.124 406.703L352.551 407.201Z"
          fill="#9AB0BB"
        />
        <path
          d="M353.048 407.774L352.551 407.201L353.124 406.703L353.621 407.276L353.048 407.774Z"
          fill="#9AB0BB"
        />
        <path
          d="M353.547 408.349L353.05 407.776L353.623 407.278L354.121 407.851L353.547 408.349Z"
          fill="#D0E1E9"
        />
        <path
          d="M354.045 408.922L353.547 408.349L354.121 407.851L354.618 408.425L354.045 408.922Z"
          fill="#D0E1E9"
        />
        <path
          d="M354.544 409.497L354.047 408.924L354.62 408.426L355.118 409L354.544 409.497Z"
          fill="#D0E1E9"
        />
        <path
          d="M355.042 410.071L354.544 409.497L355.118 409L355.615 409.573L355.042 410.071Z"
          fill="#F0FFFF"
        />
        <path
          d="M355.541 410.646L355.044 410.072L355.617 409.575L356.115 410.148L355.541 410.646Z"
          fill="#D0E1E9"
        />
        <path
          d="M356.039 411.219L355.541 410.646L356.115 410.148L356.612 410.721L356.039 411.219Z"
          fill="#D0E1E9"
        />
        <path
          d="M356.538 411.794L356.041 411.221L356.614 410.723L357.112 411.296L356.538 411.794Z"
          fill="#D0E1E9"
        />
        <path
          d="M357.036 412.367L356.538 411.794L357.112 411.296L357.609 411.869L357.036 412.367Z"
          fill="#809AA7"
        />
        <path
          d="M357.535 412.942L357.038 412.369L357.611 411.871L358.108 412.444L357.535 412.942Z"
          fill="#D0E1E9"
        />
        <path
          d="M358.034 413.517L357.535 412.942L358.108 412.444L358.608 413.019L358.034 413.517Z"
          fill="#D0E1E9"
        />
        <path
          d="M358.531 414.089L358.033 413.515L358.606 413.018L359.104 413.591L358.531 414.089Z"
          fill="#394B53"
        />
        <path
          d="M359.03 414.664L358.532 414.09L359.105 413.593L359.603 414.166L359.03 414.664Z"
          fill="#058499"
        />
        <path
          d="M359.527 415.237L359.03 414.664L359.603 414.166L360.101 414.739L359.527 415.237Z"
          fill="#394B53"
        />
        <path
          d="M360.028 415.814L359.529 415.239L360.102 414.741L360.602 415.316L360.028 415.814Z"
          fill="#D0E1E9"
        />
        <path
          d="M360.524 416.385L360.027 415.812L360.6 415.314L361.098 415.887L360.524 416.385Z"
          fill="#D0E1E9"
        />
        <path
          d="M361.024 416.96L360.526 416.387L361.099 415.889L361.597 416.462L361.024 416.96Z"
          fill="#809AA7"
        />
        <path
          d="M361.521 417.533L361.024 416.96L361.597 416.462L362.095 417.036L361.521 417.533Z"
          fill="#D0E1E9"
        />
        <path
          d="M362.022 418.11L361.523 417.535L362.096 417.038L362.595 417.613L362.022 418.11Z"
          fill="#D0E1E9"
        />
        <path
          d="M362.518 418.682L362.021 418.108L362.594 417.611L363.092 418.184L362.518 418.682Z"
          fill="#D0E1E9"
        />
        <path
          d="M363.018 419.257L362.52 418.683L363.093 418.186L363.591 418.759L363.018 419.257Z"
          fill="#9AB0BB"
        />
        <path
          d="M363.515 419.83L363.017 419.257L363.591 418.759L364.088 419.332L363.515 419.83Z"
          fill="#D0E1E9"
        />
        <path
          d="M364.014 420.405L363.517 419.832L364.09 419.334L364.588 419.907L364.014 420.405Z"
          fill="#D0E1E9"
        />
        <path
          d="M364.512 420.978L364.014 420.405L364.588 419.907L365.085 420.481L364.512 420.978Z"
          fill="#D0E1E9"
        />
        <path
          d="M365.01 421.551L364.512 420.978L365.085 420.481L365.583 421.054L365.01 421.551Z"
          fill="#809AA7"
        />
        <path
          d="M365.509 422.126L365.011 421.553L365.585 421.056L366.082 421.629L365.509 422.126Z"
          fill="#809AA7"
        />
        <path
          d="M366.506 423.275L366.008 422.702L366.582 422.204L367.079 422.777L366.506 423.275Z"
          fill="#D0E1E9"
        />
        <path
          d="M352.127 405.555L351.629 404.982L352.204 404.482L352.702 405.056L352.127 405.555Z"
          fill="#809AA7"
        />
        <path
          d="M354.121 407.851L353.623 407.278L354.198 406.779L354.696 407.352L354.121 407.851Z"
          fill="#9AB0BB"
        />
        <path
          d="M354.618 408.425L354.121 407.851L354.696 407.352L355.193 407.925L354.618 408.425Z"
          fill="#9AB0BB"
        />
        <path
          d="M355.118 409L354.62 408.426L355.195 407.927L355.693 408.5L355.118 409Z"
          fill="#D0E1E9"
        />
        <path
          d="M355.615 409.573L355.118 409L355.693 408.5L356.19 409.074L355.615 409.573Z"
          fill="#F0FFFF"
        />
        <path
          d="M356.115 410.148L355.617 409.575L356.192 409.075L356.69 409.649L356.115 410.148Z"
          fill="#D0E1E9"
        />
        <path
          d="M356.612 410.721L356.115 410.148L356.69 409.649L357.187 410.222L356.612 410.721Z"
          fill="#D0E1E9"
        />
        <path
          d="M357.112 411.296L356.614 410.723L357.189 410.224L357.687 410.797L357.112 411.296Z"
          fill="#809AA7"
        />
        <path
          d="M358.108 412.444L357.611 411.871L358.186 411.372L358.683 411.945L358.108 412.444Z"
          fill="#F0FFFF"
        />
        <path
          d="M358.608 413.019L358.108 412.444L358.683 411.945L359.183 412.52L358.608 413.019Z"
          fill="#D0E1E9"
        />
        <path
          d="M359.104 413.591L358.606 413.018L359.181 412.518L359.679 413.092L359.104 413.591Z"
          fill="#394B53"
        />
        <path
          d="M359.603 414.166L359.105 413.593L359.68 413.093L360.178 413.667L359.603 414.166Z"
          fill="#394B53"
        />
        <path
          d="M360.101 414.739L359.603 414.166L360.178 413.667L360.676 414.24L360.101 414.739Z"
          fill="#394B53"
        />
        <path
          d="M360.602 415.316L360.102 414.741L360.677 414.242L361.177 414.817L360.602 415.316Z"
          fill="#D0E1E9"
        />
        <path
          d="M361.098 415.887L360.6 415.314L361.175 414.815L361.673 415.388L361.098 415.887Z"
          fill="#809AA7"
        />
        <path
          d="M362.095 417.036L361.597 416.462L362.172 415.963L362.67 416.536L362.095 417.036Z"
          fill="#809AA7"
        />
        <path
          d="M362.595 417.613L362.096 417.038L362.671 416.538L363.171 417.113L362.595 417.613Z"
          fill="#D0E1E9"
        />
        <path
          d="M363.091 418.184L362.594 417.611L363.169 417.112L363.666 417.685L363.091 418.184Z"
          fill="#D0E1E9"
        />
        <path
          d="M363.591 418.759L363.093 418.186L363.668 417.687L364.166 418.26L363.591 418.759Z"
          fill="#9AB0BB"
        />
        <path
          d="M364.088 419.332L363.591 418.759L364.166 418.26L364.663 418.833L364.088 419.332Z"
          fill="#D0E1E9"
        />
        <path
          d="M364.588 419.907L364.09 419.334L364.665 418.835L365.163 419.408L364.588 419.907Z"
          fill="#809AA7"
        />
        <path
          d="M365.085 420.481L364.588 419.907L365.163 419.408L365.66 419.981L365.085 420.481Z"
          fill="#809AA7"
        />
        <path
          d="M367.079 422.777L366.582 422.204L367.157 421.705L367.654 422.278L367.079 422.777Z"
          fill="#809AA7"
        />
        <path
          d="M355.693 408.5L355.195 407.927L355.768 407.429L356.266 408.003L355.693 408.5Z"
          fill="#9AB0BB"
        />
        <path
          d="M356.19 409.074L355.693 408.5L356.266 408.003L356.764 408.576L356.19 409.074Z"
          fill="#9AB0BB"
        />
        <path
          d="M356.69 409.649L356.192 409.075L356.765 408.578L357.263 409.151L356.69 409.649Z"
          fill="#9AB0BB"
        />
        <path
          d="M357.187 410.222L356.69 409.649L357.263 409.151L357.76 409.724L357.187 410.222Z"
          fill="#809AA7"
        />
        <path
          d="M358.683 411.945L358.186 411.372L358.759 410.874L359.257 411.448L358.683 411.945Z"
          fill="#F0FFFF"
        />
        <path
          d="M359.183 412.52L358.683 411.945L359.257 411.448L359.756 412.023L359.183 412.52Z"
          fill="#D0E1E9"
        />
        <path
          d="M359.679 413.092L359.181 412.518L359.754 412.021L360.252 412.594L359.679 413.092Z"
          fill="#D0E1E9"
        />
        <path
          d="M360.178 413.667L359.68 413.093L360.254 412.596L360.751 413.169L360.178 413.667Z"
          fill="#D0E1E9"
        />
        <path
          d="M360.676 414.24L360.178 413.667L360.751 413.169L361.249 413.742L360.676 414.24Z"
          fill="#D0E1E9"
        />
        <path
          d="M361.177 414.817L360.677 414.242L361.251 413.744L361.75 414.319L361.177 414.817Z"
          fill="#D0E1E9"
        />
        <path
          d="M361.673 415.388L361.175 414.815L361.748 414.317L362.246 414.891L361.673 415.388Z"
          fill="#809AA7"
        />
        <path
          d="M363.17 417.113L362.671 416.538L363.244 416.041L363.744 416.616L363.17 417.113Z"
          fill="#809AA7"
        />
        <path
          d="M363.666 417.685L363.169 417.112L363.742 416.614L364.24 417.187L363.666 417.685Z"
          fill="#809AA7"
        />
        <path
          d="M364.166 418.26L363.668 417.687L364.241 417.189L364.739 417.762L364.166 418.26Z"
          fill="#809AA7"
        />
        <path
          d="M364.663 418.833L364.166 418.26L364.739 417.762L365.237 418.335L364.663 418.833Z"
          fill="#809AA7"
        />
        <path
          d="M359.257 411.448L358.759 410.874L359.332 410.377L359.83 410.95L359.257 411.448Z"
          fill="#F0FFFF"
        />
        <path
          d="M359.756 412.023L359.257 411.448L359.83 410.95L360.329 411.525L359.756 412.023Z"
          fill="#D0E1E9"
        />
        <path
          d="M360.252 412.594L359.754 412.021L360.328 411.523L360.825 412.096L360.252 412.594Z"
          fill="#D0E1E9"
        />
        <path
          d="M360.751 413.169L360.254 412.596L360.827 412.098L361.325 412.671L360.751 413.169Z"
          fill="#D0E1E9"
        />
        <path
          d="M361.249 413.742L360.751 413.169L361.325 412.671L361.822 413.245L361.249 413.742Z"
          fill="#D0E1E9"
        />
        <path
          d="M361.75 414.319L361.251 413.744L361.824 413.246L362.323 413.821L361.75 414.319Z"
          fill="#D0E1E9"
        />
        <path
          d="M362.246 414.891L361.748 414.317L362.321 413.82L362.819 414.393L362.246 414.891Z"
          fill="#809AA7"
        />
        <path
          d="M360.331 411.523L359.832 410.948L360.405 410.451L360.904 411.026L360.331 411.523Z"
          fill="#9AB0BB"
        />
        <path
          d="M360.827 412.095L360.329 411.521L360.903 411.024L361.4 411.597L360.827 412.095Z"
          fill="#9AB0BB"
        />
        <path
          d="M361.326 412.67L360.829 412.097L361.402 411.599L361.9 412.172L361.326 412.67Z"
          fill="#9AB0BB"
        />
        <path
          d="M361.824 413.243L361.326 412.67L361.9 412.172L362.397 412.745L361.824 413.243Z"
          fill="#9AB0BB"
        />
        <path
          d="M362.325 413.82L361.826 413.245L362.399 412.747L362.898 413.322L362.325 413.82Z"
          fill="#809AA7"
        />
        <path
          d="M360.904 411.026L360.405 410.451L360.978 409.953L361.478 410.528L360.904 411.026Z"
          fill="#43484B"
        />
        <path
          d="M361.4 411.597L360.903 411.024L361.476 410.526L361.973 411.099L361.4 411.597Z"
          fill="#43484B"
        />
        <path
          d="M361.9 412.172L361.402 411.599L361.975 411.101L362.473 411.674L361.9 412.172Z"
          fill="#373B3E"
        />
        <path
          d="M362.397 412.745L361.9 412.172L362.473 411.674L362.97 412.248L362.397 412.745Z"
          fill="#373B3E"
        />
        <path
          d="M362.898 413.322L362.399 412.747L362.972 412.249L363.471 412.825L362.898 413.322Z"
          fill="#26282A"
        />
        <path
          d="M361.477 410.528L360.978 409.953L361.551 409.455L362.051 410.03L361.477 410.528Z"
          fill="#373B3E"
        />
        <path
          d="M361.973 411.099L361.476 410.526L362.049 410.028L362.547 410.602L361.973 411.099Z"
          fill="#43484B"
        />
        <path
          d="M362.473 411.674L361.975 411.101L362.548 410.604L363.046 411.177L362.473 411.674Z"
          fill="#373B3E"
        />
        <path
          d="M362.97 412.248L362.473 411.674L363.046 411.177L363.544 411.75L362.97 412.248Z"
          fill="#373B3E"
        />
        <path
          d="M363.471 412.825L362.972 412.249L363.545 411.752L364.045 412.327L363.471 412.825Z"
          fill="#26282A"
        />
        <path
          d="M362.052 410.029L361.553 409.454L362.126 408.956L362.626 409.531L362.052 410.029Z"
          fill="#FFCA34"
        />
        <path
          d="M362.549 410.6L362.051 410.027L362.624 409.529L363.122 410.102L362.549 410.6Z"
          fill="#FFAD32"
        />
        <path
          d="M363.048 411.175L362.55 410.602L363.123 410.104L363.621 410.677L363.048 411.175Z"
          fill="#FFCA34"
        />
        <path
          d="M363.546 411.748L363.048 411.175L363.621 410.678L364.119 411.251L363.546 411.748Z"
          fill="#FFAD32"
        />
        <path
          d="M364.046 412.325L363.547 411.75L364.12 411.253L364.62 411.828L364.046 412.325Z"
          fill="#FFCA34"
        />
        <path
          d="M362.626 409.531L362.126 408.956L362.7 408.458L363.199 409.033L362.626 409.531Z"
          fill="#FFAD32"
        />
        <path
          d="M363.122 410.102L362.624 409.529L363.197 409.032L363.695 409.605L363.122 410.102Z"
          fill="#F7852F"
        />
        <path
          d="M363.621 410.678L363.123 410.104L363.697 409.607L364.194 410.18L363.621 410.678Z"
          fill="#FFAD32"
        />
        <path
          d="M364.119 411.251L363.621 410.677L364.194 410.18L364.692 410.753L364.119 411.251Z"
          fill="#F7852F"
        />
        <path
          d="M364.62 411.828L364.12 411.253L364.694 410.755L365.193 411.33L364.62 411.828Z"
          fill="#FFAD32"
        />
        <path
          d="M363.201 409.032L362.702 408.457L363.277 407.957L363.776 408.533L363.201 409.032Z"
          fill="#F7852F"
        />
        <path
          d="M363.697 409.603L363.199 409.03L363.774 408.531L364.272 409.104L363.697 409.603Z"
          fill="#FFAD32"
        />
        <path
          d="M364.196 410.178L363.698 409.605L364.274 409.106L364.771 409.679L364.196 410.178Z"
          fill="#D8531A"
        />
        <path
          d="M364.694 410.751L364.196 410.178L364.771 409.679L365.269 410.252L364.694 410.751Z"
          fill="#FFAD32"
        />
        <path
          d="M365.195 411.328L364.695 410.753L365.27 410.254L365.77 410.829L365.195 411.328Z"
          fill="#F7852F"
        />
        <path
          d="M364.27 409.106L363.772 408.532L364.346 408.035L364.843 408.608L364.27 409.106Z"
          fill="#D8531A"
        />
        <path
          d="M364.769 409.681L364.272 409.107L364.845 408.61L365.343 409.183L364.769 409.681Z"
          fill="#F7852F"
        />
        <path
          d="M365.267 410.254L364.769 409.681L365.343 409.183L365.84 409.756L365.267 410.254Z"
          fill="#D8531A"
        />
        <path
          d="M364.845 408.606L364.347 408.033L364.921 407.535L365.418 408.109L364.845 408.606Z"
          fill="#D8531A"
        />
        <path
          d="M365.344 409.181L364.847 408.608L365.42 408.11L365.918 408.684L365.344 409.181Z"
          fill="#D8531A"
        />
        <path
          d="M365.842 409.755L365.344 409.181L365.918 408.684L366.415 409.257L365.842 409.755Z"
          fill="#D8531A"
        />
        <path
          d="M365.918 408.684L365.42 408.11L365.993 407.613L366.491 408.186L365.918 408.684Z"
          fill="#D8531A"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_0_1"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(38.5314 386.502) rotate(180) scale(68.5)"
        >
          <stop offset="0.46" />
          <stop offset="0.77" stopColor="#3B81CC" />
          <stop offset="0.8" stopColor="#2B5F96" />
          <stop offset="0.84" stopColor="#1E4268" />
          <stop offset="0.87" stopColor="#132A43" />
          <stop offset="0.9" stopColor="#0A1725" />
          <stop offset="0.94" stopColor="#040A10" />
          <stop offset="0.97" stopColor="#010204" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_0_1"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(320.2 370.21) scale(10.2)"
        >
          <stop stopColor="white" />
          <stop offset="0.16" stopColor="#C1C1C1" stopOpacity="0.76" />
          <stop offset="0.32" stopColor="#878787" stopOpacity="0.53" />
          <stop offset="0.49" stopColor="#565656" stopOpacity="0.34" />
          <stop offset="0.64" stopColor="#313131" stopOpacity="0.19" />
          <stop offset="0.78" stopColor="#161616" stopOpacity="0.09" />
          <stop offset="0.9" stopColor="#050505" stopOpacity="0.02" />
          <stop offset="1" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_0_1"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(119.253 75.07) scale(4.0376 23.072)"
        >
          <stop stopColor="white" />
          <stop offset="0.16" stopColor="#C1C1C1" stopOpacity="0.76" />
          <stop offset="0.32" stopColor="#878787" stopOpacity="0.53" />
          <stop offset="0.49" stopColor="#565656" stopOpacity="0.34" />
          <stop offset="0.64" stopColor="#313131" stopOpacity="0.19" />
          <stop offset="0.78" stopColor="#161616" stopOpacity="0.09" />
          <stop offset="0.9" stopColor="#050505" stopOpacity="0.02" />
          <stop offset="1" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_0_1"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(119.454 76.1054) rotate(88.76) scale(4.039 23.3685)"
        >
          <stop stopColor="white" />
          <stop offset="0.16" stopColor="#C1C1C1" stopOpacity="0.76" />
          <stop offset="0.32" stopColor="#878787" stopOpacity="0.53" />
          <stop offset="0.49" stopColor="#565656" stopOpacity="0.34" />
          <stop offset="0.64" stopColor="#313131" stopOpacity="0.19" />
          <stop offset="0.78" stopColor="#161616" stopOpacity="0.09" />
          <stop offset="0.9" stopColor="#050505" stopOpacity="0.02" />
          <stop offset="1" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_0_1"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(37.7404 131.854) scale(3.9704 22.688)"
        >
          <stop stopColor="white" />
          <stop offset="0.16" stopColor="#C1C1C1" stopOpacity="0.76" />
          <stop offset="0.32" stopColor="#878787" stopOpacity="0.53" />
          <stop offset="0.49" stopColor="#565656" stopOpacity="0.34" />
          <stop offset="0.64" stopColor="#313131" stopOpacity="0.19" />
          <stop offset="0.78" stopColor="#161616" stopOpacity="0.09" />
          <stop offset="0.9" stopColor="#050505" stopOpacity="0.02" />
          <stop offset="1" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_0_1"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(37.1296 131.142) rotate(88.76) scale(3.9732 22.9878)"
        >
          <stop stopColor="white" />
          <stop offset="0.16" stopColor="#C1C1C1" stopOpacity="0.76" />
          <stop offset="0.32" stopColor="#878787" stopOpacity="0.53" />
          <stop offset="0.49" stopColor="#565656" stopOpacity="0.34" />
          <stop offset="0.64" stopColor="#313131" stopOpacity="0.19" />
          <stop offset="0.78" stopColor="#161616" stopOpacity="0.09" />
          <stop offset="0.9" stopColor="#050505" stopOpacity="0.02" />
          <stop offset="1" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_0_1"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(39.9145 133.367) rotate(134.65) scale(3.9704 22.688)"
        >
          <stop stopColor="white" />
          <stop offset="0.16" stopColor="#C1C1C1" stopOpacity="0.76" />
          <stop offset="0.32" stopColor="#878787" stopOpacity="0.53" />
          <stop offset="0.49" stopColor="#565656" stopOpacity="0.34" />
          <stop offset="0.64" stopColor="#313131" stopOpacity="0.19" />
          <stop offset="0.78" stopColor="#161616" stopOpacity="0.09" />
          <stop offset="0.9" stopColor="#050505" stopOpacity="0.02" />
          <stop offset="1" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_0_1"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(38.5993 130.397) rotate(42.08) scale(3.9718 22.696)"
        >
          <stop stopColor="white" />
          <stop offset="0.16" stopColor="#C1C1C1" stopOpacity="0.76" />
          <stop offset="0.32" stopColor="#878787" stopOpacity="0.53" />
          <stop offset="0.49" stopColor="#565656" stopOpacity="0.34" />
          <stop offset="0.64" stopColor="#313131" stopOpacity="0.19" />
          <stop offset="0.78" stopColor="#161616" stopOpacity="0.09" />
          <stop offset="0.9" stopColor="#050505" stopOpacity="0.02" />
          <stop offset="1" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_0_1"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(43.602 268.23) scale(4.0376 23.072)"
        >
          <stop stopColor="white" />
          <stop offset="0.16" stopColor="#C1C1C1" stopOpacity="0.76" />
          <stop offset="0.32" stopColor="#878787" stopOpacity="0.53" />
          <stop offset="0.49" stopColor="#565656" stopOpacity="0.34" />
          <stop offset="0.64" stopColor="#313131" stopOpacity="0.19" />
          <stop offset="0.78" stopColor="#161616" stopOpacity="0.09" />
          <stop offset="0.9" stopColor="#050505" stopOpacity="0.02" />
          <stop offset="1" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint9_radial_0_1"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(42.9856 267.819) rotate(88.76) scale(4.039 23.3685)"
        >
          <stop stopColor="white" />
          <stop offset="0.16" stopColor="#C1C1C1" stopOpacity="0.76" />
          <stop offset="0.32" stopColor="#878787" stopOpacity="0.53" />
          <stop offset="0.49" stopColor="#565656" stopOpacity="0.34" />
          <stop offset="0.64" stopColor="#313131" stopOpacity="0.19" />
          <stop offset="0.78" stopColor="#161616" stopOpacity="0.09" />
          <stop offset="0.9" stopColor="#050505" stopOpacity="0.02" />
          <stop offset="1" stopOpacity="0" />
        </radialGradient>
        <clipPath id="clip0_0_1">
          <rect
            width="500"
            height="500"
            fill="white"
            transform="translate(-55)"
          />
        </clipPath>
        <clipPath id="clip1_0_1">
          <rect
            width="137"
            height="137"
            fill="white"
            transform="translate(-30 318)"
          />
        </clipPath>
        <clipPath id="clip2_0_1">
          <rect
            width="20.29"
            height="20.25"
            fill="white"
            transform="translate(310 360)"
          />
        </clipPath>
        <clipPath id="clip3_0_1">
          <rect
            width="46.44"
            height="46.36"
            fill="white"
            transform="translate(97 52)"
          />
        </clipPath>
        <clipPath id="clip4_0_1">
          <rect
            width="45.68"
            height="45.6"
            fill="white"
            transform="translate(16 109)"
          />
        </clipPath>
        <clipPath id="clip5_0_1">
          <rect
            width="46.44"
            height="46.36"
            fill="white"
            transform="translate(23 245)"
          />
        </clipPath>
        <clipPath id="clip6_0_1">
          <rect
            width="23"
            height="19"
            fill="white"
            transform="translate(341 587)"
          />
        </clipPath>
        <clipPath id="clip7_0_1">
          <rect
            width="25"
            height="24"
            fill="white"
            transform="translate(32.9706 429) rotate(45)"
          />
        </clipPath>
        <clipPath id="clip8_0_1">
          <rect
            width="24.0203"
            height="27.0216"
            fill="white"
            transform="translate(97.281 151) rotate(51.9574)"
          />
        </clipPath>
        <clipPath id="clip9_0_1">
          <rect
            width="23.5666"
            height="25.8488"
            fill="white"
            transform="matrix(0.655589 0.755118 0.755118 -0.655589 339 415.946)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Background;
